import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader';
import GridItem from "../../components/Grid/GridItem.jsx";

import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import CustomSelect from "../CustomInput/CustomSelect.jsx";

import editPackageModalStyle from "../../../assets/jss/home/editPackageModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const EditPackageModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { editPackageDetail } = this.props;

        this.state = {
            editPackageDetail: editPackageDetail,
            accountType: editPackageDetail.accountType,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
        };

        this.accountTypes = Api.getPlans();
        this.updatePackageDetail = this.updatePackageDetail.bind(this);
    }
    updatePackageDetail(payNow = false){
        const { accountType, editPackageDetail } = this.state;
        
        const requestData = {
            id: editPackageDetail.userId,
            accountType: accountType,
        }
        
        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.updatePackage(requestData, source).then(data => {
            if(payNow){
                return this.props.onSuccess({
                    id: editPackageDetail.userId,
                    account_type: accountType
                }, payNow);
            }
            that.setState({
                success: true,
                saving: false
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    saving: false,
                    showError: true,
                    errorMessage: err.message,
                    cancelToken: null,
                });
            }
        });
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    renderForm(){
        const { saving, accountType } = this.state;
        const { classes } = this.props;
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.editPackage}>
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="Account Type"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"accountType"),
                                value: accountType
                            }}
                            inputProps={{
                                name: "accountType",
                                id: "input-accountType",
                                className: classes.alignLeft
                            }}
                            items={this.accountTypes}
                            itemLabel="label"
                            itemValue="id"
                        />
                    </div>
                    <div className={classes.transferFooter+" "+classes.fileSendingFormFooter}>
                        <Button onClick={() => this.updatePackageDetail()} type="button" color="info">
                            {
                                saving ?
                                    <LoaderComponent align="left" saving={true} color="white" />
                                :
                                    "Save Changes"
                            }
                        </Button>
                        {
                            saving === false ?
                                <Button onClick={() => this.updatePackageDetail(true)} type="button" color="info">Save & Pay</Button>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        )
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    render() {
        const { success, showError } = this.state;
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="editPackage-slide-title"
                aria-describedby="editPackage-slide-description"
            >
                <DialogTitle
                    id="editPackage-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h5 className={classes.modalTitle}>Assign Package</h5>
                </DialogTitle>
                <DialogContent
                    id="editPackage-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            success ?
                                <>
                                    <p className={classes.successMessage}>Changes saved successfully.</p>
                                    <Button
                                        color="info"
                                        onClick={() => this.props.onSuccess()}
                                    >
                                        Close
                                    </Button>
                                </>
                            :
                                <>
                                    {
                                        showError ?
                                            <div className={classes.errorsSections}>
                                                { this.renderErrorMessages() }
                                            </div>
                                        :
                                        <></>
                                    }
                                    { this.renderForm() }
                                </>
                        }
                </DialogContent>      
            </Dialog>
        );
    }
};

EditPackageModal.defaultProps = {
    open: false,
};
EditPackageModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    editPackageDetail: PropTypes.object.isRequired,
};
export default withStyles(editPackageModalStyle)(EditPackageModal);
