import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import Close from "@material-ui/icons/Close";
import FileCopy from "@material-ui/icons/FileCopy"
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteFileModal from "../components/Home/DeleteFileModal";
import ReactTable from "react-table";
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import EditIcon from "@material-ui/icons/Edit";
import AppDetailsModal from "../components/Home/AppDetailsModal";
import Snackbar from '@material-ui/core/Snackbar';
import { helper } from '../../assets/js/utils/Element';
import IconButton from '@material-ui/core/IconButton';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import appsPageStyle from "../../assets/jss/appsPageStyle";

class Apps extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        this.defaultAppDetails = {
            category: "",
            buildDate: new Date().toISOString(),
            buildVersion: "",
            file: null
        };

        this.state = {
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            deleteFileModal: false,
            limit: 50,
            deleteAll: false,
            appDetailsModal: false,
            appDetails: this.defaultAppDetails,
            editMode: false,
            snackbarOpen: false,
        };        

        this.appCategories = Api.getAppCategories();
        this.handleToggle = this.handleToggle.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onModalSuccess = this.onModalSuccess.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
        this.copyDownloadLink = this.copyDownloadLink.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);
    }
    componentDidMount(){
        this.loadApps();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
      }
    loadApps(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 200,
        };
        Api.getApps(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            appDetailsModal: false,
            editMode: false
        });
    }
    copyDownloadLink(link){
        helper.copyTextToClipboard(link);
        this.setState({snackbarOpen: true});
    }
    closeSnackbar(){
        this.setState({snackbarOpen: false});
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(file => {
            let fileArray = {
                id: file.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(file.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(file.id)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                category: this.getCategory(file),
                name: file.name,
                build_version: file.build_version,
                build_date: file.build_date,
                size: file.size,
                actions: (
                    <>
                        <Button justIcon size="sm" color="primary" onClick={() => this.copyDownloadLink(file.url)}>
                            <FileCopy />
                        </Button>
                        <Button justIcon size="sm" color="info" onClick={() => this.onEditModal(true, file)}>
                            <EditIcon />
                        </Button>
                        <Button justIcon size="sm" color="danger" onClick={() => this.deleteFile(file)}>
                            <Close />
                        </Button>
                    </>
                )
            };
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    deleteSelected(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        this.setState({deleteFileModal: true});
    }
    deleteAll(){
        this.setState({deleteFileModal: true, deleteAll: true});
    }
    deleteFile(file){
        let checked = [];
        checked.push(file.id);
        this.setState({deleteFileModal: true, checked: checked});
    }
    onModalClose(deleteFileModal = true){
        let checked = this.state.checked;
        if(deleteFileModal === false){
            checked = [];
        }
        this.setState({
            deleteFileModal: deleteFileModal,
            checked: checked,
            deleteAll: false
        });
    }
    onModalSuccess(){
        const { checked, response, deleteAll } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            apps: checked,
            clean: (deleteAll ? 1: 0)
        };
        Api.deleteApps(requestData, source).then(data => {
            //Handle Success;
        }).catch(err => {
            //Handle Error
        });
        if(deleteAll){
            this.setState({
                response: [],
                checked: [],
                page: 1,
                totalPages: 1,
                deleteFileModal: false,
                deleteAll: false
            });
            return;
        }

        let newResponse = [];
        response.map(file => {
            if(!checked.includes(file.id)){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteFileModal: false,
            deleteAll: false
        });
    }
    onAppDetailsModalClose(status = true){
        if(status === false){
            this.loadApps();
            return;
        }
        this.setState({
            appDetailsModal: status,
        });
    }
    onAppDetailsModalSuccess(){
        this.loadApps();
    }
    getCategory(file){
        let category = "";
        this.appCategories.map((item) => {
            if(item.key === file.category){
                category = item.value;
            }
            return null;
        });
        return category;
    }
    onEditModal(status = true, file){
        if(!status){
            this.loadApps(false);
            return;
        }
        this.setState({
            appDetailsModal: status,
            appDetails: {
                id: file.id,
                category: file.category,
                buildDate: new Date(file.build_date).toISOString(),
                buildVersion: file.build_version,
                file: null
            },
            editMode: status
        });
    }
    render() {
        const { classes } = this.props;
        const { loading, page, loadingMore, totalPages, deleteFileModal, checked, limit, deleteAll, appDetailsModal, appDetails, editMode } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        const tableData = this.getTableData();
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <PhonelinkSetupIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Meta Data
                                <Button round color="primary" type="button" className={classes.addButton} onClick={() => this.onAppDetailsModalClose(true)}>Add Meta Data</Button>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loading ?
                                    <LoaderComponent align="center" />
                                :
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                headerClassName: "hd_check",
                                                className: "hd_check td_check",
                                            },
                                            {
                                                Header: "",
                                                accessor: "check",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_check",
                                                className: "hd_check td_check",
                                            },
                                            {
                                                Header: "Category",
                                                accessor: "category",
                                                headerClassName: "hd_category",
                                                className: "hd_category td_category",
                                            },
                                            {
                                                Header: "Build Date",
                                                accessor: "build_date",
                                                headerClassName: "hd_build_date",
                                                className: "hd_build_date td_build_date",
                                            },
                                            {
                                                Header: "Build Version",
                                                accessor: "build_version",
                                                headerClassName: "hd_build_version",
                                                className: "hd_build_version td_build_version",
                                            },
                                            {
                                                Header: "Name",
                                                accessor: "name",
                                                headerClassName: "hd_name",
                                                className: "hd_name td_name",
                                            },
                                            {
                                                Header: "Size",
                                                accessor: "size",
                                                headerClassName: "hd_size",
                                                className: "hd_size td_size",
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_actions",
                                                className: "hd_actions td_actions",
                                            }
                                        ]}
                                        data={tableData}
                                        pageSizeOptions={[50, 100, 200]}
                                        defaultPageSize={limit}
                                        showPaginationTop
                                        minRows={0}
                                        showPaginationBottom={false}
                                        className={"-striped -highlight "+classes.appsTable}
                                    />
                            }
                            <div className={classes.loadMore}>
                                {
                                    loadingMore === false && tableData.length > 0 ?
                                        <>
                                            <Button color="primary" disabled={(checked.length === 0)} round onClick={(e) => this.deleteSelected()}>
                                                <Close className={classes.icons} />Delete Selected
                                            </Button>
                                            {
                                                user.level_id === 1 ?
                                                    <Button color="primary" round onClick={(e) => this.deleteAll()}>
                                                        <Close className={classes.icons} />Delete All
                                                    </Button>
                                                :
                                                <></>
                                            }
                                        </>
                                    :
                                    <></>
                                }
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="primary" id="contentViewMore" round onClick={(e) => this.loadApps(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <DeleteFileModal deleteAll={deleteAll} open={deleteFileModal} onClose={() => this.onModalClose(false)} onSuccess={() => this.onModalSuccess()} />
                {
                    appDetailsModal ?
                        <AppDetailsModal open={appDetailsModal} editMode={editMode} appDetails={appDetails} onClose={() => this.onAppDetailsModalClose(false)} onSuccess={() => this.onAppDetailsModalSuccess()} />
                    :
                    <></>
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={this.state.snackbarOpen}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Your link has been copied</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.closeSnackbar}
                    >
                        <Close />
                    </IconButton>,
                    ]}
                />
            </GridContainer>
        );
    }
}

Apps.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(appsPageStyle)(Apps);
