import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import Icon from "@material-ui/core/Icon";
import EditIcon from "@material-ui/icons/Edit";
import GridItem from "../../../components/Grid/GridItem.jsx";
import PasswordMatch from "../../../../assets/js/utils/PasswordMatch";

import Api from "../../../../assets/js/utils/Api";
import CustomInput from "../../CustomInput/CustomInput.jsx";
import CustomSelect from "../../CustomInput/CustomSelect.jsx";

import addAccountModalStyle from "../../../../assets/jss/home/addAccountModalStyle";

const Step1 = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { accountDetails, editMode } = this.props;

        this.state = {
            accountDetails: accountDetails,
            firstName: accountDetails.firstName,
            lastName: accountDetails.lastName,
            email: accountDetails.email,
            password: accountDetails.password,
            passwordConfirm: accountDetails.passwordConfirm,
            timezone: accountDetails.timezone,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                passwordConfirm: "",
                isValid: false,
                isValidate: false,
                validateForm: false
            },
            editMode: editMode,
            viewPassword: false,
            viewConfirmPassword: false
        };

        this.appCategories = Api.getAppCategories();
        this.timezoneOptions = Api.getTimezoneOptions();
    }
    isValidated() {
        const isValid = this.validateForm();
        if(!isValid){
            return false;
        }

        return true;
    }
    validatePassword(){
        if(this.state.validation.validateForm){
            this.validateForm();
            return;
        }
        let validation = {
            password: "success",
            passwordConfirm: "success",
            isValid: true
        };

        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
                showPasswordErrors: true,
                requireLowerletter: requireLowerletter,
                requireUpperletter: requireUpperletter,
                requireNumber: requireNumber,
                requireSymbol: requireSymbol,
                requireLength: requireLength
            });
        });
        
        if(this.state.password.length <= 0 || check === false){
            validation.password = "error";
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            validation.passwordConfirm = "error";
            validation.isValid = false;
        }
        validation.isValidate = true;

        this.setState({validation: validation});
    }
    validateForm(){
        let validation = {
            firstName: "success",
            lastName: "success",
            email: "success",
            password: "success",
            passwordConfirm: "success",
            timezone: "success",
            isValid: true
        };
        if(this.state.firstName.length <= 0){
            validation.firstName = "error";
            validation.isValid = false;
        }
        if(this.state.lastName.length <= 0){
            validation.lastName = "error";
            validation.isValid = false;
        }
        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        
        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        
        if((this.state.password.length <= 0 && this.state.editMode === false) || (this.state.password.length > 0 && check === false)){
            validation.password = "error";
            validation.isValid = false;
        }
        if((this.state.passwordConfirm.length <= 0 && this.state.editMode === false) || this.state.password !== this.state.passwordConfirm){
            validation.passwordConfirm = "error";
            validation.isValid = false;
        }
        if(this.state.timezone.length <= 2){
            validation.timezone = "error";
            validation.isValid = false;
        }
        validation.isValidate = true;
        validation.validateForm = true;

        this.setState({validation: validation});
        return validation.isValid;
    }
    sendState() {
        return this.state;
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state,() => {
            if(name === 'password' || name === 'passwordConfirm'){
                this.validatePassword();
            }
        });
    }
    renderForm(){
        const { validation, firstName, lastName, email, password, requireLowerletter, requireUpperletter, requireNumber, 
            requireSymbol, requireLength, passwordConfirm } = this.state;
        const showPasswordErrors = (validation.password === "success" || validation.password === "error");
        const showPasswordConfirmErrors = (validation.passwordConfirm === "success" || validation.passwordConfirm === "error");
        const requirePasswordConfirm = (passwordConfirm.length > 0 && password === passwordConfirm);
        const { classes } = this.props;
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.addAccount}>
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            success={validation.firstName === "success"}
                            error={validation.firstName === "error"}
                            id="input-firstName"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'firstName'),
                                name: "firstName",
                                value: firstName,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"First Name"
                            }}
                        />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            success={validation.lastName === "success"}
                            error={validation.lastName === "error"}
                            id="input-lastName"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'lastName'),
                                name: "lastName",
                                value: lastName,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Last Name"
                            }}
                        />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            success={validation.email === "success"}
                            error={validation.email === "error"}
                            id="input-email"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'email'),
                                name: "email",
                                value: email,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <Email className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Email",
                                type: "email"
                            }}
                        />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            success={validation.password === "success"}
                            error={validation.password === "error"}
                            id="input-password"                                        
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'password'),
                                name: "password",
                                type: "password",
                                value: password,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment}>
                                        <Icon className={classes.inputAdornmentIcon}>lock_outline </Icon>
                                    </InputAdornment>
                                ),
                                placeholder:"Password"
                            }}
                        />
                        {
                            showPasswordErrors || password.length > 0 ?
                                <GridItem>
                                    <div>
                                        <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                        </div>
                                        <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                        </div>
                                        <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-symbols">Password must contain a number</span>
                                        </div>
                                        <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                        </div>
                                        <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                        </div>
                                    </div>
                                </GridItem>
                            :
                            <></>
                        }
                        <CustomInput
                            success={validation.passwordConfirm === "success"}
                            error={validation.passwordConfirm === "error"}
                            id="input-signupPasswordConfirm"
                            inputProps={{
                                required: true,
                                onChange: (e) => this.handleChange(e,'passwordConfirm'),
                                name: "passwordConfirm",
                                type: "password",
                                value: passwordConfirm,
                                placeholder:"Type Password Again",
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment}>
                                        <Icon className={classes.inputAdornmentIcon}>lock_outline </Icon>
                                    </InputAdornment>
                                ),
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                        />
                        {
                            showPasswordConfirmErrors ?
                                <GridItem>
                                    <div>
                                        <div className={(requirePasswordConfirm?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requirePasswordConfirm? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-lowerletter">Password do not match</span>
                                        </div>
                                    </div>
                                </GridItem>
                            :
                            null
                        }
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="Timezone"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"timezone"),
                                value: this.state.timezone
                            }}
                            inputProps={{
                                name: "timezone",
                                id: "input-timezone",
                                className: classes.alignLeft
                            }}
                            success={validation.timezone === "success"}
                            error={validation.timezone === "error"}
                            items={this.timezoneOptions}
                            itemLabel="value"
                            itemValue="key"
                        />
                    </div>
                </div>
            </div>
        )
    }
    render() {
        return (
            <>
                {
                    this.renderForm()
                }
            </>
        );
    }
};

Step1.defaultProps = {
    onMount: function(){ }
};
Step1.propTypes = {
    classes: PropTypes.object,
    onMount: PropTypes.func
};
export default withStyles(addAccountModalStyle)(Step1);
