import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api.js";
import LoaderComponent from "../components/Loader.js";
import Button from "../components/CustomButtons/Button.jsx";
import CustomInput from "../components/CustomInput/CustomInput.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import PaymentIcon from "@material-ui/icons/Payment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import settingsPageStyle from "../../assets/jss/settingsPageStyle.jsx";

class PricingSchedule extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.editor = React.createRef();
        this.history = this.props.history;
        this.state = {
            cancelToken: null,
            loading: false,
            showError: false,
            errorMessage: "",
            loadingSetting: false,
            setting: {
                costsPerGig: "",
            },
            validation: {
                costsPerGig: ""
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        this.loadSetting();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleChange(event, name) {
        this.setState({
            setting: {
                ...this.state.setting,
                [name]: event.target.value
            }
        });
    }
    loadSetting(){
        const source = axios.CancelToken.source();
        const requestData = {};
        Api.getSetting(requestData, source).then(data => {
            this.setState({
                setting: data,
                loadingSetting: false
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loadingSetting: true, 
            cancelToken: source,
        });
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        Api.saveSetting(this.state.setting, source).then(data => {
            this.setState({
                loading: false, 
                showError: false, 
                cancelToken: null,
                errorMessage: ""
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    validateForm(){
        let validation = {
            costsPerGig: 'success',
            isValid: true
        };
        const { setting } = this.state;
        if(setting.costsPerGig.length <= 0){
            validation.costsPerGig = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    render() {
        const { classes } = this.props;
        const { loading, loadingSetting, setting, validation } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <PaymentIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Pricing Schedule</h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loadingSetting ?
                                    <LoaderComponent align="left" />
                                :
                                    <form onSubmit={this.handleSubmit} noValidate>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <a href={"https://aws.amazon.com/s3/pricing/?nc=sn&loc=4"} target="_blank" rel="noopener noreferrer">
                                                AWS S3 Pricing
                                            </a>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <CustomInput
                                                success={validation.costsPerGig === "success"}
                                                error={validation.costsPerGig === "error"}
                                                labelText="Costs Per Gig"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,"costsPerGig"),
                                                    value: setting.costsPerGig
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem lg={6} md={6} sm={12}>
                                            {
                                                loading ?
                                                    <LoaderComponent align="left" />
                                                :
                                                <Button type="submit" color="info">
                                                    Save Changes
                                                </Button>
                                            }
                                        </GridItem>
                                    </form>
                            }
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

PricingSchedule.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(settingsPageStyle)(PricingSchedule);
