import {
    whiteColor,
    cardTitle
} from "./material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "./material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const downloadsPageStyle = {
    ...customCheckboxRadioSwitchStyle,
    main: {
        background: whiteColor
    },
    loadMore: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    addButton: {
        float: "right",
        marginTop: "0px !important"
    },
    imagePreview: {
        width: "50px",
        height: "50px",
        objectFit: "cover"
    },
    downloadsTable: {
        "& .rt-thead .rt-th": {
            fontSize: "14px",
            "&.hd_hide": {
                display: 'none'
            }
        },
        "& .rt-tbody .rt-td": {
            fontSize: "12px",
            padding: "0px 5px",
            "&.td_hide": {
                display: "none"
            }
        },
        "& .hd_check": {
            width: "50px !important",
            textAlign: "center !important",
            "& .MuiIconButton-root": {
                padding: "0px"
            }
        },
        "& .hd_id": {
            width: "30px !important",
            textAlign: "center !important",
            flex: "30 0 auto !important"
        },
        "& .hd_photo": {
            width: "60px !important",
            textAlign: "left !important",
        },
        "& .hd_category": {
            textAlign: "left !important",
        },
        "& .hd_order": {
            textAlign: "center !important",
        },
        "& .hd_actions": {
            textAlign: "right !important",
        },
    }
};

export default downloadsPageStyle;
