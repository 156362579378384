import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader';

import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import CustomInput from "../CustomInput/CustomInput.jsx";
import CustomSelect from "../CustomInput/CustomSelect.jsx";
import DateTimePicker from "../CustomInput/DateTimePicker";
import { helper } from '../../../assets/js/utils/Element';

import signupControlsModalStyle from "../../../assets/jss/home/signupControlsModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const SignupControlsModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { signupControlDetails, accountTypes, allAccountTypes } = this.props;

        this.state = {
            signupControlDetails: signupControlDetails,
            accountTypes: accountTypes,
            allAccountTypes: allAccountTypes,
            accountType: signupControlDetails.accountType,
            signupsAllowed: signupControlDetails.signupsAllowed,
            fromDateTime: signupControlDetails.fromDateTime,
            toDateTime: signupControlDetails.toDateTime,
            formPath: signupControlDetails.formPath,
            quota: signupControlDetails.quota,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                accountType: "",
                isValid: false
            },
            uploaded: 0
        };

        this.updateSignupControlDetails = this.updateSignupControlDetails.bind(this);
    }
    updateSignupControlDetails(){
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }

        const { accountType, signupsAllowed, fromDateTime, toDateTime, formPath, signupControlDetails } = this.state;
        let quota = helper.getBytesSize(this.state.quota);
        const requestData = {
            account_type: accountType,
            signups_allowed: signupsAllowed,
            from_datetime: fromDateTime,
            to_datetime: toDateTime,
            form_path: formPath,
            quota: quota,
        };
        
        if(signupControlDetails.hasOwnProperty("id")){
            requestData['id'] = signupControlDetails.id;
        }

        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.updateSignupControlDetails(requestData, source).then(data => {
            that.setState({
                success: true,
                saving: false
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    saving: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    handleDateChange(date, name){
        try{
            if(date === 'Invalid Date'){
                return true;
            }
            let state = {};
            state[name] = date.toISOString();
            this.setState(state);
        }catch(e){ console.log(e); } 
    }
    validateForm(){
        let validation = {
            accountType: "success",
            isValid: true
        };
        const { accountType } = this.state;
        if(accountType.length <= 0 && this.props.editMode === false){
            validation.accountType = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    
    renderForm(){
        const { saving, signupsAllowed, fromDateTime, toDateTime, formPath, quota, validation, accountTypes, allAccountTypes } = this.state;
        const { classes,editMode } = this.props;
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.formFiles+" uploader-files"}>
                        {
                            editMode ?
                                <CustomSelect
                                    success={validation.accountType === "success"}
                                    error={validation.accountType === "error"}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.customSelectFormControl
                                    }}
                                    labelText="Type"
                                    selectProps={{
                                        onChange: (e) => (e),
                                        value: this.state.accountType
                                    }}
                                    inputProps={{
                                        name: "accountType",
                                        id: "input-accountType",
                                        className: classes.alignLeft,
                                        disabled: true
                                    }}
                                    color="info"
                                    items={allAccountTypes}
                                    itemLabel="label"
                                    itemValue="id"
                                />
                            :
                                <CustomSelect
                                    success={validation.accountType === "success"}
                                    error={validation.accountType === "error"}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.customSelectFormControl
                                    }}
                                    labelText="Type"
                                    selectProps={{
                                        onChange: (e) => this.handleChange(e,"accountType"),
                                        value: this.state.accountType
                                    }}
                                    inputProps={{
                                        name: "accountType",
                                        id: "input-accountType",
                                        className: classes.alignLeft
                                    }}
                                    color="info"
                                    items={accountTypes}
                                    itemLabel="label"
                                    itemValue="id"
                                />
                        }
                        <CustomInput
                            id="input-signupsAllowed"
                            labelText="Signup Alloweds"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'signupsAllowed'),
                                value: signupsAllowed ? String(signupsAllowed) : "",
                                name: "signupsAllowed",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <CustomInput
                            id="input-quota"
                            labelText="Storage Space (GB)"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'quota'),
                                value: quota ? String(quota) : "",
                                name: "quota",
                                type: "number",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <DateTimePicker
                            labelText="From Date/Time"
                            id="input-fromDateTime"
                            value={fromDateTime ? fromDateTime : ""}
                            color="info"
                            onChange={(date) => this.handleDateChange(date, 'fromDateTime')}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.datePicker
                            }}
                        />
                        <DateTimePicker
                            labelText="To Date/Time"
                            id="input-toDateTime"
                            value={toDateTime ? toDateTime : ""}
                            color="info"
                            onChange={(date) => this.handleDateChange(date, 'toDateTime')}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.datePicker
                            }}
                        />
                        <CustomInput
                            id="input-formPath"
                            labelText="Form Path"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'formPath'),
                                value: formPath ? formPath : "",
                                name: "formPath",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </div>
                    <div className={classes.transferFooter+" "+classes.fileSendingFormFooter}>
                        <Button onClick={this.updateSignupControlDetails} type="button" color="info">
                            {
                                saving ?
                                    <LoaderComponent align="left" saving={true} color="white" />
                                :
                                    "Save Changes"
                            }
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { success } = this.state;
        const { classes, editMode } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="signupControlDetails-slide-title"
                aria-describedby="signupControlDetails-slide-description"
            >
                <DialogTitle
                    id="signupControlDetails-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h5 className={classes.modalTitle}>
                        {
                            editMode ?
                                "Edit Signup Control Data"
                            :
                            "Add Signup Control Data"
                        }
                    </h5>
                </DialogTitle>
                <DialogContent
                    id="signupControlDetails-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            success ?
                                <>
                                    <p className={classes.successMessage}>Changes saved successfully.</p>
                                    <Button
                                        color="info"
                                        onClick={() => this.props.onClose()}
                                    >
                                        Close
                                    </Button>
                                </>
                            :
                            this.renderForm()
                        }
                </DialogContent>      
            </Dialog>
        );
    }
};

SignupControlsModal.defaultProps = {
    open: false,
    editMode: false,
};
SignupControlsModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    signupControlDetails: PropTypes.object.isRequired,
    editMode: PropTypes.bool
};
export default withStyles(signupControlsModalStyle)(SignupControlsModal);
