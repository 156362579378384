import { whiteColor, blackColor, grayColor } from "../material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const UserTypeModalStyle = theme => ({
    ...customCheckboxRadioSwitchStyle,
    modalRoot: {
        overflow: "auto",
        display: "block"
    },
    modal: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: "500px",
        },
        borderRadius: "6px",
        overflow: "visible",
        maxHeight: "unset",
        width: "60%",
        marginTop: "5px !important",
        boxShadow: "0px 11px 15px -7px rgba(255, 255, 255, 0.2), "+
                "0px 24px 38px 3px rgba(255, 255, 255, 0.14), "+
                "0px 9px 46px 8px rgba(255, 255, 255, 0.12)",
        [theme.breakpoints.down("sm")]: {
            marginTop: "5px !important",
            margin: theme.spacing(3)
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "5px !important",
            margin: theme.spacing(2),
            width: "400px",
            maxWidth: "100%"
        }
    },
    modalHeader: {
        borderBottom: "none",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        minHeight: "0px"
    },
    modalTitle: {
        margin: "0",
        lineHeight: "1.5",
        textAlign: "center",
        fontWeight: "500",
        marginBottom: "20px"
    },
    modalCloseButton: {
        "&, &:hover": {
            color: whiteColor
        },
        "&:hover": {
            opacity: "1"
        },
        cursor: "pointer",
        padding: "1rem",
        margin: "-1rem -1rem -1rem auto",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        float: "right",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 " + whiteColor,
        opacity: ".5",
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalBody: {
        padding: "0px !Important",
        position: "relative",
        overflow: "visible",
        textAlign: "center",
        marginBottom: "20px",
        "& .wizardContainer>div": {
            padding: "0px",
            margin: "0px",
            boxShadow: "none",
        },
        "& .sd_loader": {
            marginTop: "10px"
        },
        "& .wizardHeader": {
            padding: "10px 0px 0px"
        },
        "& .wizardContent": {
            marginTop: "0px",
            paddingTop: "10px",
            paddingBottom: "0px"
        },
        "& .MuiFormControl-root": {
            paddingTop: "10px"
        },
        "& .datePickerFormControl": {
            marginBottom: "17px"
        },
        "& [class*='selectFormControl']": {
            marginTop: "-15px !important"
        }
    },
    modalFooter: {
        padding: "15px",
        margin: "0 auto"
    },
    modalFooterCenter: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    selectAccountType: {
        "& .MuiFormControlLabel-root": {
            display: "flex",
            maxWidth: "300px",
            margin: "0px auto 10px auto",
            "& $label": {
                display: "block",
                fontWeight: "bold",
                textAlign: "left",
                color: blackColor,
            },
            "& p": {
                fontWeight: "normal",
                margin: "5px 0px",
                color: grayColor[7],
            }
        }
    },
});

export default UserTypeModalStyle;
