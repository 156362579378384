import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LoaderComponent from '../Loader';
import GridItem from "../../components/Grid/GridItem.jsx";

import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Wizard from "../../components/Wizard/Wizard.jsx";
import Step1 from "./AddAccountSteps/Step1.jsx";
import Step2 from "./AddAccountSteps/Step2.jsx";

import addAccountModalStyle from "../../../assets/jss/home/addAccountModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const AddAccountModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { accountDetails, editMode } = this.props;

        this.state = {
            accountDetails: accountDetails,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            loading: false,
            user: null,
            editMode: editMode,
            stepId: 0
        };

        this.appCategories = Api.getAppCategories();
        this.handleFinish = this.handleFinish.bind(this);
        this.onStepMount = this.onStepMount.bind(this);
    }
    onStepMount(stepId, state, stepChanged = false){
        this.setState({
            stepId: (stepChanged ? stepId : this.state.stepId),
            allStates: state
        });
    }
    handleFinish(allStates, payNow = false){
        const { accountDetails, editMode } = this.state;

        const step1 = allStates.step1;
        const step2 = allStates.step2;

        const requestData = {
            id: accountDetails.userId,
            firstName: step1.firstName,
            lastName: step1.lastName,
            email: step1.email,
            password: step1.password,
            timezone: step1.timezone,
            accountName: step2.accountName,
            cell: step2.cell,
            phone: step2.phone,
            address1: step2.address1,
            address2: step2.address2,
            address3: step2.address3,
            accountType: step2.accountType,
            countryId: step2.countryId,
            stateId: step2.stateId,
            cityId: step2.cityId,
            dob: step2.dob,
            terms: step2.terms,
            promotions: step2.promotions,
            userType: 'personal'
        };
        if(editMode === true){
            requestData.first_name = step1.firstName;
            requestData.last_name = step1.lastName;
            requestData.account_name = step2.accountName;
            requestData.country_id = step2.countryId;
            requestData.state_id = step2.stateId;
            requestData.city_id = step2.cityId;
        }

        const source = axios.CancelToken.source();
        this.setState({
            loading: true, 
            cancelToken: source,
            showError: false,
            stepId: 1
        });

        const that = this;
        if(editMode === true) {
            Api.updateAccount(requestData, source).then(data => {
                that.setState({
                    loading: false, 
                    user: data.user
                });
    
                this.props.onSuccess(data.user, false, payNow);
            }).catch(err => {
                if(typeof(err) === "object" && err.hasOwnProperty("message")){
                    this.setState({
                        loading: false, 
                        showError: true, 
                        cancelToken: null,
                        errorMessage: err.message,
                    });
                }
            });

            return;
        }
        Api.signUp(requestData, source).then(data => {
            that.setState({
                loading: false, 
                user: data.user
            });

            this.props.onSuccess(data.user, true, payNow);
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message,
                });
            }
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    render() {
        const { stepId, showError, accountDetails, loading, editMode } = this.state;
        const { classes } = this.props;
        
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal,
                    scrollPaper: classes.scrollPaper
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="addAccount-slide-title"
                aria-describedby="addAccount-slide-description"
            >
                <DialogContent
                    id="addAccount-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            showError ?
                                <div className={classes.errorsSections}>
                                    { this.renderErrorMessages() }
                                </div>
                            :
                            <></>
                        }
                        <Wizard
                            validate
                            store={this.store}
                            steps={[
                                { stepName: "Basic Info", stepComponent: Step1, stepId: "step1", props: {accountDetails: accountDetails, editMode: editMode} },
                                { stepName: "Additional Info", stepComponent: Step2, stepId: "step2", props: {accountDetails: accountDetails}  },
                            ]}
                            title={(editMode ? "Edit Account" : "Add Account")}
                            subtitle=""
                            finishButtonText={(
                                editMode ? "Update Account" : "Add Account"
                            )}
                            finishButtonClick={this.handleFinish}
                            onStepMount={this.onStepMount}
                            currentStep={stepId}
                            color="primary"
                            proceedButtonText={(
                                editMode ? "Update Account & Pay" : "Add Account & Pay"
                            )}
                            proceedButton={true}
                            proceedButtonClick={(allStates) => this.handleFinish(allStates, true)}
                            proceedButtonClasses={classes.proceedButton}
                        />
                        <div className={classes.modalFooter + " " + classes.justifyContentCenter}>
                            {
                                loading ?
                                    <LoaderComponent />
                                :
                                    <Button onClick={() => this.props.onClose()} color="primary" round>Cancel</Button>
                            }
                        </div>
                </DialogContent>   
            </Dialog>
        );
    }
};

AddAccountModal.defaultProps = {
    open: false,
    editMode: false,
};
AddAccountModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    accountDetails: PropTypes.object.isRequired,
    editMode: PropTypes.bool
};
export default withStyles(addAccountModalStyle)(AddAccountModal);
