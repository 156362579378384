import {
    whiteColor,
    cardTitle
} from "./material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "./material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const signupRequestsPageStyle = {
    ...customCheckboxRadioSwitchStyle,
    main: {
        background: whiteColor
    },
    loadMore: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    addButton: {
        float: "right",
        marginTop: "0px !important"
    },
    signupRequestsTable: {
        "& .rt-noData": {
            position: "static",
            transform: "none"
        },
        "& .rt-thead .rt-th": {
            fontSize: "14px",
            "&.hd_hide": {
                display: 'none'
            }
        },
        "& .rt-tbody .rt-td": {
            fontSize: "12px",
            padding: "0px 5px",
            "&.td_hide": {
                display: "none"
            }
        },
        "& .hd_check": {
            textAlign: "center !important",
            "& .MuiIconButton-root": {
                padding: "0px"
            }
        },
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: "center",
        "& .MuiFormControl-root": {
            marginRight: "10px !important",
            minWidth: "160px",
            marginTop: "0px !important",
        },
        "& .datePickerFormControl": {
            marginBottom: "5px"
        }
    },
};

export default signupRequestsPageStyle;
