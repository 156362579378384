import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import deleteFileModalStyle from "../../../assets/jss/home/deleteFileModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DeleteFileModal = class extends React.Component {
  render() {
    const { classes, deleteAll } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="deleteFile-slide-title"
        aria-describedby="deleteFile-slide-description"
      >
        <DialogContent
          id="deleteFile-slide-description"
          className={classes.modalBody}
        >
            {
                deleteAll ?
                    <p>Are you sure you want to delete all files?</p>
                :
                    <p>Are you sure you want to delete?</p>
            }
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.justifyContentCenter}
        >
          <Button onClick={() => this.props.onSuccess()} color="danger" round>Delete</Button>
          <Button onClick={() => this.props.onClose()} color="primary" round>Cancel</Button>
        </DialogActions>        
      </Dialog>
    );
  }
};

DeleteFileModal.defaultProps = {
  open: false,
  deletingUser: false,
  deleteAll: false
};
DeleteFileModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  deleteAll: PropTypes.bool
};
export default withStyles(deleteFileModalStyle)(DeleteFileModal);
