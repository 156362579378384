/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid
} from "../..//material-dashboard-pro-react.jsx";

const appStyle = theme => ({
    wrapper: {
        position: "relative",
        top: "0",
        height: "100vh",
        "&:after": {
            display: "table",
            clear: "both",
            content: '" "'
        }
    },
    mainPanel: {
        // transitionProperty: "top, bottom, width",
        // transitionDuration: ".2s, .2s, .35s",
        // transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        // overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        // overflowScrolling: "touch"
    },
    content: {
        marginTop: "70px",
        padding: "30px 15px",
        minHeight: "calc(100vh - 123px)"
    },
    container: { ...containerFluid },
    map: {
        marginTop: "70px"
    },
    mainPanelSidebarMini: {
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerMiniWidth}px)`
        }
    },
    mainPanelWithPerfectScrollbar: {
        overflow: "hidden !important"
    },
    mainApp: {
        "&[class*='layout_page_user_onboarding']": {
            "& .mainContentPanel": {
                width: "100%"
            }
        }
    }
});

export default appStyle;
