import React from "react";
import axios from "axios";
import moment from 'moment';
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import Close from "@material-ui/icons/Close";
import Assignment from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteFileModal from "../components/Home/DeleteFileModal";
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import filesPageStyle from "../../assets/jss/filesPageStyle";

class Files extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.state = {
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            deleteFileModal: false,
            limit: 50,
            deleteAll: false
        };
        this.handleToggle = this.handleToggle.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onModalSuccess = this.onModalSuccess.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
    }
    componentDidMount(){
        this.loadFiles();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
      }
    loadFiles(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 50,
            filesOnly: 1,
            order: 'id'
        };
        Api.getFiles(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
                limit: response.length
            })
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(file => {
            const uploaded = moment(file.created_at).format("MM/DD/YYYY h:mm A");;
            let fileArray = {
                id: file.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(file.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(file.id)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                name: file.name,
                email: file.email,
                ip: file.ip,
                orignalSize: file.orignalSize,
                size: file.size,
                expires: file.expires,
                uploaded: uploaded,
                actions: (
                    <Button justIcon size="sm" color="danger" onClick={() => this.deleteFile(file)}>
                        <Close />
                    </Button>
                )
            };
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    deleteSelected(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        this.setState({deleteFileModal: true});
    }
    deleteAll(){
        this.setState({deleteFileModal: true, deleteAll: true});
    }
    deleteFile(file){
        let checked = [];
        checked.push(file.id);
        this.setState({deleteFileModal: true, checked: checked});
    }
    onModalClose(deleteFileModal = true){
        let checked = this.state.checked;
        if(deleteFileModal === false){
            checked = [];
        }
        this.setState({
            deleteFileModal: deleteFileModal,
            checked: checked,
            deleteAll: false
        });
    }
    onModalSuccess(){
        const { checked, response, deleteAll } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            files: checked,
            clean_bucket: (deleteAll ? 1: 0)
        };
        Api.deleteFiles(requestData, source).then(data => {
            //Handle Success;
        }).catch(err => {
            //Handle Error
        });
        if(deleteAll){
            this.setState({
                response: [],
                checked: [],
                page: 1,
                totalPages: 1,
                deleteFileModal: false,
                deleteAll: false
            });
            return;
        }

        let newResponse = [];
        response.map(file => {
            if(!checked.includes(file.id)){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteFileModal: false,
            deleteAll: false
        });
    }
    render() {
        const { classes } = this.props;
        const { loading, page, loadingMore, totalPages, deleteFileModal, checked, limit, deleteAll } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        const tableData = this.getTableData();
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Files</h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loading ?
                                    <LoaderComponent align="center" />
                                :
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                headerClassName: "hd_id",
                                                className: "hd_id td_id",
                                                width: 50
                                            },
                                            {
                                                Header: "",
                                                accessor: "check",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_check",
                                                className: "hd_check td_check",
                                                width: 40
                                            },
                                            {
                                                Header: "Name",
                                                accessor: "name"
                                            },
                                            {
                                                Header: "Email",
                                                accessor: "email"
                                            },
                                            {
                                                Header: "IP",
                                                accessor: "ip"
                                            },
                                            {
                                                Header: "Size",
                                                accessor: "orignalSize",
                                                headerClassName: "hd_size",
                                                className: "hd_size td_size td_hide",
                                            },
                                            {
                                                Header: "Size",
                                                accessor: "size",
                                                headerClassName: "hd_size hd_hide",
                                                className: "hd_size td_size",
                                            },
                                            {
                                                Header: "Expires",
                                                accessor: "expires",
                                                sortMethod: (a, b) => {
                                                    var a1 = new Date(a).getTime();
                                                    var b1 = new Date(b).getTime();
                                                  if(a1<b1)
                                                    return 1;
                                                  else if(a1>b1)
                                                    return -1;
                                                  else
                                                    return 0;
                                                }
                                            },
                                            {
                                                Header: "Uploaded",
                                                accessor: "uploaded",
                                                sortMethod: (a, b) => {
                                                    var a1 = new Date(a).getTime();
                                                    var b1 = new Date(b).getTime();
                                                  if(a1<b1)
                                                    return 1;
                                                  else if(a1>b1)
                                                    return -1;
                                                  else
                                                    return 0;
                                                }
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_action",
                                                className: "hd_action td_action",
                                                width: 60
                                            }
                                        ]}
                                        data={tableData}
                                        // pageSizeOptions={[50, 100, 200]}
                                        // defaultPageSize={limit}
                                        pageSizeOptions={[limit]}
                                        defaultPageSize={-1}
                                        minRows={0}
                                        showPaginationTop={true}
                                        showPaginationBottom={false}
                                        showPagination={false}
                                        className={"-striped -highlight "+(classes.reactTable)}
                                    />
                            }
                            <div className={classes.loadMore}>
                                {
                                    loadingMore === false && tableData.length > 0 ?
                                        <>
                                            <Button color="primary" disabled={(checked.length === 0)} round onClick={(e) => this.deleteSelected()}>
                                                <Close className={classes.icons} />Delete Selected
                                            </Button>
                                            {
                                                user.level_id === 1 ?
                                                    <Button color="primary" round onClick={(e) => this.deleteAll()}>
                                                        <Close className={classes.icons} />Delete All
                                                    </Button>
                                                :
                                                <></>
                                            }
                                        </>
                                    :
                                    <></>
                                }
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="primary" id="contentViewMore" round onClick={(e) => this.loadFiles(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <DeleteFileModal deleteAll={deleteAll} open={deleteFileModal} onClose={() => this.onModalClose(false)} onSuccess={() => this.onModalSuccess()} />
            </GridContainer>
        );
    }
}

Files.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(filesPageStyle)(Files);
