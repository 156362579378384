import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    close: {
      padding: theme.spacing(0.5),
    },
}));

export default function NewUpdate(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        open: false,
    });
    
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('controllerchange', function () {
            showUpdate();
        });
    }

    function update(){
        setState({
            open: false,
        });
        window.location.reload(true);
    }
    function cancel(){
        setState({
            ...state,
            open: false,
        });
    }
    function showUpdate(){
        setState({
            open: true
        });
    }
    const { open } = state;
    return (
        <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            open={open}
            onClose={cancel}
            ContentProps={{
            'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Would you like the latest code?</span>}
            action={[
            <Button key="undo" color="secondary" size="small" onClick={update}>
                Yes
            </Button>,
            <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={cancel}
            >
                <CloseIcon />
            </IconButton>,
            ]}
        />
    )
}