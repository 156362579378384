import {
    whiteColor,
    cardTitle,
    dangerColor
} from "./material-dashboard-pro-react.jsx";

import hoverCardStyle from "./material-dashboard-pro-react/hoverCardStyle.jsx";
const homePageStyle = theme => ({
    ...hoverCardStyle,
    main: {
        background: whiteColor
    },
    loadMore: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    cardTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    loader: {
        height: "70px",
        paddingTop: "20px"
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: "center",
        "& .MuiFormControl-root": {
            marginRight: "10px !important",
            minWidth: "160px",
            marginTop: "0px !important",
        },
        "& .datePickerFormControl": {
            marginBottom: "5px"
        },
        "& .sd_loader": {
            display: "inline-flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "30px",
            margin: "initial",
            marginTop: "10px"
        }
    },
    dangerColor: {
        color: dangerColor[0],
        position: "absolute",
        left: "0px",
        right: "0px",
        top: "0px",
        bottom: "0px",
        background: "#FFC7CE",
        padding: "7px 5px"
    },
    mail: {
        position: "absolute",
        left: "0px",
        right: "0px",
        top: "0px",
        bottom: "0px",
        background: "#B4C6E7",
        padding: "7px 5px"
    },
    transfer: {
        position: "absolute",
        left: "0px",
        right: "0px",
        top: "0px",
        bottom: "0px",
        background: "#B4C6E7",
        padding: "7px 5px"
    },
    dailystorage: {
        position: "absolute",
        left: "0px",
        right: "0px",
        top: "0px",
        bottom: "0px",
        background: "#F8CBAD",
        padding: "7px 5px"
    },
    storage: {
        position: "absolute",
        left: "0px",
        right: "0px",
        top: "0px",
        bottom: "0px",
        background: "#F8CBAD",
        padding: "7px 5px"
    },
    statid: {
        position: "absolute",
        left: "0px",
        right: "0px",
        top: "0px",
        bottom: "0px",
        background: "#ED7D31",
        padding: "7px 5px"
    },
    percentage: {
        position: "absolute",
        left: "0px",
        right: "0px",
        top: "0px",
        bottom: "0px",
        background: "#C6EFCE",
        padding: "7px 5px"
    },
    statsTable: {
        "& .rt-thead": {
            "& .rt-th": {
                margin: "0px",
                "&.hd_mail": {
                    background: "#B4C6E7",
                },
                "&.hd_transfer": {
                    background: "#B4C6E7",
                },
                "&.hd_daily_storage": {
                    background: "#F8CBAD",
                },
                "&.hd_storage": {
                    background: "#F8CBAD",
                },
            }
        }
    }
});

export default homePageStyle;
