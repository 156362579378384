import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";

import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';

import dateTimePickerStyle from "../../../assets/jss/material-kit-pro-react/components/dateTimePickerStyle.jsx";

const DateTimePicker = class extends React.Component {
    render(){
        const {
            classes,
            formControlProps,
            labelText,
            inputProps,
            error,
            success,
            format,
            variant
        } = this.props;
        let { value, id } = this.props;

        if(id === null){
            id = "date-picker-dialog";
        }
        if(typeof(value) === "string" && value.length <= 0){
            value = null;
        }
        var formControlClasses;
        if (formControlProps !== undefined) {
            formControlClasses = classNames(
                formControlProps.className,
                classes.formControl
            );
        } else {
            formControlClasses = classes.formControl;
        }
        return(
            <FormControl {...formControlProps} className={formControlClasses}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>                    
                    <KeyboardDateTimePicker
                        id={id}
                        onChange={this.props.onChange}
                        format={format}
                        value={value}
                        success={success}
                        error={error}
                        label={labelText}
                        variant={variant}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        InputProps={inputProps}
                        DialogProps={{
                            className: classes.modalRoot,
                        }}
                    />
                </MuiPickersUtilsProvider>
            </FormControl>
        )
    }
}

DateTimePicker.defaultProps = {
    loading: false,
    id: null,
    onChange: function(){ },
    labelText: "Date Time Picker",
    format: "MM-dd-yyyy hh:mm:ss a",
    value: null,
    variant: "dialog",
    inputProps:{
        autoComplete: "off"
    }
}

DateTimePicker.propTypes = {
    classes: PropTypes.object.isRequired,
    id: PropTypes.string,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    onChange: PropTypes.func,
    format: PropTypes.string,
    value: PropTypes.string,
    variant: PropTypes.oneOf([
        "dialog",
        "inline",
        "static"
    ])
};
  
export default withStyles(dateTimePickerStyle)(DateTimePicker);