import Cookie from './Cookie';
import Config from '../../../../Config';
import axios from 'axios';

export default {
    hasAccessToken: function(){
        if(Cookie.read("oauth_token") && Cookie.read("oauth_token").length > 0){
            return true;
        }
        return false;
    },
    validateToken(){
        const that = this;
        return new Promise(function(resolve, reject){
            if(!that.hasAccessToken()){
                return false;
            }
            that.getUserInfo().then(data => {
                return resolve(data);
            }).catch(err => {
                return resolve(false);
            });
        });
    },
    getUserInfo(){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            axios({
                url: Config.getApiUrl()+"user/me",
                method: "GET",
                headers: headers
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch((err) => {
                reject(err);
            });
        });
    },
    isLoggedIn(){
        try{
            if(!this.hasAccessToken()){
                return false;
            }

            return true;
        }catch(e){
            this.logout(); 
            return false; 
        }
    },
    logout: function(){
        Cookie.dispose("oauth_token");
        Cookie.dispose("oauth_secret");
    },
};