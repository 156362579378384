import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Link from '@material-ui/core/Link';
import EditIcon from "@material-ui/icons/Edit";
import CustomSelect from "../../CustomInput/CustomSelect.jsx";
import LoaderComponent from '../../Loader';

import axios from "axios";
import Api from "../../../../assets/js/utils/Api";
import CustomInput from "../../CustomInput/CustomInput.jsx";

import addAccountModalStyle from "../../../../assets/jss/home/addAccountModalStyle";


const Step2 = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { accountDetails } = this.props;

        this.state = {
            accountDetails: accountDetails,
            firstName: accountDetails.firstName,
            lastName: accountDetails.lastName,
            businessName: accountDetails.businessName,
            phone: accountDetails.phone,
            address1: accountDetails.address1,
            address2: accountDetails.address2,
            address3: accountDetails.address3,
            accountType: accountDetails.planId,
            countryId: accountDetails.countryId,
            stateId: accountDetails.stateId,
            cityId: accountDetails.cityId,
            dob: accountDetails.dob,
            terms: accountDetails.terms,
            promotions: accountDetails.promotions,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                countryId: '',
                code: '',
                stateId: '',
                businessName: '',
                firstName: '',
                lastName: '',
                phone: '',
                isValid: false,
            },
            countries: [],
            states: [],
            cities: [],
            loadingCountries: false,
            loadingStates: false,
            loadingCities: false,
        };

        this.accountTypes = Api.getPlans();
        this.appCategories = Api.getAppCategories();
    }
    componentDidMount(){
        this.loadCountries();
    }
    
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    isValidated() {
        const isValid = this.validateForm();
        if(!isValid){
            return false;
        }

        return true;
    }
    validateForm(){
        let validation = {
            countryId: 'success',
            stateId: 'success',
            businessName: 'succsess',
            firstName: 'succsess',
            lastName: 'succsess',
            phone: 'succsess',
            isValid: true
        };

        
        if(this.state.firstName.length <= 0){
            validation.firstName = "error";
            validation.isValid = false;
        }
        if(this.state.lastName.length <= 0){
            validation.lastName = "error";
            validation.isValid = false;
        }
        if(this.state.businessName.length <= 0){
            validation.businessName = "error";
            validation.isValid = false;
        }
        if(this.state.phone.length <= 0){
            validation.phone = "error";
            validation.isValid = false;
        }
        if(this.state.countryId.length <= 2){
            validation.countryId = "error";
            validation.isValid = false;
        }
        if(this.state.states.length > 0 && this.state.stateId.length <= 0){
            validation.stateId = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    sendState() {
        return this.state;
    }
    loadCountries(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingCountries: true,
            countries: []
        });
        Api.getCountries(source).then(data => {
            that.setState({
                countries: data.response,
                loadingCountries: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onCountryChange(e){
        const country = e.target.value;
        this.loadStates(country);
    }
    loadStates(country){
        const { countryId } = this.state;
        if(countryId === country){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingStates: true,
            countryId: country,
            states: [],
            stateId: ""
        });
        Api.getStates(country, source).then(data => {
            that.setState({
                states: data.response,
                loadingStates: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onStateChange(e){
        const state = e.target.value;
        this.loadCities(state);
    }
    loadCities(state){
        const { stateId } = this.state;
        if(stateId === state){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        const { countryId } = this.state;
        that.setState({
            cancelToken: source,
            loadingCities: true,
            stateId: state,
            cities: [],
            cityId: ""
        });
        Api.getCities(countryId, state, source).then(data => {
            that.setState({
                cities: data.response,
                loadingCities: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    renderForm(){
        const { validation, firstName, lastName, businessName, phone, address1, terms,
            countries, states, cities, countryId, stateId, cityId, loadingCountries, loadingCities, loadingStates } = this.state;
        const { classes } = this.props;
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.addAccount}>
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            success={validation.firstName === "success"}
                            error={validation.firstName === "error"}
                            id="input-firstName"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'firstName'),
                                name: "firstName",
                                value: firstName,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Legal First Name"
                            }}
                        />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            success={validation.lastName === "success"}
                            error={validation.lastName === "error"}
                            id="input-lastName"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'lastName'),
                                name: "lastName",
                                value: lastName,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Legal Last Name"
                            }}
                        />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            success={validation.businessName === "success"}
                            error={validation.businessName === "error"}
                            id="input-businessName"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'businessName'),
                                name: "businessName",
                                value: businessName,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Legal Business Name"
                            }}
                        />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            success={validation.phone === "success"}
                            error={validation.phone === "error"}
                            id="input-phone"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'phone'),
                                name: "phone",
                                value: phone,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Business Phone"
                            }}
                        />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            id="input-address1"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'address1'),
                                name: "address1",
                                value: address1,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Street Address"
                            }}
                        />
                        
                        {
                            countries.length > 0 ?
                                <CustomSelect 
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    labelText="Country"
                                    success={validation.countryId === "success"}
                                    error={validation.countryId === "error"}
                                    selectProps={{
                                        onChange: (e) => this.onCountryChange(e),
                                        value: countryId
                                    }}
                                    inputProps={{
                                        name: "countryId",
                                        id: "input-countryId",
                                        className: classes.alignLeft
                                    }}
                                    items={countries}
                                    itemLabel="name"
                                    itemValue="id"
                                />
                            :
                                loadingCountries ?
                                    <LoaderComponent align="left" />
                                :
                                <></>
                        }
                        {
                            states.length > 0 ?
                                <CustomSelect 
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    labelText="State / Province"
                                    success={validation.stateId === "success"}
                                    error={validation.stateId === "error"}
                                    selectProps={{
                                        onChange: (e) => this.onStateChange(e),
                                        value: stateId
                                    }}
                                    inputProps={{
                                        name: "stateId",
                                        id: "input-stateId",
                                        className: classes.alignLeft
                                    }}
                                    items={states}
                                    itemLabel="name"
                                    itemValue="id"
                                />
                            :
                                loadingStates ?
                                    <LoaderComponent align="left" />
                                :
                                <></>
                        }
                        {
                            cities.length > 0 ?
                                <CustomSelect 
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    labelText="City"
                                    selectProps={{
                                        onChange: (e) => this.handleChange(e, "cityId"),
                                        value: cityId
                                    }}
                                    inputProps={{
                                        name: "cityId",
                                        id: "input-cityId",
                                        className: classes.alignLeft
                                    }}
                                    items={cities}
                                    itemLabel="name"
                                    itemValue="id"
                                />
                            :
                                loadingCities ?
                                    <LoaderComponent align="left" />
                                :
                                <></>
                        }

                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox                                                
                                    tabIndex={-1}
                                    onClick={(e) => this.handleCheckbox(e, "terms")}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                    checked={(terms)}
                                />
                            }
                            label={
                                <span>I agree to Sendlinx's <Link href="https://sendlinx.com/terms-of-use/" target="_blank">Term of Use</Link> & <Link href="https://sendlinx.com/privacy-policy/" target="_blank">Privacy Policy</Link></span>
                            }
                        />

                    </div>
                </div>
            </div>
        )
    }
    render() {
        return (
            <>
                {
                    this.renderForm()
                }
            </>
        );
    }
};

Step2.defaultProps = {
    onMount: function(){ }
};
Step2.propTypes = {
    classes: PropTypes.object,
    onMount: PropTypes.func
};
export default withStyles(addAccountModalStyle)(Step2);
