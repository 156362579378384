import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import Close from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeletePromoCodeModal from "../components/Home/DeletePromoCodeModal";
import ReactTable from "react-table";
import SettingsIcon from "@material-ui/icons/SettingsApplications";
import EditIcon from "@material-ui/icons/Edit";
import PromoCodeModal from "../components/Home/PromoCodeModal";
import { helper } from '../../assets/js/utils/Element';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import promoCodesPageStyle from "../../assets/jss/promoCodesPageStyle";

class PromoCodes extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        this.promoCodeDetails = {
            title: "",
            code: "",
            start_date: "",
            end_date: "",
            storage: "",
            max_signups: "",
            directory_name: "",
            status: 1
        };

        this.state = {
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            deletePromoCodeModal: false,
            limit: 50,
            deleteAll: false,
            promoCodeDetailsModal: false,
            promoCodeDetails: this.promoCodeDetails,
            editMode: false,
            snackbarOpen: false,
        };        

        this.appCategories = Api.getAppCategories();
        this.handleToggle = this.handleToggle.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onModalSuccess = this.onModalSuccess.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
        this.copyDownloadLink = this.copyDownloadLink.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);
    }
    componentDidMount(){
        this.loadPromoCodes();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
      }
    loadPromoCodes(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 200,
        };
        Api.getPromoCodes(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            editMode: false
        });
    }
    copyDownloadLink(link){
        helper.copyTextToClipboard(link);
        this.setState({snackbarOpen: true});
    }
    closeSnackbar(){
        this.setState({snackbarOpen: false});
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(promoCode => {
            let promoCodeArray = {
                id: promoCode.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(promoCode.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(promoCode.id)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                title: promoCode.title,
                code: promoCode.code,
                start_date: promoCode.start_date,
                end_date: promoCode.end_date,
                storage: helper.getFormatedSize(promoCode.storage),
                max_signups: promoCode.max_signups,
                signup_count: promoCode.signup_count,
                directory_name: promoCode.directory_name,
                status: (promoCode.status ? "Enabled" : "Disabled"),
                created_at: promoCode.created_at,
                actions: (
                    <>
                        <Button justIcon size="sm" color="info" onClick={() => this.onEditModal(true, promoCode)}>
                            <EditIcon />
                        </Button>
                        <Button justIcon size="sm" color="danger" onClick={() => this.deleteFile(promoCode)}>
                            <Close />
                        </Button>
                    </>
                )
            };
            tableData.push(promoCodeArray);
            return null;
        });
        return tableData;
    }
    deleteSelected(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        this.setState({deletePromoCodeModal: true});
    }
    deleteAll(){
        this.setState({deletePromoCodeModal: true, deleteAll: true});
    }
    deleteFile(file){
        let checked = [];
        checked.push(file.id);
        this.setState({deletePromoCodeModal: true, checked: checked});
    }
    onModalClose(status = true){
        let checked = this.state.checked;
        if(status === false){
            checked = [];
        }
        this.setState({
            deletePromoCodeModal: status,
            checked: checked,
            deleteAll: false
        });
    }
    onModalSuccess(){
        const { checked, response, deleteAll } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            promoCodes: checked,
            clean: (deleteAll ? 1: 0)
        };
        Api.deletePromoCodes(requestData, source).then(data => {
            //Handle Success;
        }).catch(err => {
            //Handle Error
        });
        if(deleteAll){
            this.setState({
                response: [],
                checked: [],
                page: 1,
                totalPages: 1,
                deletePromoCodeModal: false,
                deleteAll: false
            });
            return;
        }

        let newResponse = [];
        response.map(file => {
            if(!checked.includes(file.id)){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deletePromoCodeModal: false,
            deleteAll: false
        });
    }
    onPromoCodeDetailsModalClose(status = true){
        this.setState({
            promoCodeDetailsModal: status,
            promoCodeDetails: this.promoCodeDetails,
        }, () => {
            if(!status){
                this.loadPromoCodes();
            }
        });
    }
    onAppDetailsModalSuccess(){
        this.loadPromoCodes();
    }
    getCategory(file){
        let category = "";
        this.appCategories.map((item) => {
            if(item.key === file.category){
                category = item.value;
            }
            return null;
        });
        return category;
    }
    onEditModal(status = true, promoCode){
        if(!status){
            this.loadPromoCodes(false);
            return;
        }
        this.setState({
            promoCodeDetailsModal: status,
            promoCodeDetails: {
                id: promoCode.id,
                title: promoCode.title,
                code: promoCode.code,
                start_date: promoCode.start_date,
                end_date: promoCode.end_date,
                storage: (promoCode.storage/(1024*1024)), //Convert storage to MBs
                max_signups: promoCode.max_signups,
                directory_name: promoCode.directory_name,
                status: promoCode.status,
            },
            editMode: status
        });
    }
    render() {
        const { classes } = this.props;
        const { loading, page, loadingMore, totalPages, deletePromoCodeModal, checked, limit, deleteAll, promoCodeDetailsModal, promoCodeDetails, editMode } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        const tableData = this.getTableData();
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <SettingsIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Promo Codes
                                <Button round color="primary" type="button" className={classes.addButton} onClick={() => this.onPromoCodeDetailsModalClose(true)}>Add Promo Code</Button>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loading ?
                                    <LoaderComponent align="center" />
                                :
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "",
                                                accessor: "check",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_check",
                                                className: "hd_check td_check",
                                            },
                                            {
                                                Header: "Title",
                                                accessor: "title",
                                                headerClassName: "hd_title",
                                                className: "hd_title td_title",
                                            },
                                            {
                                                Header: "Code",
                                                accessor: "code",
                                                headerClassName: "hd_code",
                                                className: "hd_code td_code",
                                            },
                                            {
                                                Header: "Start Date",
                                                accessor: "start_date",
                                                headerClassName: "hd_start_date",
                                                className: "hd_start_date td_start_date",
                                            },
                                            {
                                                Header: "End Date",
                                                accessor: "end_date",
                                                headerClassName: "hd_end_date",
                                                className: "hd_end_date td_end_date",
                                            },
                                            {
                                                Header: "Quota",
                                                accessor: "storage",
                                                headerClassName: "hd_storage",
                                                className: "hd_storage td_storage",
                                            },
                                            {
                                                Header: "Signups Allowed",
                                                accessor: "max_signups",
                                                headerClassName: "hd_max_signups",
                                                className: "hd_max_signups td_max_signups",
                                            },
                                            {
                                                Header: "Signups",
                                                accessor: "signup_count",
                                                headerClassName: "hd_signup_count",
                                                className: "hd_signup_count td_signup_count",
                                            },
                                            {
                                                Header: "Directory",
                                                accessor: "directory_name",
                                                headerClassName: "hd_directory_name",
                                                className: "hd_directory_name td_directory_name",
                                            },
                                            {
                                                Header: "Status",
                                                accessor: "status",
                                                headerClassName: "hd_status",
                                                className: "hd_status td_status",
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_actions",
                                                className: "hd_actions td_actions",
                                            }
                                        ]}
                                        data={tableData}
                                        pageSizeOptions={[50, 100, 200]}
                                        defaultPageSize={limit}
                                        showPaginationTop
                                        minRows={0}
                                        showPaginationBottom={false}
                                        className={"-striped -highlight "+classes.appsTable}
                                    />
                            }
                            <div className={classes.loadMore}>
                                {
                                    loadingMore === false && tableData.length > 0 ?
                                        <>
                                            <Button color="primary" disabled={(checked.length === 0)} round onClick={(e) => this.deleteSelected()}>
                                                <Close className={classes.icons} />Delete Selected
                                            </Button>
                                            {
                                                user.level_id === 1 ?
                                                    <Button color="primary" round onClick={(e) => this.deleteAll()}>
                                                        <Close className={classes.icons} />Delete All
                                                    </Button>
                                                :
                                                <></>
                                            }
                                        </>
                                    :
                                    <></>
                                }
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="primary" id="contentViewMore" round onClick={(e) => this.loadPromoCodes(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <DeletePromoCodeModal deleteAll={deleteAll} open={deletePromoCodeModal} onClose={() => this.onModalClose(false)} onSuccess={() => this.onModalSuccess()} />
                {
                    promoCodeDetailsModal ?
                        <PromoCodeModal open={promoCodeDetailsModal} editMode={editMode} promoCodeDetails={promoCodeDetails} onClose={() => this.onPromoCodeDetailsModalClose(false)} onSuccess={() => this.onAppDetailsModalSuccess()} />
                    :
                    <></>
                }
            </GridContainer>
        );
    }
}

PromoCodes.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(promoCodesPageStyle)(PromoCodes);
