import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import CustomSelect from "../components/CustomInput/CustomSelect";
import CustomInput from "../components/CustomInput/CustomInput";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import MailIcon from "@material-ui/icons/Mail";
import JoditEditor from "jodit-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import templatesPageStyle from "../../assets/jss/templatesPageStyle";

class Templates extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.editor = React.createRef();
        this.history = this.props.history;
        this.state = {
            response: null,
            cancelToken: null,
            loading: false,
            loadingMore: false,
            loadingTemplate: false,
            type: "",
            subject: "",
            body: "",
            placeholders: "",
            showError: false,
            errorMessage: "",
            validation: {
                subject: '',
                body: '',
                isValid: false
            },
        };
        this.templates = [
            {
                id: 1,
                label: "Transfer Sent",
            },
            {
                id: 2,
                label: "Transfer Received",
            },
            {
                id: 3,
                label: "Transfer Downloaded",
            },
            {
                id: 4,
                label: "Downloaded Reminder (Sender)",
            },
            {
                id: 5,
                label: "Downloaded Reminder (Receiver)",
            },
            {
                id: 6,
                label: "Someone sent you support request"
            },
            {
                id: 11,
                label: "Verification Code"
            },
            {
                id: 7,
                label: "Welcome Email"
            },
            {
                id: 8,
                label: "Account Update"
            },
            {
                id: 9,
                label: "Running Low On Space Alert"
            },
            {
                id: 10,
                label: "Sorry to see you go!"
            },
            {
                id: 12,
                label: "Files Shared"
            },
            {
                id: 13,
                label: "Notify Owner of Shared Folder"
            },
            {
                id: 17,
                label: "Notify Group of Shared Folder"
            },
            {
                id: 14,
                label: "Your Files Shared Successfully"
            },
            {
                id: 15,
                label: "Sendlinx Employee Invitation"
            },
            {
                id: 16,
                label: "Sendlinx Your Employee Invitation"
            },
            {
                id: 18,
                label: "Shared Folder Access is Active"
            },
            {
                id: 19,
                label: "Shared Folder Access has Expired"
            },
            {
                id: 20,
                label: "Shared Folder Access has been Removed"
            },
            {
                id: 21,
                label: "Shared Folder Signup Invitation"
            },
            {
                id: 23,
                label: "Signup Request Email (Accepted)"
            },
            {
                id: 24,
                label: "Signup Request Email (Rejected)"
            },
            {
                id: 25,
                label: "Sendlinx Referral Invitation"
            },
            {
                id: 26,
                label: "Signup Request Email"
            }
        ];

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    loadTemplate(id){
        const source = axios.CancelToken.source();
        const requestData = {
            id: id,
        };
        Api.getTemplate(requestData, source).then(data => {
            this.setState({
                subject: data.subject,
                body: data.body,
                placeholders: data.placeholders,
                loadingTemplate: false,
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loadingTemplate: true, 
            cancelToken: source,
        });
    }
    handleTemplateChange(event, name) {
        const id = event.target.value;
        this.setState({
            [name]: id
        });
        this.loadTemplate(id);
    }
    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    handleBodyChange(content, name){
        this.setState({
            [name]: content
        });
    }
    validateForm(){
        let validation = {
            subject: 'success',
            body: 'success',
            isValid: true
        };
        if(this.state.subject.length <= 0){
            validation.subject = "error";
            validation.isValid = false;
        }
        if(this.state.body.length <= 0){
            validation.body = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }
        const source = axios.CancelToken.source();
        const { type, subject } = this.state;
        const body = this.editor.current.value;
        const requestData = {
            id: type,
            subject: subject,
            body: body
        }
        Api.saveTemplate(requestData, source).then(data => {
            this.setState({
                loading: false, 
                showError: false, 
                cancelToken: null,
                errorMessage: ""
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    render() {
        const { classes } = this.props;
        const { loading, type, loadingTemplate, subject, placeholders, body, validation } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <MailIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Email Templates</h4>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={this.handleSubmit} noValidate>
                                <GridItem lg={6} md={8} sm={12}>
                                    <CustomSelect 
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        selectProps={{
                                            onChange: (e) => this.handleTemplateChange(e,"type"),
                                            value: type
                                        }}
                                        inputProps={{
                                            name: "type",
                                            id: "input-type",
                                            className: classes.alignLeft
                                        }}
                                        items={this.templates}
                                        itemLabel="label"
                                        itemValue="id"
                                    />
                                </GridItem>
                                {
                                    loadingTemplate === true ?
                                        <LoaderComponent align="left" />
                                    :
                                    <>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <CustomInput 
                                                success={validation.subject === "success"}
                                                error={validation.subject === "error"}
                                                labelText="Subject"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: subject,
                                                    onChange: (e) => this.handleChange(e,"subject"),
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem lg={10} md={12} sm={12}>
                                            {
                                                placeholders.length > 0 ?
                                                    <div className={classes.placeholders}>
                                                        <p>Available Placeholders</p>
                                                        <p>{placeholders}</p>
                                                    </div>
                                                :
                                                <></>
                                            }
                                            <div className={classes.editor}>
                                                <JoditEditor
                                                    ref={this.editor}
                                                    value={body}
                                                    config={{
                                                        readonly: false,
                                                        height: "500px",
                                                        buttons: ['source', '|', 'bold', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', 'brush', 'paragraph', '|', 'left', 'center', 'right', 'justify', '|', 'undo', 'redo', 'hr', 'eraser']
                                                    }}
                                                    onBlur={(content) => this.handleBodyChange(content, "body")}
                                                />
                                            </div>
                                        </GridItem>
                                    </>
                                }
                                <GridItem lg={6} md={6} sm={12} className={classes.saveButton}>
                                    {
                                        loading ?
                                            <LoaderComponent align="left" />
                                        :
                                        <Button type="submit" color="info" disabled={(type.length === 0)}>
                                            Save Changes
                                        </Button>
                                    }
                                </GridItem>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

Templates.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(templatesPageStyle)(Templates);
