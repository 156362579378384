import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import CustomInput from "../components/CustomInput/CustomInput";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import SettingsIcon from "@material-ui/icons/SettingsApplications";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import settingsPageStyle from "../../assets/jss/settingsPageStyle";

class Settings extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.editor = React.createRef();
        this.history = this.props.history;
        this.state = {
            cancelToken: null,
            loading: false,
            showError: false,
            errorMessage: "",
            loadingSetting: false,
            setting: {
                backgroundInterval: "1",
                exportFunction: false,
                tutorialsMenuUrl: "",
                recentUpdatesMenuUrl: "",
            },
            validation: {
                backgroundInterval: ""
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        this.loadSetting();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleChange(event, name) {
        this.setState({
            setting: {
                ...this.state.setting,
                [name]: event.target.value
            }
        });
    }
    loadSetting(){
        const source = axios.CancelToken.source();
        const requestData = {};
        Api.getSetting(requestData, source).then(data => {
            this.setState({
                setting: data,
                loadingSetting: false
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loadingSetting: true, 
            cancelToken: source,
        });
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        Api.saveSetting(this.state.setting, source).then(data => {
            this.setState({
                loading: false, 
                showError: false, 
                cancelToken: null,
                errorMessage: ""
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    validateForm(){
        let validation = {
            backgroundInterval: 'success',
            isValid: true
        };
        const { setting } = this.state;
        if(setting.backgroundInterval.length <= 0){
            validation.backgroundInterval = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    handleCheckbox(e, name){
        this.setState({
            setting: {
                ...this.state.setting,
                [name]: e.target.checked
            }
        });
    }
    render() {
        const { classes } = this.props;
        const { loading, loadingSetting, setting, validation } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <SettingsIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>General Settings</h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loadingSetting ?
                                    <LoaderComponent align="left" />
                                :
                                    <form onSubmit={this.handleSubmit} noValidate>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <CustomInput
                                                success={validation.backgroundInterval === "success"}
                                                error={validation.backgroundInterval === "error"}
                                                labelText="Change Background Images"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,"backgroundInterval"),
                                                    value: setting.backgroundInterval
                                                }}
                                            />
                                            <p>Change background images every X amount of minutes.</p>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <FormControlLabel
                                                classes={{label: classes.label}}
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={(e) => this.handleCheckbox(e, "exportFunction")}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                                        checked={(setting.exportFunction === '1' || setting.exportFunction === true) ? true : false }
                                                    />
                                                }
                                                label={
                                                    <span>Export Function</span>
                                                }
                                            />
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <CustomInput
                                                labelText="Tutorials Menu Url"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,"tutorialsMenuUrl"),
                                                    value: setting.tutorialsMenuUrl
                                                }}
                                            />
                                            <p>Change tutorials menu url from sidebar menu.</p>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <CustomInput
                                                labelText="Recent Updates Menu Url"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,"recentUpdatesMenuUrl"),
                                                    value: setting.recentUpdatesMenuUrl
                                                }}
                                            />
                                            <p>Change recent updates menu url from sidebar menu.</p>
                                        </GridItem>
                                        <GridItem lg={6} md={6} sm={12}>
                                            {
                                                loading ?
                                                    <LoaderComponent align="left" />
                                                :
                                                <Button type="submit" color="info">
                                                    Save Changes
                                                </Button>
                                            }
                                        </GridItem>
                                    </form>
                            }
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

Settings.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(settingsPageStyle)(Settings);
