import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Router } from "react-router-dom";
import {Helmet} from 'react-helmet';
import PropTypes from "prop-types";
import LoaderComponent from './components/Loader';
import RouterComponent from './components/Router';
import AuthApi from '../assets/js/utils/Auth';
import { helper } from '../assets/js/utils/Element';
import NewUpdate from './components/NewUpdate';
import withStyles from "@material-ui/core/styles/withStyles";
import SplashScreen from './components/SplashScreen';

import '../assets/scss/App.scss';
import appStyle from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

const history = createBrowserHistory();
history.listen(function (location) {
    window.scrollTo(0, 0);
});

class App extends Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.loading = this.props.loading;
        this.state = this.store.getState()
    }
    componentDidMount(){
        const that = this;
        this.listenHistory();
        const hasAccessToken = AuthApi.hasAccessToken();
        if(hasAccessToken === false){
            this.loading = false
            that.store.dispatch({type: 'UPDATE_STATE', state: {}}); 
        }
        AuthApi.validateToken().then((isValidToken) => {
            this.loading = false;
            if(hasAccessToken === true && isValidToken === false){
                that.logout();
            }else if(hasAccessToken === true){
                that.store.dispatch({
                    type: "LOGIN",
                    state: {
                        authorized: true,
                        user: isValidToken
                    }
                });
            }
        }).catch(err => {
            this.loading = false;
            that.store.dispatch({type: 'UPDATE_STATE',state: {}});
            console.log(err);
        });

        if(process.env.REACT_APP_ENV !== "production"){
            console.log(process.env);
        }

        const returnUrl = helper.getParam("rte");
        if(returnUrl && returnUrl.length > 0 && returnUrl !== "null"){
            this.store.dispatch({type: 'UPDATE_STATE',state: {url: "/"+decodeURIComponent(returnUrl) }});
            history.push(returnUrl);
        }
    }
    logout(){
        history.push("/auth/logout");
        AuthApi.logout();
        this.store.dispatch({type: 'LOGOUT'});
    }
    listenHistory(){
        const that = this;
        history.listen(function (location) {
            that.store.dispatch({type: 'UPDATE_STATE',state: {url: location.pathname }});           
        });
    }
    render() {
        const { mainLoading, url, authorized } = this.store.getState();
        const { classes } = this.props;
        if(process.env.REACT_APP_DEBUG === true || process.env.REACT_APP_DEBUG === "true"){
            console.log(this.store.getState());
        }
        
        if(mainLoading){
            return <LoaderComponent />
        }

        if(this.loading === true){
            return <SplashScreen />
         }

        const bodyClass = helper.getBodyClass(url);
        return (
            <Router history={history}>
                <Helmet 
                    htmlAttributes={{
                        class: bodyClass
                    }}
                />
                <div className={classes.mainApp+" "+classes.wrapper+" "+bodyClass+" App"+(authorized?' user_authorized':'')}>
                    <RouterComponent store={this.store} history={history}/>
                    <NewUpdate />
                </div>
            </Router>
        );
    }
}

App.defaultProps = {
    loading: true
}
App.propTypes = {
    loading: PropTypes.bool,
}

export default withStyles(appStyle)(App);
