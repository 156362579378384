import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import ChartistGraph from "react-chartist";
import Api from "../../assets/js/utils/Api";
import LoaderComponent from "../components/Loader";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import Assignment from "@material-ui/icons/Assignment";
import ReactTable from "react-table";
import CustomSelect from "../components/CustomInput/CustomSelect";
import CustomInput from "../components/CustomInput/CustomInput";
import Button from "../components/CustomButtons/Button.jsx";
import NavPills from "../components/NavPills/NavPills.jsx";

import { dailySalesChart } from "../../assets/jss/variables/charts";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import homePageStyle from "../../assets/jss/homePageStyle";

class Home extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.state = {
            response: null,
            cancelToken: null,
            loading: false,
            limit: 500,
            report: "user",
            loadingReport: false,
            reportCancelToken: null,
            reportResponse: null,
            countries: [],
            states: [],
            cities: [],
            loadingCountries: false,
            loadingStates: false,
            loadingCities: false,
            reportValues: {
                countryId: "",
                stateId: "",
                cityId: "",
                fileType: ""
            },
            activeTab: 0,
            activeFilterTab: 2
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitFile = this.handleSubmitFile.bind(this);
    }
    componentDidMount(){
        this.loadDashboard();
        this.loadReport();
        this.loadCountries();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
        if (this.state.reportCancelToken) {
            this.state.reportCancelToken.cancel('Request Cancelled')
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(prevState.report !== this.state.report){
            this.loadReport();
        }
    }
    loadDashboard(days = 30, tabId = 0){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        const requestData = {
            days: days
        };
        Api.getDashboardHome(requestData, source).then(data => {
            this.setState({
                response: data.response,
                loading: false,
                cancelToken: null
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: true, 
            showError: false, 
            cancelToken: source,
            page: page,
            activeFilterTab: tabId
        });
    }
    onFilterChange(tabId){
        let days = 30;
        switch(tabId){
            case 0:
                days = 1;
                break;
            case 1:
                days = 7;
                break;
            case 3:
                days = 90;
                break;
            case 4:
                days = 180;
                break;
            case 5:
                days = 365;
                break;
            default:
                break;
        }
        this.loadDashboard(days, tabId);
    }
    loadReport(){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        const { report, reportValues } = this.state;
        Api.getDashboardReport(report, reportValues, source).then(data => {
            this.setState({
                reportResponse: data.response,
                loadingReport: false,
                reportCancelToken: null
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loadingReport: true, 
            reportCancelToken: source,
            page: page,
            reportResponse: null
        });
    }
    deleteSelected(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        this.setState({deleteFileModal: true});
    }
    prepareData(){
        const { classes } = this.props;
        const { response } = this.state;
        let mpdSeries = [];
        let dtrSeries = [];
        let spdSeries = [];
        let astdSeries = [];
        let mpdLabels = [];
        let spdLabels = [];
        let tableData = [];
        if(response !== null){
            response.map((stat, key) => {
                mpdSeries.push(stat.mail_percentage);
                dtrSeries.push(stat.transfer_percentage);
                spdSeries.push(stat.daily_storage_percentage);
                astdSeries.push(stat.storage_percentage);
                mpdLabels.push(++key);
                spdLabels.push(key);

                const statArray = Object.assign({}, stat);
                statArray['id'] = <>
                                    &nbsp;
                                    <span className={classes.statid}>{key}</span>
                                </>;
                statArray['mail_count'] = this.prepareColumnData(stat.mail_count, "mail");
                statArray['mail_percentage'] = this.preparePercentage(stat.mail_percentage, "percentage");
                statArray['transfer_rate'] = this.prepareColumnData(stat.transfer_rate, "transfer");
                statArray['transfer_percentage'] = this.preparePercentage(stat.transfer_percentage, "percentage");
                statArray['daily_storage_rate'] = this.prepareColumnData(stat.daily_storage_rate, "dailystorage");
                statArray['daily_storage_percentage'] = this.preparePercentage(stat.daily_storage_percentage, "percentage");
                statArray['storage_rate'] = this.prepareColumnData(stat.storage_rate, "storage");
                statArray['storage_percentage'] = this.preparePercentage(stat.storage_percentage, "percentage");
                tableData.push(statArray);
                return null;
            });
        }
        const chartData = {
            mpdChart: {
                labels: mpdLabels,
                series: [mpdSeries, dtrSeries]
            },
            spdChart: {
                labels: spdLabels,
                series: [spdSeries, astdSeries]
            },
            tableData: tableData
        };
        return chartData;
    }
    prepareColumnData(percentage, cls = null){
        const { classes } = this.props;
        return <>
            &nbsp;
            <span className={classes[cls]}>{percentage}</span>
        </>;
    }
    preparePercentage(percentage, cls = null){
        const { classes } = this.props;
        if(!percentage || percentage < 90){
            return <>
                &nbsp;
                <span className={classes[cls]}>{percentage}</span>
            </>;
        }
        return <>
            &nbsp;
            <span className={classes.dangerColor}>{percentage}</span>
        </>;
    }
    prepareReportData(){
        const { reportResponse } = this.state;
        let tableData = [];
        if(reportResponse !== null){
            reportResponse.map((stat, key) => {
                const statArray = Object.assign({}, stat);
                statArray['id'] = ++key;
                tableData.push(statArray);
                return null;
            });
        };
        return tableData;
    }
    loadCountries(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingCountries: true,
            countries: []
        });
        Api.getCountries(source).then(data => {
            const countries = [{id: "", "name": "Select Country"}].concat(data.response);
            that.setState({
                countries: countries,
                loadingCountries: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onCountryChange(e){
        const country = e.target.value;
        if(country.length <= 0){
            this.setState({
                reportValues: {
                    ...this.state.reportValues,
                    countryId: country,
                    stateId: "",
                    cityId: "",
                },
                cities: [],
                states: [],
            });
            return;
        }
        this.loadStates(country);
    }
    loadStates(country){
        const { countryId } = this.state.reportValues;
        if(countryId === country){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingStates: true,
            reportValues: {
                ...this.state.reportValues,
                countryId: country,
                stateId: "",
                cityId: ""
            },
            states: [],
            cities: [],
        });
        Api.getStates(country, source).then(data => {
            let states = data.response;
            if(states.length > 0){
                states = [{id: "", "name": "Select State"}].concat(data.response);
            }
            that.setState({
                states: states,
                loadingStates: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onStateChange(e){
        const state = e.target.value;
        if(state.length <= 0){
            this.setState({
                reportValues: {
                    ...this.state.reportValues,
                    stateId: state,
                    cityId: ""
                },
                states: [],
                cities: [],
            });
            return;
        }
        this.loadCities(state);
    }
    loadCities(state){
        const { stateId } = this.state.reportValues;
        if(stateId === state){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        const { countryId } = this.state.reportValues;
        that.setState({
            cancelToken: source,
            loadingCities: true,
            reportValues: {
                ...this.state.reportValues,
                stateId: state,
                cityId: ""
            },
            cities: [],
        });
        Api.getCities(countryId, state, source).then(data => {
            let cities = data.response;
            if(cities.length > 0){
                cities = [{id: "", "name": "Select City"}].concat(data.response);
            }
            that.setState({
                cities: cities,
                loadingCities: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    handleChange(event, name) {
        this.setState({
            reportValues: {
                ...this.state.reportValues,
                [name]: event.target.value
            }
        });
    }
    handleSubmit(e){
        e.preventDefault();
        this.loadReport();
    }
    handleSubmitFile(e){
        e.preventDefault();
        this.loadReport();
    }
    onUserReportChange(tabId){
        let report = "user";
        switch(tabId){
            case 1:
                report = "storage";
                break;
            case 2:
                report = "email";
                break;
            case 3:
                report = "file";
                break;
            case 4:
                report = "customfile";
                break;
            default:
                break;
        }
        this.setState({report: report, activeTab: tabId});
    }
    getReportColumns(){
        const { report } = this.state;
        let columns = [
            {
                Header: "ID",
                accessor: "id"
            },
            {
                Header: "Country",
                accessor: "country",
            },
            {
                Header: "State",
                accessor: "state",
            },
            {
                Header: "City",
                accessor: "city",
            },
        ];
        if(report === "file" || report === "customfile"){
            columns.push(
                {
                    Header: "File Type",
                    accessor: "mime_type",
                },
            );
            columns.push(
                {
                    Header: "Extension",
                    accessor: "extension",
                },
            );
        }
        columns.push(
            {
                Header: (report === "storage" ? "Storage" : "Count"),
                accessor: "count",
            },
        );
        return columns;
    }
    getChartOptions(){
        const { activeFilterTab } = this.state;
        if(activeFilterTab > 2){
            let showInterval = 5;
            if(activeFilterTab === 4){
                showInterval = 10;
            }else if(activeFilterTab === 5){
                showInterval = 30;
            }
            dailySalesChart.options.axisX.labelInterpolationFnc = function(value, index){
                return index % showInterval === 0 ? value: null;
            };
            return dailySalesChart;
        }
        dailySalesChart.options.axisX.labelInterpolationFnc = function(value, index){
            return value;
        };
        return dailySalesChart;
    }
    render() {
        const { classes } = this.props;
        const { loading, limit, reportValues, loadingReport, activeTab, activeFilterTab, report } = this.state;
        const { countries, states, cities, loadingCountries, loadingStates, loadingCities } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        const data = this.prepareData();
        const chartOptions = this.getChartOptions();
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <Assignment />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>Filters</h4>
                            </CardHeader>
                            <CardBody>
                                <NavPills
                                    tabs={[
                                        {
                                            tabButton: "Past 24 Hours"
                                        },
                                        {
                                            tabButton: "Past 7 Days"
                                        },
                                        {
                                            tabButton: "Past 30 Days"
                                        },
                                        {
                                            tabButton: "Past 3 Months"
                                        },
                                        {
                                            tabButton: "Past 6 Months"
                                        },
                                        {
                                            tabButton: "Past 1 Year"
                                        }
                                    ]}
                                    color="rose"
                                    onChange={(tabId) => this.onFilterChange(tabId)}
                                    active={activeFilterTab}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <Card chart>
                            {
                                loading ?
                                    <LoaderComponent align="center" className={classes.loader} />
                                :
                                    <>
                                        <CardHeader color="warning" className={classes.cardHeaderHover}>
                                            <ChartistGraph
                                                className="ct-chart-white-colors"
                                                data={data.mpdChart}
                                                type="Line"
                                                options={chartOptions.options}
                                                listener={chartOptions.animation}
                                            />
                                        </CardHeader>
                                        <CardBody>
                                            <h4 className={classes.cardTitle}>Messages Per day vs Data Transfer Rate per day</h4>
                                        </CardBody>
                                    </>
                            }
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Card chart>
                            {
                                loading ?
                                    <LoaderComponent align="center" className={classes.loader} />
                                :
                                    <>
                                        <CardHeader color="warning" className={classes.cardHeaderHover}>
                                            <ChartistGraph
                                                className="ct-chart-white-colors"
                                                data={data.spdChart}
                                                type="Line"
                                                options={chartOptions.options}
                                                listener={chartOptions.animation}
                                            />
                                        </CardHeader>
                                        <CardBody>
                                            <h4 className={classes.cardTitle}>Storage Per Day vs All Storage To Date</h4>
                                        </CardBody>
                                    </>
                            }
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <Assignment />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>Dashboard Home</h4>
                            </CardHeader>
                            <CardBody>
                                {
                                    loading ?
                                        <LoaderComponent align="center" />
                                    :
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "Day",
                                                accessor: "id"
                                            },
                                            {
                                                Header: "Messages Per Day",
                                                accessor: "mail_count",
                                                headerClassName: "hd_mail",
                                            },
                                            {
                                                Header: "Percentage",
                                                accessor: "mail_percentage",
                                                headerClassName: "hd_mail",
                                            },
                                            {
                                                Header: "Transfer Rate Per Day",
                                                accessor: "transfer_rate",
                                                headerClassName: "hd_transfer",
                                            },
                                            {
                                                Header: "Percentage",
                                                accessor: "transfer_percentage",
                                                headerClassName: "hd_transfer",
                                            },
                                            {
                                                Header: "Storage Per Day",
                                                accessor: "daily_storage_rate",
                                                headerClassName: "hd_daily_storage",
                                            },
                                            {
                                                Header: "Percentage",
                                                accessor: "daily_storage_percentage",
                                                headerClassName: "hd_daily_storage",
                                            },
                                            {
                                                Header: "Storage To Date",
                                                accessor: "storage_rate",
                                                headerClassName: "hd_storage",
                                            },
                                            {
                                                Header: "Percentage",
                                                accessor: "storage_percentage",
                                                headerClassName: "hd_storage",
                                            },
                                        ]}
                                        data={data.tableData}
                                        pageSizeOptions={[500]}
                                        defaultPageSize={limit}
                                        showPaginationTop={false}
                                        minRows={0}
                                        showPaginationBottom={false}
                                        className={"-striped -highlight "+classes.statsTable}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <Assignment />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>Overall Reports</h4>
                            </CardHeader>
                            <CardBody>
                            <div>
                                <form onSubmit={this.handleSubmit} className={classes.form} noValidate autoComplete="off">
                                    {
                                        countries.length > 0 ?
                                            <CustomSelect 
                                                labelText="Country"
                                                selectProps={{
                                                    onChange: (e) => this.onCountryChange(e),
                                                    value: reportValues.countryId
                                                }}
                                                inputProps={{
                                                    name: "countryId",
                                                    id: "input-countryId",
                                                }}
                                                items={countries}
                                                itemLabel="name"
                                                itemValue="id"
                                            />
                                        :
                                            loadingCountries ?
                                                <LoaderComponent align="left" />
                                            :
                                            <></>
                                    }
                                    {
                                        states.length > 0 ?
                                            <CustomSelect 
                                                labelText="State"
                                                selectProps={{
                                                    onChange: (e) => this.onStateChange(e),
                                                    value: reportValues.stateId
                                                }}
                                                inputProps={{
                                                    name: "stateId",
                                                    id: "input-stateId",
                                                }}
                                                items={states}
                                                itemLabel="name"
                                                itemValue="id"
                                            />
                                        :
                                            loadingStates ?
                                                <LoaderComponent align="left" />
                                            :
                                            <></>
                                    }
                                    {
                                        cities.length > 0 ?
                                            <CustomSelect 
                                                labelText="City"
                                                selectProps={{
                                                    onChange: (e) => this.handleChange(e, "cityId"),
                                                    value: reportValues.cityId
                                                }}
                                                inputProps={{
                                                    name: "cityId",
                                                    id: "input-cityId",
                                                }}
                                                items={cities}
                                                itemLabel="name"
                                                itemValue="id"
                                            />
                                        :
                                            loadingCities ?
                                                <LoaderComponent align="left" />
                                            :
                                            <></>
                                    }
                                    <Button round color="primary" type="submit">Search</Button>
                                </form>
                                <NavPills
                                    tabs={[
                                        {
                                            tabButton: "User"
                                        },
                                        {
                                            tabButton: "Storage"
                                        },
                                        {
                                            tabButton: "Email"
                                        },
                                        {
                                            tabButton: "File Type"
                                        },
                                        {
                                            tabButton: "Custom File Type"
                                        }
                                    ]}
                                    color="rose"
                                    onChange={(tabId) => this.onUserReportChange(tabId)}
                                    active={activeTab}
                                />
                            </div>
                                {
                                    loadingReport ?
                                        <LoaderComponent align="left" />
                                    :
                                    <>
                                        {
                                            report === "customfile" ?
                                                <form onSubmit={this.handleSubmitFile} className={classes.form} noValidate autoComplete="off">
                                                    <CustomInput
                                                        labelText="Filter File Type"
                                                        id="customInput-fileType"
                                                        inputProps={{
                                                            onChange: (e) => this.handleChange(e, "fileType"),
                                                            value: reportValues.fileType
                                                        }}
                                                    />
                                                    <Button round color="primary" type="submit">Search</Button>
                                                </form>
                                            :
                                            <></>
                                        }
                                        <ReactTable
                                            columns={this.getReportColumns()}
                                            data={this.prepareReportData()}
                                            pageSizeOptions={[500]}
                                            defaultPageSize={limit}
                                            showPaginationTop={false}
                                            minRows={0}
                                            showPaginationBottom={false}
                                            className="-striped -highlight"
                                        />
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(homePageStyle)(Home);