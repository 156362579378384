/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
    primaryColor,
    dangerColor,
    successColor,
    whiteColor,
    grayColor,
    defaultFont,
    roseColor,
    infoColor,
  } from "../../material-kit-pro-react.jsx";
  
  const customInputStyle = theme => ({
      disabled: {
          "&:before": {
              backgroundColor: "transparent !important"
          }
      },
      underline: {
          "&:hover:not($disabled):before,&:before": {
              borderBottomColor: grayColor[11] + " !important",
              borderBottomWidth: "1px !important"
          },
          "&:after": {
              borderBottomColor: primaryColor[0]
          }
      },
      underlineError: {
          "&:after": {
              borderBottomColor: dangerColor[0]
          }
      },
      underlineSuccess: {
          "&:after": {
              borderBottomColor: successColor[0]
          }
      },
      labelRoot: {
          ...defaultFont,
          color: grayColor[12] + " !important",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "1.42857",
          top: "10px",
          letterSpacing: "unset",
          cursor: "text",
          "& + $underline": {
              marginTop: "0px"
          },
          "& .info_tooltip_icon": {
              marginLeft: "5px"
          },
          "& .private_icon": {
              cursor: "auto",
          },
          "&.MuiInputLabel-shrink": {
              "& .info_tooltip_icon": {
                  width: "22.7px",
                  height: "22.7px",
              }
          },
          width: "max-content"
      },
      labelRootError: {
          color: dangerColor[0] + " !important"
      },
      labelRootSuccess: {
          color: successColor[0] + " !important"
      },
      feedback: {
          position: "absolute",
          bottom: "4px",
          right: "0px",
          zIndex: "2",
          display: "block",
          width: "24px",
          height: "24px",
          textAlign: "center",
          pointerEvents: "none"
      },
      formControl: {
          margin: "0 0 17px 0",
          paddingTop: "27px",
          position: "relative",
          "& .MuiInputBase-root.Mui-disabled": {
              backgroundColor: grayColor[19]
          },
          "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
          color: grayColor[13]
          }
      },
      whiteUnderline: {
          "&:hover:not($disabled):before,&:before": {
          borderBottomColor: whiteColor
          },
          "&:after": {
          borderBottomColor: whiteColor
          }
      },
      input: {
          color: grayColor[13],
          height: "unset",
          "&,&::placeholder": {
              fontSize: "14px",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "400",
              lineHeight: "1.42857",
              opacity: "1"
          },
          "&::placeholder": {
              color: grayColor[12]
          },
          MozAppearance: "textfield",
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
              appearance: "none"
          }
      },
      whiteInput: {
          "&,&::placeholder": {
          color: whiteColor,
          opacity: "1"
          }
      },
      animate: {
          animation: "customSpin 1.3s infinite linear"
      },
      cancelIcon: {
          cursor: "pointer",
          pointerEvents: "all",
          right: "0px"
      },
      hasClearIcon: {
          right: "25px"
      },
      hasEndAdornment: {
          right: "50px"
      },
      hasClearEndAdornment: {
          right: "25px"
      },
      primary: {
          "&:hover:not($disabled):before,&:before": {
              borderBottomColor: grayColor[11] + " !important",
              borderBottomWidth: "1px !important"
          },
          "&:after": {
              borderBottomColor: primaryColor[0]
          },
          "&.Mui-focused": {
              color: primaryColor[0]
          }
      },
      rose: {
          "&:hover:not($disabled):before,&:before": {
              borderBottomColor: grayColor[11] + " !important",
              borderBottomWidth: "1px !important"
          },
          "&:after": {
              borderBottomColor: roseColor[0]
          },
          "&.Mui-focused": {
              color: roseColor[0]
          }
      },
      success: {
          "&:hover:not($disabled):before,&:before": {
              borderBottomColor: grayColor[11] + " !important",
              borderBottomWidth: "1px !important"
          },
          "&:after": {
              borderBottomColor: successColor[0]
          },
          "&.Mui-focused": {
              color: successColor[0]
          }
      },
      info: {
          "&:hover:not($disabled):before,&:before": {
              borderBottomColor: grayColor[11] + " !important",
              borderBottomWidth: "1px !important"
          },
          "&:after": {
              borderBottomColor: infoColor[0]
          },
          "&.Mui-focused": {
              color: infoColor[0]
          }
      },
      gray: {
          "&:hover:not($disabled):before,&:before": {
              borderBottomColor: grayColor[11] + " !important",
              borderBottomWidth: "1px !important"
          },
          "&:after": {
              borderBottomColor: grayColor[26]
          },
          "&.Mui-focused": {
              color: grayColor[26]
          }
      },
      customRose: {
          "&:hover:not($disabled):before,&:before": {
              borderBottomColor: grayColor[11] + " !important",
              borderBottomWidth: "1px !important"
          },
          "&:after": {
              borderBottomColor: roseColor[5]
          },
          "&.Mui-focused": {
              color: roseColor[5]
          }
      },
      customSuccess: {
          "&:hover:not($disabled):before,&:before": {
              borderBottomColor: grayColor[11] + " !important",
              borderBottomWidth: "1px !important"
          },
          "&:after": {
              borderBottomColor: successColor[8]
          },
          "&.Mui-focused": {
              color: successColor[8]
          }
      },
      customInfo: {
          "&:hover:not($disabled):before,&:before": {
              borderBottomColor: grayColor[11] + " !important",
              borderBottomWidth: "1px !important"
          },
          "&:after": {
              borderBottomColor: infoColor[8]
          },
          "&.Mui-focused": {
              color: infoColor[8]
          }
      },
  });
  
  export default customInputStyle;
  