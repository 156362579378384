import {
    whiteColor,
    cardTitle
} from "./material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "./material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const filesPageStyle = {
    ...customCheckboxRadioSwitchStyle,
    main: {
        background: whiteColor
    },
    loadMore: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    reactTable: {
        "& .rt-thead .rt-th": {
            fontSize: "14px",
            "&.hd_hide": {
                display: 'none'
            }
        },
        "& .rt-tbody .rt-td": {
            fontSize: "12px",
            padding: "0px 5px",
            "&.td_hide": {
                display: "none"
            }
        },
        "& .hd_check": {
            "& .MuiIconButton-root": {
                padding: "0px"
            }
        },
    }
};

export default filesPageStyle;
