import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import LayersIcon from "@material-ui/icons/LayersOutlined";
import Popover from '@material-ui/core/Popover';
import { SketchPicker } from 'react-color'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import themeSettingsPageStyle from "../../assets/jss/themeSettingsPageStyle";

class ThemeSetting extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.editor = React.createRef();
        this.history = this.props.history;
        this.state = {
            cancelToken: null,
            loading: false,
            showError: false,
            errorMessage: "",
            loadingSetting: false,
            popupOpen: false,
            anchorEl: null,
            colorType: null,
            values: {}
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        this.loadThemeSetting();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    loadThemeSetting(){
        const source = axios.CancelToken.source();
        const requestData = {};
        Api.getThemeSetting(requestData, source).then(data => {
            this.setState({
                values: data,
                loadingSetting: false
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loadingSetting: true, 
            cancelToken: source,
        });
    }
    handleSubmit(e){
        e.preventDefault();
        
        const source = axios.CancelToken.source();
        Api.saveThemeSetting(this.state.values, source).then(data => {
            this.setState({
                loading: false, 
                showError: false, 
                cancelToken: null,
                errorMessage: ""
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    handleClick(event, name){
        this.setState({
            popupOpen: true,
            anchorEl: event.currentTarget,
            colorType: name
        });

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 200);
    };
    handleClose = () => {
        this.setState({
            popupOpen: false,
            anchorEl: null
        })
    };
    handleChangeComplete = (color) => {
        this.setState({
            values: {
                ...this.state.values,
                [this.state.colorType]: color.hex 
            }
        });
    } 
    render() {
        const { classes } = this.props;
        const { loading, loadingSetting, popupOpen, anchorEl } = this.state;
        const { backgroundColor, textColor, pageTitleColor, borderColor, loaderColor, iconsColor, sidebarMenuLinkColor, sidebarSubmenuLinkColor, sidebarMenuLinkShadow, sidebarMenuIconsColor, horizontalMenuTextColor, horizontalMenuIconsColor, tableHeadTextColor, tableRowActionsIconsColor, checkboxColor, breadcrumbsColor, linksColor, headingsColor, buttonsColor } = this.state.values;
        const { authorized } = this.store.getState();
        const id = popupOpen ? 'simple-popover' : undefined;
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <LayersIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Light Theme Setting</h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loadingSetting ?
                                    <LoaderComponent align="left" />
                                :
                                    <form onSubmit={this.handleSubmit} noValidate>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Background Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'backgroundColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': backgroundColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Text Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'textColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': textColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Page Title Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'pageTitleColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': pageTitleColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Border Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'borderColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': borderColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Loader Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'loaderColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': loaderColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Icons Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'iconsColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': iconsColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Sidebar Menu Link Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'sidebarMenuLinkColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': sidebarMenuLinkColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Sidebar Submenu Link Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'sidebarSubmenuLinkColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': sidebarSubmenuLinkColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Sidebar Menu Shadow Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'sidebarMenuLinkShadow')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': sidebarMenuLinkShadow}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Sidebar Menu Icons Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'sidebarMenuIconsColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': sidebarMenuIconsColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Horizontal Menu Text Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'horizontalMenuTextColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': horizontalMenuTextColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Horizontal Menu Icons Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'horizontalMenuIconsColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': horizontalMenuIconsColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Table Head Text Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'tableHeadTextColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': tableHeadTextColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Table Row Actions Icons Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'tableRowActionsIconsColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': tableRowActionsIconsColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Checkbox Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'checkboxColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': checkboxColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Breadcrumbs Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'breadcrumbsColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': breadcrumbsColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Links Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'linksColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': linksColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Headings Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'headingsColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': headingsColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12} className={classes.colorItem}>
                                            <p>Buttons Color</p>
                                            <div 
                                                className='colorBox' 
                                                aria-label="open popup" 
                                                onClick={(e) => this.handleClick(e, 'buttonsColor')} 
                                                aria-describedby={id} 
                                                style={{'backgroundColor': buttonsColor}}
                                            >
                                            </div>
                                        </GridItem>
                                        
                                        <GridItem lg={6} md={6} sm={12}>
                                            {
                                                loading ?
                                                    <LoaderComponent align="left" />
                                                :
                                                <Button type="submit" color="info">
                                                    Save Changes
                                                </Button>
                                            }
                                        </GridItem>
                                    </form>
                            }
                            <Popover
                                id={id}
                                open={popupOpen}
                                anchorEl={anchorEl}
                                onClose={this.handleClose}
                                keepMounted
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 25,
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                classes={{
                                    root: classes.popoverRoot
                                }}
                            >
                                <SketchPicker 
                                    color={ this.state.values[this.state.colorType] }
                                    onChangeComplete={ this.handleChangeComplete }
                                    disableAlpha={ true }
                                />
                            </Popover>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

ThemeSetting.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(themeSettingsPageStyle)(ThemeSetting);
