import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import Close from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteFileModal from "../components/Home/DeleteFileModal";
import ReactTable from "react-table";
import SignupControlIcon from '@material-ui/icons/PersonAddDisabled';
import EditIcon from "@material-ui/icons/Edit";
import SignupControlsModal from "../components/Home/SignupControlsModal";
import { helper } from '../../assets/js/utils/Element';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import signupControlsPageStyle from "../../assets/jss/signupControlsPageStyle";

class SignupControls extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        this.defaultSignupControlDetails = {
            accountType: "",
            signupsAllowed: "",
            fromDateTime: "",
            toDateTime: "",
            formPath: "",
            quota: "",
        };

        this.state = {
            allAccountTypes: this.getPlans(true),
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            deleteFileModal: false,
            limit: 10000000,
            deleteAll: false,
            signupControlsModal: false,
            signupControlDetails: this.defaultSignupControlDetails,
            editMode: false,
        };        

        this.appCategories = Api.getAppCategories();
        this.handleToggle = this.handleToggle.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onModalSuccess = this.onModalSuccess.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
    }
    componentDidMount(){
        this.loadSignupControls();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    getPlans(all = false){
        let plans = Api.getPlans();
        
        let accountTypes = [];
        plans.map(plan => {
            if(plan.id === '-1'){
                return null;
            }
            if(all === true){
                accountTypes.push(plan);
            }else{
                if(!this.state.typesArray.includes(plan.id)){
                    accountTypes.push(plan);
                }
            }
            return null;
        });

        if(all === true){
            return accountTypes;
        }
        let stateObj = {};
        stateObj['accountTypes'] = accountTypes;

        this.setState(stateObj)
    }
    prepareTypesArray(){
        let types = [];
        this.state.response.map(data => {
            types.push(data.account_type);

            return null
        });

        this.setState({
            typesArray: types
        }, () => {
            this.getPlans();
        });
    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
      }
    loadSignupControls(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 200,
        };
        Api.getSignupControls(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });

            this.prepareTypesArray();
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            signupControlsModal: false,
            editMode: false,
            signupControlDetails: this.defaultSignupControlDetails
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(file => {
            let fileArray = {
                id: file.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(file.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(file.id)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                accountType: this.getAccountType(file),
                signupsAllowed: file.signups_allowed,
                fromDateTime: file.from_datetime,
                toDateTime: file.to_datetime,
                formPath: file.form_path,
                quota: file.quota ? helper.getFormatedSize(file.quota) : '',
                actions: (
                    <>
                        <Button justIcon size="sm" color="info" onClick={() => this.onEditModal(true, file)}>
                            <EditIcon />
                        </Button>
                        <Button justIcon size="sm" color="danger" onClick={() => this.deleteFile(file)}>
                            <Close />
                        </Button>
                    </>
                )
            };
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    deleteSelected(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        this.setState({deleteFileModal: true});
    }
    deleteAll(){
        this.setState({deleteFileModal: true, deleteAll: true});
    }
    deleteFile(file){
        let checked = [];
        checked.push(file.id);
        this.setState({deleteFileModal: true, checked: checked});
    }
    onModalClose(deleteFileModal = true){
        let checked = this.state.checked;
        if(deleteFileModal === false){
            checked = [];
        }
        this.setState({
            deleteFileModal: deleteFileModal,
            checked: checked,
            deleteAll: false
        });
    }
    onModalSuccess(){
        const { checked, response, deleteAll } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            signupControls: checked,
            clean: (deleteAll ? 1: 0)
        };
        Api.deleteSignupControls(requestData, source).then(data => {
            //Handle Success;
        }).catch(err => {
            //Handle Error
        });
        if(deleteAll){
            this.setState({
                response: [],
                checked: [],
                page: 1,
                totalPages: 1,
                deleteFileModal: false,
                deleteAll: false
            });
            return;
        }

        let newResponse = [];
        response.map(file => {
            if(!checked.includes(file.id)){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteFileModal: false,
            deleteAll: false
        }, () => {
            this.prepareTypesArray();
        });
    }
    onSignupControlsModalClose(status = true){
        if(status === false){
            this.loadSignupControls();
            return;
        }
        this.setState({
            signupControlsModal: status,
        });
    }
    onSignupControlsModalSuccess(){
        this.loadSignupControls();
    }
    getAccountType(file){
        let type = "";
        this.state.allAccountTypes.map((item) => {
            if(item.id === file.account_type){
                type = item.label;
            }
            return null;
        });

        return type;
    }
    onEditModal(status = true, file){
        if(!status){
            this.loadSignupControls(false);
            return;
        }
        this.setState({
            signupControlsModal: status,
            signupControlDetails: {
                id: file.id,
                accountType: file.account_type,
                signupsAllowed: file.signups_allowed,
                fromDateTime: file.from_datetime ? new Date(file.from_datetime).toISOString() : "",
                toDateTime: file.from_datetime ? new Date(file.to_datetime).toISOString() : "",
                formPath: file.form_path,
                quota: file.quota ? helper.getFormatedSize(file.quota, false) : '',
            },
            editMode: status
        });
    }
    render() {
        const { classes } = this.props;
        const { loading, page, loadingMore, totalPages, deleteFileModal, checked, limit, deleteAll, signupControlsModal, signupControlDetails, editMode, accountTypes, allAccountTypes } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }

        const tableData = this.getTableData();
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <SignupControlIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Sign Up Controls
                                {
                                    loading ?
                                        <></>
                                    :
                                        accountTypes && accountTypes.length > 0 ?
                                            <Button round color="primary" type="button" className={classes.addButton} onClick={() => this.onSignupControlsModalClose(true)}>Add Signup Control</Button>
                                        :
                                            <></>
                                }
                            </h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loading ?
                                    <LoaderComponent align="center" />
                                :
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                headerClassName: "hd_check",
                                                className: "hd_check td_check",
                                                width: 50
                                            },
                                            {
                                                Header: "",
                                                accessor: "check",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_check",
                                                className: "hd_check td_check",
                                                width: 50
                                            },
                                            {
                                                Header: "Type",
                                                accessor: "accountType",
                                                headerClassName: "hd_type",
                                                className: "hd_type td_type",
                                            },
                                            {
                                                Header: "Signups Allowed",
                                                accessor: "signupsAllowed",
                                                headerClassName: "hd_signups_allowed",
                                                className: "hd_signups_allowed td_signups_allowed",
                                            },
                                            {
                                                Header: "Storage",
                                                accessor: "quota",
                                                headerClassName: "hd_quota",
                                                className: "hd_quota td_quota",
                                            },
                                            {
                                                Header: "From Date/Time",
                                                accessor: "fromDateTime",
                                                headerClassName: "hd_from_datetime",
                                                className: "hd_from_datetime td_from_datetime",
                                                sortMethod: (a, b) => {
                                                    var a1 = new Date(a).getTime();
                                                    var b1 = new Date(b).getTime();
                                                  if(a1<b1)
                                                    return 1;
                                                  else if(a1>b1)
                                                    return -1;
                                                  else
                                                    return 0;
                                                }
                                            },
                                            {
                                                Header: "To Date/Time",
                                                accessor: "toDateTime",
                                                headerClassName: "hd_to_datetime",
                                                className: "hd_to_datetime hd_to_datetime",
                                                sortMethod: (a, b) => {
                                                    var a1 = new Date(a).getTime();
                                                    var b1 = new Date(b).getTime();
                                                  if(a1<b1)
                                                    return 1;
                                                  else if(a1>b1)
                                                    return -1;
                                                  else
                                                    return 0;
                                                }
                                            },
                                            {
                                                Header: "Form Path",
                                                accessor: "formPath",
                                                headerClassName: "hd_form_path",
                                                className: "hd_form_path td_form_path",
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_actions",
                                                className: "hd_actions td_actions",
                                            }
                                        ]}
                                        data={tableData}
                                        pageSizeOptions={[limit]}
                                        defaultPageSize={limit}
                                        minRows={0}
                                        showPaginationTop={true}
                                        showPaginationBottom={false}
                                        showPagination={false}
                                        className={"-striped -highlight "+classes.signupControlsTable}
                                    />
                            }
                            <div className={classes.loadMore}>
                                {
                                    loadingMore === false && tableData.length > 0 ?
                                        <>
                                            <Button color="primary" disabled={(checked.length === 0)} round onClick={(e) => this.deleteSelected()}>
                                                <Close className={classes.icons} />Delete Selected
                                            </Button>
                                            {
                                                user.level_id === 1 ?
                                                    <Button color="primary" round onClick={(e) => this.deleteAll()}>
                                                        <Close className={classes.icons} />Delete All
                                                    </Button>
                                                :
                                                <></>
                                            }
                                        </>
                                    :
                                    <></>
                                }
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="primary" id="contentViewMore" round onClick={(e) => this.loadSignupControls(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <DeleteFileModal deleteAll={deleteAll} open={deleteFileModal} onClose={() => this.onModalClose(false)} onSuccess={() => this.onModalSuccess()} />
                {
                    signupControlsModal ?
                        <SignupControlsModal open={signupControlsModal} editMode={editMode} allAccountTypes={allAccountTypes} accountTypes={accountTypes} signupControlDetails={signupControlDetails} onClose={() => this.onSignupControlsModalClose(false)} onSuccess={() => this.onSignupControlsModalSuccess()} />
                    :
                    <></>
                }
            </GridContainer>
        );
    }
}

SignupControls.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(signupControlsPageStyle)(SignupControls);
