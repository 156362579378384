/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "../CustomButtons/Button.jsx";
import addFileIcon from "../../../assets/img/add-file-icon.png";
import addFolderIcon from "../../../assets/img/add-folder-icon.png";

import styles from "../../../assets/jss/material-kit-pro-react/components/customFileUploadStyle.jsx";

class CustomFileUpload extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            files: this.props.files,
            showPopup: false
        };
        this.hiddenFile = React.createRef();
        this.hiddenFileDirectory = React.createRef();

        this.addFile = this.addFile.bind(this);
        this.addFolder = this.addFolder.bind(this);
        this.popupToggle = this.popupToggle.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            files: props.files
        });
    }
    popupToggle(){
        this.setState({
            showPopup: !this.state.showPopup
        });
    }
    onFocus = e => {
        this.hiddenFile.current.click(e);
    };
    onDirectoryFocus = e => {
        this.hiddenFileDirectory.current.click(e);
    };
    handleSubmit = e => {
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    };
    addFile = e => {
        let files = e.target.files;
        let filesArray = this.state.files;
        for (let i = 0; i < files.length; i++) {
        filesArray.push({
            type: "file",
            file: files[i]
        });
        }
        this.props.onChange(filesArray);
        this.setState({
            files: filesArray
        });
    };
    addFolder = e => {
        let files = e.target.files;
        if(files.length <= 0){
            return;
        }
        let newFilesArray = [];
        for (let i = 0; i < files.length; i++) {
            newFilesArray.push(files[i]);
        }
        let filesArray = this.state.files;
        var folderPath = files[0].webkitRelativePath;
        var folder = folderPath.split("/")[0];
        filesArray.push({
            type: "folder",
            name: folder,
            files: newFilesArray
        });
        this.props.onChange(filesArray);
        this.setState({
            files: filesArray
        });
    }
    filesList(files){
        let filesLists = [];
        for (var i = 0; i < files.length; i++) {
            let file = files[i];
            filesLists.push(file);
        }
        return filesLists;
    }
    render() {
        const { classes, multiple, allowFolder, accept } = this.props;

        return (
            <div className={classes.inputFileWrapper+" "+(false ? classes.mobile : "")}>
                <input
                    type="file"
                    className={classes.inputFile}
                    multiple={multiple}
                    ref={this.hiddenFile}
                    onChange={this.addFile}
                    accept={accept}
                />
                {
                    allowFolder ?
                        <input
                            type="file"
                            className={classes.inputFile}
                            multiple={multiple}
                            ref={this.hiddenFileDirectory}
                            onChange={this.addFolder}
                            webkitdirectory="webkitdirectory"
                            directory="directory"
                            accept={accept}
                        />
                    :
                    <></>
                }
                <div className={classes.addFilesButton+" add-files-button"}>
                    <ul>
                        <li>
                            <Button onClick={this.onFocus} className="add-file-title">
                                <img src={addFileIcon} alt={"send"} onClick={this.onFocus} />
                                Add files
                            </Button>
                        </li>
                        {
                            allowFolder ?
                                <>
                                    <li className="or-text">or</li>
                                    <li>
                                        <Button onClick={this.onDirectoryFocus} className="select-folder">
                                            <img src={addFolderIcon} alt={"send"} onClick={this.onDirectoryFocus} />
                                            Select a folder
                                        </Button>
                                    </li>
                                </>
                            :
                            <></>
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

CustomFileUpload.defaultProps = {
    multiple: false,
    onChange: function(){  },
    allowFolder: true,
    accept: "*"
};

CustomFileUpload.propTypes = {
    classes: PropTypes.object,
    id: PropTypes.string,
    endButton: PropTypes.object,
    startButton: PropTypes.object,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    allowFolder: PropTypes.bool,
    accept: PropTypes.string
};

export default withStyles(styles)(CustomFileUpload);
