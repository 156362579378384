import React from 'react'
import className from "classnames";
// import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import Home from '../pages/Home';
import Files from '../pages/Files';
import Templates from "../pages/Templates";
import Spam from "../pages/Spam";
import Login from "../pages/auth/Login";
// import Signup from "../pages/auth/Signup";
import Logout from './Logout';
import BackgroundImages from "../pages/BackgroundImages";
import Settings from "../pages/Settings";
import Apps from "../pages/Apps";
import Downloads from "../pages/Downloads";

import withStyles from "@material-ui/core/styles/withStyles";
import Sidebar from "./Sidebar/Sidebar.jsx";
import routes from "../pages/routes.js";
import AdminNavbar from "./Navbars/AdminNavbar.jsx";
import Footer from './Footer/Footer';
import Accounts from "../pages/Accounts";
import DeletedAccounts from "../pages/DeletedAccounts";
import PromoCodes from "../pages/PromoCodes";
import NotFound from "./NotFound";
import ThemeSetting from "../pages/ThemeSetting";
import SignupControls from "../pages/SignupControls";
import SignupRequests from "../pages/SignupRequests";
import LoginVideos from "../pages/LoginVideos";
import PricingSchedule from "../pages/PricingSchedule";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import appStyle from "../../assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";
// var ps;

const RouterComponent = class extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.mainPanel = React.createRef();
        this.state = {
            mobileOpen: false,
            miniActive: false,
            image: require("../../assets/img/sidebar-2.jpg"),
            color: "blue",
            bgColor: "black",
            hasImage: true,
            fixedClasses: "dropdown",
            logo: require("../../assets/img/logo-white.svg")
        };

        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    }
    componentDidMount() {
        // if (navigator.platform.indexOf("Win") > -1) {
        //     ps = new PerfectScrollbar(this.mainPanel.current, {
        //         suppressScrollX: true,
        //         suppressScrollY: false
        //     });
        //     document.body.style.overflow = "hidden";
        // }
        // window.addEventListener("resize", this.resizeFunction);
    }
    componentWillUnmount() {
        // if (navigator.platform.indexOf("Win") > -1) {
        //     ps.destroy();
        // }
        // window.removeEventListener("resize", this.resizeFunction);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.history.location.pathname !== this.props.history.location.pathname) {
            this.mainPanel.current.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    handleDrawerToggle() {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    }
    sidebarMinimize = () => {
        this.setState({ miniActive: !this.state.miniActive });
    }
    resizeFunction = () => {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    }
    getActiveRoute = routes => {
        let activeRoute = "Sendlinx";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = this.getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    }
    render() {
        const { history, classes, ...rest } = this.props;
        const mainPanel =
            classes.mainPanel +
            " " +
            className({
                [classes.mainPanelSidebarMini]: this.state.miniActive,
                [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1 && false
            });
        return (
            <div className={"main-container "+classes.wrapper} id="global_wrapper">
                <Sidebar
                    routes={routes}
                    logoText={"Sendlinx"}
                    logo={this.state.logo}
                    image={this.state.image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color={this.state.color}
                    bgColor={this.state.bgColor}
                    miniActive={this.state.miniActive}
                    {...rest}
                />
                <div className={"mainContentPanel "+mainPanel} ref={this.mainPanel}>
                    <AdminNavbar
                        sidebarMinimize={this.sidebarMinimize.bind(this)}
                        miniActive={this.state.miniActive}
                        brandText={this.getActiveRoute(routes)}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />
                    <div className={classes.content}>
                        <div id="global_content" className={classes.container}>
                            <Switch>
                                <Route exact path="/" component={() => <Home store={this.store} history={this.history} />} />
                                <Route exact path="/dashboard" component={() => <Home store={this.store} history={this.history} />} />
                                <Route exact path="/dashboard/files" component={() => <Files store={this.store} history={this.history} />} />
                                <Route exact path="/dashboard/templates" component={() => <Templates store={this.store} history={this.history} />} />
                                <Route exact path="/dashboard/accounts" component={() => <Accounts store={this.store} history={this.history} type="signup" />} />
                                <Route exact path="/dashboard/accounts/limited" component={() => <Accounts store={this.store} history={this.history} type="limited" />} />
                                <Route exact path="/dashboard/accounts/recipients" component={() => <Accounts store={this.store} history={this.history} type="recipients" />} />
                                <Route exact path="/dashboard/accounts/deactivated" component={() => <Accounts store={this.store} history={this.history} type="deactivated" />} />
                                <Route exact path="/dashboard/accounts/deleted" component={() => <DeletedAccounts store={this.store} history={this.history} />} />
                                <Route exact path="/dashboard/spam" component={() => <Spam store={this.store} history={this.history} />} />
                                <Route exact path="/dashboard/manage/images" component={() => <BackgroundImages store={this.store} history={this.history} />} />
                                <Route exact path="/dashboard/settings" component={() => <Settings store={this.store} history={this.history} />} />
                                <Route exact path="/apps/metadata" component={() => <Apps store={this.store} history={this.history} />} />
                                <Route exact path="/apps/downloads" component={() => <Downloads store={this.store} history={this.history} />} />
                                <Route exact path="/auth/login" component={() => <Login store={this.store} history={this.history} />} />
                                <Route exact path="/promo/codes" component={() => <PromoCodes store={this.store} history={this.history} />} />
                                <Route exact path="/theme/setting" component={() => <ThemeSetting store={this.store} history={this.history} />} />
                                <Route exact path="/signup/controls" component={() => <SignupControls store={this.store} history={this.history} />} />
                                <Route exact path="/signup/requests" component={() => <SignupRequests store={this.store} history={this.history} />} />
                                <Route exact path="/videos" component={() => <LoginVideos store={this.store} history={this.history} />} />
                                <Route exact path="/pricing/schedule" component={() => <PricingSchedule store={this.store} history={this.history} />} />
                                {/* <Route exact path="/signup" component={() => <Signup store={this.store} history={this.history} />} /> */}


                                <Route exact path="/auth/logout" component={ () => <Logout store={this.store} history={this.history} />} />
                                <Route path="*" exact={true} component={NotFound} />
                            </Switch>
                        </div>
                    </div>
                    <Footer fluid />
                </div>
            </div>
        )
    }
}

export default withStyles(appStyle)(RouterComponent);
