import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LoaderComponent from '../Loader';
import GridItem from "../Grid/GridItem.jsx";

import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Wizard from "../Wizard/Wizard.jsx";
import Step1 from "./AddBusinessInfoSteps/Step1.jsx";
import Step2 from "./AddBusinessInfoSteps/Step2.jsx";

import addAccountModalStyle from "../../../assets/jss/home/addAccountModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const AddBusinessInfoModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { businessInfoDetails } = this.props;

        this.state = {
            businessInfoDetails: businessInfoDetails,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            loading: false,
            user: null,
            stepId: 0
        };

        this.appCategories = Api.getAppCategories();
        this.handleFinish = this.handleFinish.bind(this);
        this.onStepMount = this.onStepMount.bind(this);
    }
    onStepMount(stepId, state, stepChanged = false){
        this.setState({
            stepId: (stepChanged ? stepId : this.state.stepId),
            allStates: state
        });
    }
    handleFinish(allStates){
        const { businessInfoDetails } = this.state;

        const step1 = allStates.step1;
        const step2 = allStates.step2;

        const requestData = {
            id: businessInfoDetails.userId,
            businessType: step1.businessType,
            keywords: step1.keywords,
            employeesCount: step1.employeesCount,
            establishedDate: step1.establishedDate,
            website: step1.website,
            dob: step2.dob,
            occupation: step2.occupation,
            address1: step2.address1,
            address2: step2.address2,
            address3: step2.address3,
            countryId: step2.countryId,
            stateId: step2.stateId,
            cityId: step2.cityId,
            firstName: step2.firstName,
            lastName: step2.lastName,
            email: step2.email,
        };        

        const source = axios.CancelToken.source();
        this.setState({
            loading: true, 
            cancelToken: source,
            showError: false,
            stepId: 1
        });

        const that = this;
        
        Api.businessInfo(requestData, source).then(data => {
            that.setState({
                loading: false, 
                user: data.user
            });

            this.props.onSuccess();
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message,
                });
            }
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    render() {
        const { stepId, showError, businessInfoDetails, loading } = this.state;
        const { classes } = this.props;
        
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="addAccount-slide-title"
                aria-describedby="addAccount-slide-description"
            >
                <DialogContent
                    id="addAccount-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            showError ?
                                <div className={classes.errorsSections}>
                                    { this.renderErrorMessages() }
                                </div>
                            :
                            <></>
                        }
                        <Wizard
                            validate
                            store={this.store}
                            steps={[
                                { stepName: "Step1", stepComponent: Step1, stepId: "step1", props: {businessInfoDetails: businessInfoDetails} },
                                { stepName: "Step2", stepComponent: Step2, stepId: "step2", props: {businessInfoDetails: businessInfoDetails}  }
                            ]}
                            title={("Add Business Info")}
                            subtitle=""
                            finishButtonText={(
                                "Add Account"
                            )}
                            finishButtonClick={this.handleFinish}
                            onStepMount={this.onStepMount}
                            currentStep={stepId}
                            color="primary"
                        />
                        <div className={classes.modalFooter + " " + classes.justifyContentCenter}>
                            {
                                loading ?
                                    <LoaderComponent />
                                :
                                    <Button onClick={() => this.props.onClose()} color="primary" round>Cancel</Button>
                            }
                        </div>
                </DialogContent>   
            </Dialog>
        );
    }
};

AddBusinessInfoModal.defaultProps = {
    open: false,
};
AddBusinessInfoModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    businessInfoDetails: PropTypes.object.isRequired,
};
export default withStyles(addAccountModalStyle)(AddBusinessInfoModal);
