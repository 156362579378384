import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader";

import processingModalStyle from "../../../assets/jss/home/processingModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ProcessingModal = class extends React.Component {
    render() {
        const { classes, saving, loadingMessage, successMessage, errorMessage } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="processingModal-modal-slide-title"
                aria-describedby="processingModal-modal-slide-description"
            >
                <DialogTitle
                    id="processingModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        {
                            errorMessage !== null ?
                                <></>
                            :
                                saving ?
                                    <h4 className={classes.modalTitle+" "+classes.textCenter}>{loadingMessage}</h4> 
                                :
                                <></>
                        }
                </DialogTitle>
                <DialogContent
                    id="processingModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            errorMessage !== null ?
                                <p className={classes.errorMessage}>{errorMessage}</p>
                            :
                                saving ?
                                    <LoaderComponent align="center" color="success" />
                                :
                                <p>{successMessage}</p>
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    {
                        saving === false ?
                            <Button
                                onClick={() => this.props.onSuccess()}
                                color="info"
                                round
                            >
                                OK
                            </Button>
                        :
                        <></>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

ProcessingModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
    saving: false,
    errorMessage: null
}
ProcessingModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
    saving: PropTypes.bool,
    errorMessage: PropTypes.string
}
export default withStyles(processingModalStyle)(ProcessingModal);