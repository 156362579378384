import {
  grayColor,
  roseColor,
  primaryColor,
  infoColor,
  successColor,
  whiteColor
} from "./material-kit-pro-react.jsx";

const loaderStyle = {
    white: {
        color: whiteColor
    },
    primary: {
        color: primaryColor[0]
    },
    info: {
        color: infoColor[0]
    },
    gray: {
        color: grayColor[0]
    },
    success: {
        color: successColor[0],
    },
    rose: {
        color: roseColor[0],
    },
    customInfo: {
        color: infoColor[8]
    },
    customSuccess: {
        color: successColor[8],
    },
    customRose: {
        color: roseColor[5],
    },
    saving: {
        height: "18px",
        width: "18px",
        "& .MuiCircularProgress-root": {
            height: "18px !important",
            width: "18px !important",
        }
    },
};

export default loaderStyle;
