// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import HomeIcon from "@material-ui/icons/Home";
import EmailIcon from "@material-ui/icons/Email";
import BlockIcon from "@material-ui/icons/Block";
import AccountsIcon from "@material-ui/icons/SupervisorAccount";
import Assignment from "@material-ui/icons/Assignment";
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum";
import SettingsIcon from "@material-ui/icons/SettingsApplications";
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import LayersIcon from "@material-ui/icons/LayersOutlined";
import SignupControlIcon from "@material-ui/icons/PersonAddDisabled";
import SignupRequestsIcon from "@material-ui/icons/Notifications";
import VideoIcon from "@material-ui/icons/OndemandVideo";
import PaymentIcon from "@material-ui/icons/Payment";

import { ReactComponent as LoginIcon } from "../../assets/icons/signin.svg";
import { ReactComponent as SignupIcon } from "../../assets/icons/signup.svg";


var dashRoutes = [
    {
        path: "/auth/login",
        name: "Login",
        rtlName: "Login",
        icon: LoginIcon,
        layout: "",
        authRequired: false,
        isExternal: false
    },
    // {
    //     path: "/signup",
    //     name: "Signup",
    //     rtlName: "Signup",
    //     icon: SignupIcon,
    //     layout: "",
    //     authRequired: false,
    //     isExternal: false
    // },
    {
        collapse: true,
        name: "Dashboard",
        rtlName: "Dashboard",
        icon: DashboardIcon,
        state: "dashboardCollapse",
        authRequired: true,
        isExternal: false,
        views: [
            {
                path: "/dashboard",
                name: "Home",
                rtlName: "Home",
                icon: HomeIcon,
                layout: "",
                authRequired: true,
                isExternal: false
            },
            {
                path: "/dashboard/files",
                name: "Files",
                rtlName: "Files",
                icon: Assignment,
                layout: "",
                authRequired: true,
                isExternal: false
            },
            {
                path: "/dashboard/accounts",
                name: "Accounts",
                rtlName: "Accounts",
                icon: AccountsIcon,
                layout: "",
                authRequired: true,
                isExternal: false,
                state: "accountsCollapse",
                collapse: true,
                views: [
                    {
                        path: "/dashboard/accounts",
                        name: "Account Holders",
                        rtlName: "Account Holders",
                        icon: AccountsIcon,
                        layout: "",
                        authRequired: true,
                        isExternal: false
                    },
                    {
                        path: "/dashboard/accounts/limited",
                        name: "Limited Access",
                        rtlName: "Limited Access",
                        icon: AccountsIcon,
                        layout: "",
                        authRequired: true,
                        isExternal: false
                    },
                    {
                        path: "/dashboard/accounts/recipients",
                        name: "Recipients",
                        rtlName: "Recipients",
                        icon: AccountsIcon,
                        layout: "",
                        authRequired: true,
                        isExternal: false
                    },
                    {
                        path: "/dashboard/accounts/deactivated",
                        name: "To be Deleted",
                        rtlName: "To be Deleted",
                        icon: AccountsIcon,
                        layout: "",
                        authRequired: true,
                        isExternal: false
                    },
                    {
                        path: "/dashboard/accounts/deleted",
                        name: "Deleted List",
                        rtlName: "Deleted List",
                        icon: AccountsIcon,
                        layout: "",
                        authRequired: true,
                        isExternal: false
                    }
                ]
            },
            {
                path: "/dashboard/templates",
                name: "Email Templates",
                rtlName: "Email Templates",
                icon: EmailIcon,
                layout: "",
                authRequired: true,
                isExternal: false
            },
            {
                path: "/dashboard/spam",
                name: "Spam Control",
                rtlName: "Spam Control",
                icon: BlockIcon,
                layout: "",
                authRequired: true,
                isExternal: false
            },
            {
                path: "/dashboard/manage/images",
                name: "BG Images Management",
                rtlName: "BG Images Management",
                icon: PhotoAlbumIcon,
                layout: "",
                authRequired: true,
                isExternal: false
            },
            {
                path: "/dashboard/settings",
                name: "General Settings",
                rtlName: "General Settings",
                icon: SettingsIcon,
                layout: "",
                authRequired: true,
                isExternal: false
            },
        ]
    },
    {
        collapse: true,
        name: "Apps",
        rtlName: "Apps",
        icon: DashboardIcon,
        state: "appsCollapse",
        authRequired: true,
        isExternal: false,
        views: [
            {
                path: "/apps/metadata",
                name: "Meta Data",
                rtlName: "Meta Data",
                icon: PhonelinkSetupIcon,
                layout: "",
                authRequired: true,
                isExternal: false
            },
            {
                path: "/apps/downloads",
                name: "Downloads",
                rtlName: "Downloads",
                icon: DownloadIcon,
                layout: "",
                authRequired: true,
                isExternal: false
            },
        ]
    },
    {
        path: "/promo/codes",
        name: "Promo Codes",
        rtlName: "Promo Codes",
        icon: SettingsIcon,
        layout: "",
        authRequired: true,
        isExternal: false
    },
    {
        path: "/theme/setting",
        name: "Theme Setting",
        rtlName: "Theme Setting",
        icon: LayersIcon,
        layout: "",
        authRequired: true,
        isExternal: false
    },
    {
        path: "/signup/controls",
        name: "Sign Up Controls",
        rtlName: "Sign Up Controls",
        icon: SignupControlIcon,
        layout: "",
        authRequired: true,
        isExternal: false
    },
    {
        path: "/signup/requests",
        name: "Sign Up Requests",
        rtlName: "Sign Up Requests",
        icon: SignupRequestsIcon,
        layout: "",
        authRequired: true,
        isExternal: false
    },
    {
        path: "/videos",
        name: "Login Videos",
        rtlName: "Login Videos",
        icon: VideoIcon,
        layout: "",
        authRequired: true,
        isExternal: false
    },
    {
        path: "/pricing/schedule",
        name: "Pricing Schedule",
        rtlName: "Pricing Schedule",
        icon: PaymentIcon,
        layout: "",
        authRequired: true,
        isExternal: false
    },
    {
        path: "/auth/logout",
        name: "Logout",
        rtlName: "Logout",
        icon: SignupIcon,
        layout: "",
        authRequired: true,
        isExternal: false
    },
];
export default dashRoutes;
