/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

// core components
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";

import navPillsStyle from "../../../assets/jss/material-kit-pro-react/components/navPillsStyle.jsx";

class NavPills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active
    };
  }
  handleChange = (event, active, updateParent = true) => {
    this.setState({ active });
    if(updateParent === true){
        this.props.onChange(active);
    }    
  };
  handleChangeIndex = (index, updateParent = true) => {
    this.setState({ active: index });
    this.props.onChange(index);
  };
  render() {
    const {
      classes,
      tabs,
      direction,
      color,
      horizontal,
      alignCenter
    } = this.props;
    const flexContainerClasses = classNames({
      [classes.flexContainer]: true,
      [classes.horizontalDisplay]: horizontal !== undefined
    });
    const tabButtons = (
      <Tabs
        classes={{
          root: classes.root,
          fixed: classes.fixed,
          flexContainer: flexContainerClasses,
          indicator: classes.displayNone
        }}
        value={this.state.active}
        onChange={this.handleChange}
        centered={alignCenter}
      >
        {tabs.map((prop, key) => {
            let hiddenTab = (prop.hasOwnProperty("hidden") && prop.hidden === true);
            var icon = {};
            if (prop.tabIcon !== undefined) {
                icon["icon"] = <prop.tabIcon className={classes.tabIcon} />;
            }else if(prop.tabImageIcon !== undefined){
                icon["icon"] = prop.tabImageIcon;
            }
            const pillsClasses = classNames({
                [classes.pills]: true,
                [classes.horizontalPills]: horizontal !== undefined,
                [classes.pillsWithIcons]: prop.tabIcon !== undefined || prop.tabImageIcon !== undefined
            });
            return (
                <Tab
                    label={prop.tabButton}
                    key={key}
                    {...icon}
                    className={prop.className+" "+(hiddenTab?classes.hiddenContent:"")}
                    classes={{
                        root: pillsClasses,
                        label: classes.label,
                        selected: classes[color]
                    }}
                />
            );
        })}
      </Tabs>
    );
    const tabContent = (
      <div className={"navPillsContentWrapper "+classes.contentWrapper}>
        <SwipeableViews
          axis={direction === "rtl" ? "x-reverse" : "x"}
          index={this.state.active}
          onChangeIndex={this.handleChangeIndex}
        >
          {tabs.map((prop, key) => {
                if(prop.hasOwnProperty("hidden") && prop.hidden === true){
                    return <div key={key} className={classes.hiddenContent}></div>;
                }
                return (
                    <div className={"navPillsTabContent "+classes.tabContent+" "+(key !== this.state.active?classes.hiddenContent:"navPillsActiveTabContent")} key={key}>
                        {prop.tabContent}
                    </div>
                );
          })}
        </SwipeableViews>
      </div>
    );
    return horizontal !== undefined ? (
      <GridContainer>
        <GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
        <GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
      </GridContainer>
    ) : (
      <div>
        {tabButtons}
        {tabContent}
      </div>
    );
  }
}

NavPills.defaultProps = {
  active: 0,
  color: "primary",
  onChange: function(){ },
  className: ""
};

NavPills.propTypes = {
  classes: PropTypes.object.isRequired,
  // index of the default active pill
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.string,
      tabIcon: PropTypes.object,
      tabImageIcon: PropTypes.node,
      tabContent: PropTypes.node,
      hidden: PropTypes.bool
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ]),
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object
  }),
  alignCenter: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};

export default withStyles(navPillsStyle)(NavPills);
