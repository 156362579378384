/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {
    grayColor,
    whiteColor,
    infoColor,
    dangerColor
  } from "../material-kit-pro-react.jsx";

import customCheckboxRadioSwitch from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
import scrollbarStyle from 'react-custom-scroll/dist/customScroll.css';
import circularProgressbarStyle from 'react-circular-progressbar/dist/styles.css';

const homePageStyle = theme => ({
    ...customCheckboxRadioSwitch,
    ...scrollbarStyle,
    ...circularProgressbarStyle,
    transferForm: {
        "& h3": {
            color: whiteColor,
            marginBottom: "20px",
            width: "100%",
            textShadow: "2px 2px 2px #000",
            marginTop: "0px"
        },
        position: "relative",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        zIndex: "2",
        borderRadius: "10px",
        height: "auto",
        left: "0px",
        right: "0px",
        marginRight: "auto",
        marginLeft: "auto",
        top: "8em",
        maxWidth: "32em",
        width: "100%",
        marginBottom: "50px",
        overflow: "hidden",
        [theme.breakpoints.down("md")]: {
            "& h3":{
                marginBottom: "30px",
                fontSize: "24px"
            }
        },
        [theme.breakpoints.down("sm")]: {
            "& h3":{
                marginBottom: "20px",
                fontSize: "20px"
            }
        },
        [theme.breakpoints.down("xs")]: {
            top: "5em",
            width: "95%",
            marginBottom: "20px",
        }
    },
    fileSendingForm: {
        top: "5em",
        borderRadius: "0px",
        backgroundColor: "transparent",
        "& >div": {
            width: "100%",
            overflow: "hidden",
            clear: "both"
        },
        [theme.breakpoints.down("sm")]: {
            left: "0px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            paddingRight: "10px",
            paddingLeft: "10px",
        }
    },
    formFiles: {
        borderBottom: "none",
        position: "relative",
        padding: "0",
        width: "100%",
    },
    formFields: {
        "& > div": {
            marginBottom: "10px",
            overflow: "hidden",
            clear: "both",
            "& .MuiInput-multiline": {
                "&:before, &:after": {
                    content: "none"
                }
            }
        },
        "& .formElement": {
            width: "100%",
            padding: "0",
            position: "relative",
            "& p":{
                color: whiteColor,
                textAlign: "left",
                fontSize: "14px",
                margin: "0px",
                textShadow: "2px 2px 2px #000",
            }
        },
        "& input, textarea": {
            backgroundColor: whiteColor,
            padding: "10px",
            boxSizing: "border-box",
            borderRadius: "3px",
            width: "100%",
            verticalAlign: "top",
            display: "inline-block",
            border: "none",
            float: "right"
        },
        "& textarea": {
            minHeight: "200px"
        },
        "& label": {
            color: whiteColor,
            maxWidth: "150px",
            width: "100%",
            textAlign: "right",
            fontSize: "18px",
            marginTop: "7px",
            display: "inline-block",
            textShadow: "2px 2px 2px #000",
        },
        "& .claer-icon": {
            position: "absolute",
            top: "5px",
            right: "15px",
            fill: dangerColor[0]
        },
        [theme.breakpoints.down("sm")]: {
            "& label":{
                maxWidth: "100%",
                textAlign: "left",
                display: "block",
                padding: "0",
                fontSize: "16px",
                marginTop: "0px"
            },
            "& textarea": {
                minHeight: "135px",
            },
            "& .formElement": {
                width: "100%",
                float: "none"
            }
        },
    },
    transferOptions: {
        display: "none",
        background: "#f7f9fa",
        borderTop: "1px solid "+grayColor[17],
        padding: "0 10px",
        "&.active": {
            display: "block"
        },
        "& .transfer-option": {
            borderBottom: "1px solid "+grayColor[17],
            cursor: "default",
            padding: "0.55em 0 0.45em",
            position: "relative",
            textAlign: "left",
            "&:last-of-type": {
                borderBottom: "none"
            }
        },
        "& .transfer-plus-features": {
            "& label": {
                color: "#484a4d",
                display: "block",
                fontSize: "0.75em",
                margin: "0",
                width: "10.1666666667em"
            },
            "& .transfer-option-placeholder": {
                color: "#17181a",
                cursor: "pointer",
                display: "inline",
                fontSize: "0.875em"
            },
            "& .transfer-option-disabled": {
                "& label,& .transfer-option-placeholder": {
                    color: "rgba(23,24,26,0.2)"
                }
            }
        }
    },
    transferFooter: {
        position: "relative",
        padding: "0",
        borderTop: "1px solid "+grayColor[17],
        height: "4.5em",
        "& .transfer-toggle-options": {
            left: "10px",
            position: "absolute",
            top: "50%",
            height: "41px",
            padding: "0px",
            width: "41px",
            "-webkit-transform": "translateY(-50%)",
            transform: "translateY(-50%)",
            margin: "0px",
            "& svg": {
                fill: infoColor[0],
                border: "2px solid "+infoColor[0],
                borderRadius: "100%",
                width: "24px",
                height: "24px"
            },
            "&:hover": {
                "& svg": {
                    fill: infoColor[1],
                    borderColor: infoColor[1],
                }
            }
        },
        "& Button": {
            "& svg":{
                cursor: "pointer",
                height: "1.5em",
                marginRight: "0.2em",
                width: "1.5em",
                fill: infoColor[0],
                fontSize: "inherit",
                transform: "rotate(-45deg)",
                display: "inline-block",
                verticalAlign: 'top',
                marginTop: "-5px",
                "&:hover": {
                    fill: infoColor[1],
                }
            },
            display: "block",
            fontSize: "1em",
            margin: "12px auto",
            color: whiteColor,
            padding: "10px 30px",
            textTransform: "capitalize"
        }
    },
    fileSendingFormFooter: {
        textAlign: "left",
        width: "100% !Important",
        border: "none",
        height: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        "& img": {
            width: "1.5em",
            marginTop: "-5px",
            marginRight: "0.2em",
        },
        "& Button": {
            borderTop: "1px solid "+grayColor[17],
            textAlign: "left",
            backgroundColor: "transparent !Important",
            width: "100%",
            borderRadius: "0px",
            padding: "10px 8px",
            boxShadow: "none !Important",
            margin: "0px"
        },
    },
    disableFooter: {
        borderRadius: "0px 0px 5px 5px"
    },
    transferFormLink: {
        "& .add-files-button": {
            minHeight: "15.8em",
            paddingTop: "7.1875em",
        },
        "& .add-files-icon": {
            top: "7.125em"
        },
        "& .add-file-title": {
            top: "5.4545454545em"
        },
        "& .select-folder": {
            top: "10.1538461538em"
        },
        "& .uploader-list": {
            minHeight: "16.2em"
        },
        [theme.breakpoints.down("xs")]: {
            "& .add-files-button": {
                minHeight: "13.5em",
                paddingTop: "5.5em"
            },
            "& .add-files-icon": {
                top: "5.8em"
            },
            "& .add-file-title": {
                top: "4.5em"
            },
            "& .select-folder": {
                top: "8.5em"
            },
        }
    },
    tagsInput: {
        textAlign: "left",
        marginBottom: "5px",
        width: "100%",
        padding: "5px",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        borderRadius: "5px",
        "& .react-tagsinput-tag a::before": {
            color: infoColor[0],
        },
        "& .users-icon": {
            float: "left",
            "& img": {
                maxWidth: "20px",
                width: "100%"
            },
        },
        "& .users-lists": {
            paddingLeft: "20px"
        },
        "& span": {
            textTransform: "none",
            backgroundColor: "transparent !Important",
            borderRight: "1px solid "+whiteColor,
            borderRadius: "0px",
            lineHeight: "normal",
            paddingTop: "0px",
            paddingBottom: "0px",
            "& a": {
                top: "1px"
            }
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            float: "none"
        },
    },
    uploadingProgress: {
        "& .transfer_loader": {
            maxWidth: "170px",
            margin: "3em auto 1.3em",
            "& span": {
                color: "#3e98c7"
            }
        },
        "& h2": {
            margin: "0 0 0.3em",
            padding: "0",
            fontSize: "1.375em",
            lineHeight: "1.2",
            color: whiteColor
        },
        "& p": {
            fontSize: "0.875em",
            lineHeight: "1.2857142857em",
            margin: "0 0 5px",
            padding: "0",
            color: whiteColor,
            "&.orangeColor": {
                color: infoColor[0]
            }
        },
    },
    uploaderCompleteContent: {
        padding: "0px 20px",
        marginBottom: "20px",
        "& img": {
            width: "10.625rem",
            margin: "1.875em auto 0px",
            [theme.breakpoints.down("xs")]: {
                width: "9rem"
            },
        },
        "& h2": {
            fontSize: "1.375em",
            lineHeight: "1.2",
            margin: "0 0 0.1818181818em",
            padding: "0",
            color: whiteColor
        },
        "& p": {
            fontSize: "0.875em",
            lineHeight: "1.2857142857em",
            margin: "0",
            padding: "0",
            textAlign: "center",
            whiteSpace: "pre-wrap",
            color: whiteColor
        },
        "& .success-image": {
            marginBottom: "10px"
        }
        // "& button": {
        //     padding: "0px",
        //     margin: "0 0 0.9285714286em",
        //     fontSize: "1em",
        //     color: grayColor[0],
        //     textTransform: "inherit",
        //     textDecoration: "underline",
        //     "&:hover, &:active, &:focus": {
        //         color: grayColor[0],
        //         textDecoration: "underline",
        //     }
        // }
    },
    uploadingContent: {
        marginBottom: "20px"
    },
    downloadLink: {
        "& a": {
            wordBreak: "break-all",
            fontSize: "13px",
            color: infoColor[0]
        },
        marginBottom: "5px",
        marginTop: "5px",
        lineHeight: "18px"
    },
    editor: {
        color: grayColor[1],
        "& .public-DraftEditor-content": {
            minHeight: "150px"
        }
    },
    uploaderList: {
        position: "relative",
        width: "100% !Important",        
        borderRadius: "0px 0px 5px 5px",
        "& .uploader-add-files-count": {
            color: whiteColor,
            display: "block",
            textAlign: "right",
            fontSize: "14px",
            lineHeight: "normal",
            marginBottom: "0px",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            paddingTop: "5px",
            paddingBottom: "5px",
            "& span": {
                color: infoColor[0],
                borderLeft: "1px solid "+whiteColor,
                marginLeft: "6px",
                paddingLeft: "5px",
                verticalAlign: "top",
                maxWidth: "118px",
                width: "100%",
                display: "inline-block",
                textAlign: "left",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
            }
        },
    },
    filesList: {
        listStyle: "none",
        margin: "0",
        padding: "0",
        "& .file": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            cursor: "default",
            margin: "0",
            outline: "0",
            padding: "10px 8px",
            position: "relative",
            textAlign: "left",
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
            }
        },
        "& .file-header": {
            position: "relative"
        },
        "& .file-title": {
            fontWeight: "400",
            color: whiteColor,
            display: "block",
            fontSize: "14px",
            lineHeight: "normal",
            margin: "0",
            overflow: "hidden",
            padding: "0",
            "& .file-name": {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "calc(100% - 150px)",
                display: "inline-block",
                verticalAlign: "middle",
            },
            "& .icon":{
                maxWidth: "18px",
                width: "100%",
                marginRight: "5px",
                textAlign: "center",
                display: "inline-block",
                "& img": {
                    verticalAlign: "bottom",
                    width: "100%",
                },
                "&.file-icon": {
                    "& img": {
                        maxWidth: "13px"
                    }
                },
            }
        },
        "& .file-detail":{
            float: "right",
            borderLeft: "1px solid "+whiteColor,
            paddingLeft: "5px",
            maxWidth: "110px",
            width: "100%",
            "& svg": {
                width: "9px",
                height: "7px"
            },
            "& .folder-detail": {
                marginLeft: "0.25em"
            }
        },
        "& .filelist-action-delete": { 
            cursor: "pointer",
            position: "absolute",
            right: "-8px",
            top: "-4px",
            width: "auto",
            display: "none",
            "& img": {
                width: "28px",
            },
            [theme.breakpoints.down("xs")]: {
                display: "block"
            },
        },
        "& .file:hover": {
            "& .filelist-action-delete": { 
                display: "block"
            }
        },
    },
    transferTypeLink: {
        marginBottom: "25px",
        "& button": {
            color: whiteColor+" !important",
            fontSize: "16px",
            textTransform: "none",
            padding: "0px",
            margin: "0px",
            textShadow: "2px 2px 2px #000",
            "& img": {
                maxWidth: "28px",
                marginRight: "8px"
            }
        }
    },
});

export default homePageStyle;
