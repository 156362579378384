import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader.js';
import GridItem from "../Grid/GridItem.jsx";

import axios from "axios";
import Api from "../../../assets/js/utils/Api.js";
import CustomInput from "../CustomInput/CustomInput.jsx";

import editPackageModalStyle from "../../../assets/jss/home/editPackageModalStyle.jsx";
import { helper } from "../../../assets/js/utils/Element.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ChangeStorageModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { changeStorageDetail } = this.props;

        this.state = {
            changeStorageDetail: changeStorageDetail,
            quota: changeStorageDetail.quota,
            usage: changeStorageDetail.usage,
            remaining: changeStorageDetail.quota - changeStorageDetail.usage,
            storageSize: helper.getFormatedSizeInGb(changeStorageDetail.quota, false),
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
        };

        this.updateStorage = this.updateStorage.bind(this);
    }
    updateStorage(){
        const { storageSize, changeStorageDetail } = this.state;
        
        const requestData = {
            id: changeStorageDetail.userId,
            quota: helper.getBytesSize(storageSize),
        }
        
        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.changeStorageSize(requestData, source).then(data => {
            that.setState({
                success: true,
                saving: false
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    saving: false,
                    showError: true,
                    errorMessage: err.message,
                    cancelToken: null,
                });
            }
        });
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    renderForm(){
        const { saving, storageSize, usage, quota, remaining } = this.state;
        const { classes } = this.props;

        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.editPackage}>
                        <CustomInput 
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="Storage Size (GB)"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,"storageSize"),
                                value: storageSize,
                                name: "storageSize",
                                id: "input-storageSize",
                                className: classes.alignLeft,
                                type: "number",
                            }}
                            color="info"
                        />
                    </div>
                    <div className={classes.textLeft}>{"Max Reduction: "+helper.getFormatedSize(remaining)+" (used "+helper.getFormatedSize(usage)+" / "+helper.getFormatedSize(quota)+")"}</div>
                    <div className={classes.transferFooter+" "+classes.fileSendingFormFooter}>
                        <Button onClick={() => this.updateStorage()} type="button" color="info" disabled={storageSize === '' ? true : false}>
                            {
                                saving ?
                                    <LoaderComponent align="left" saving={true} color="white" />
                                :
                                    "Change"
                            }
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { success, showError } = this.state;
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="editPackage-slide-title"
                aria-describedby="editPackage-slide-description"
            >
                <DialogTitle
                    id="editPackage-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h5 className={classes.modalTitle}>Are you sure you want to change storage size?</h5>
                </DialogTitle>
                <DialogContent
                    id="editPackage-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            success ?
                                <>
                                    <p className={classes.successMessage}>Changes saved successfully.</p>
                                    <Button
                                        color="info"
                                        onClick={() => this.props.onSuccess()}
                                    >
                                        Close
                                    </Button>
                                </>
                            :
                                <>
                                    {
                                        showError ?
                                            <div className={classes.errorsSections}>
                                                { this.renderErrorMessages() }
                                            </div>
                                        :
                                        <></>
                                    }
                                    { this.renderForm() }
                                </>
                        }
                </DialogContent>      
            </Dialog>
        );
    }
};

ChangeStorageModal.defaultProps = {
    open: false,
};
ChangeStorageModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    changeStorageDetail: PropTypes.object.isRequired,
};
export default withStyles(editPackageModalStyle)(ChangeStorageModal);
