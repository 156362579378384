import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import Close from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import Assignment from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteFileModal from "../components/Home/DeleteFileModal";
import UploadFileModal from "../components/Home/UploadFileModal";
import EditFileDetailsModal from "../components/Home/EditFileDetailsModal";
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import backgroundImagesPageStyle from "../../assets/jss/backgroundImagesPageStyle";

class BackgroundImages extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.state = {
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            deleteFileModal: false,
            limit: 200,
            deleteAll: false,
            uploadFileModal: false,
            imageDetailsModal: false,
            image: null
        };
        this.handleToggle = this.handleToggle.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onModalSuccess = this.onModalSuccess.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
    }
    componentDidMount(){
        this.loadImages();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
      }
    loadImages(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 25
        };
        Api.getBackgroundImages(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            uploadFileModal: false,
            imageDetailsModal: false,
            image: null
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(file => {
            let fileArray = {
                id: file.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(file.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(file.id)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                image: (
                    <a href={file.url} target="_blank" rel="noopener noreferrer">
                        <img className={classes.imagePreview} src={file.url} alt={file.name} />
                    </a>
                ),
                name: file.name,
                size: file.size,
                actions: (
                    <>
                        <Button justIcon size="sm" color="info" onClick={() => this.onEditModal(true, file)}>
                            <EditIcon />
                        </Button>
                        <Button justIcon size="sm" color="danger" onClick={() => this.deleteFile(file)}>
                            <Close />
                        </Button>
                    </>
                )
            };
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    deleteSelected(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        this.setState({deleteFileModal: true});
    }
    deleteAll(){
        this.setState({deleteFileModal: true, deleteAll: true});
    }
    deleteFile(file){
        let checked = [];
        checked.push(file.id);
        this.setState({deleteFileModal: true, checked: checked});
    }
    onModalClose(deleteFileModal = true){
        let checked = this.state.checked;
        if(deleteFileModal === false){
            checked = [];
        }
        this.setState({
            deleteFileModal: deleteFileModal,
            checked: checked,
            deleteAll: false
        });
    }
    onModalSuccess(){
        const { checked, response, deleteAll } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            images: checked,
            clean: (deleteAll ? 1: 0)
        };
        Api.deleteImages(requestData, source).then(data => {
            //Handle Success;
        }).catch(err => {
            //Handle Error
        });
        if(deleteAll){
            this.setState({
                response: [],
                checked: [],
                page: 1,
                totalPages: 1,
                deleteFileModal: false,
                deleteAll: false
            });
            return;
        }

        let newResponse = [];
        response.map(file => {
            if(!checked.includes(file.id)){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteFileModal: false,
            deleteAll: false
        });
    }
    onUploadModal(uploadFileModal = true){
        if(!uploadFileModal){
            this.loadImages(false);
            return;
        }
        this.setState({
            uploadFileModal: uploadFileModal,
        });
    }
    onUploadModalSuccess(){
        this.loadImages(false);
    }
    onEditModal(status = true, image){
        if(!status){
            this.loadImages(false);
            return;
        }
        this.setState({
            imageDetailsModal: status,
            image: image
        });
    }
    onEditModalSuccess(){
        this.loadImages(false);
    }
    render() {
        const { classes } = this.props;
        const { loading, page, loadingMore, totalPages, deleteFileModal, checked, limit, deleteAll, uploadFileModal, 
            imageDetailsModal, image } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        const tableData = this.getTableData();
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Background Images Management
                                <Button color="primary" round onClick={() => this.onUploadModal(true)} className={classes.uploadButton}>
                                    Upload
                                </Button>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loading ?
                                    <LoaderComponent align="center" />
                                :
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                headerClassName: "hd_id",
                                                className: "hd_id td_id",
                                            },
                                            {
                                                Header: "",
                                                accessor: "check",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_id",
                                                className: "hd_id td_id",
                                            },
                                            {
                                                Header: "",
                                                accessor: "image",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_image",
                                                className: "hd_image td_image",
                                            },
                                            {
                                                Header: "Name",
                                                accessor: "name",
                                                headerClassName: "hd_name",
                                                className: "hd_name td_name",
                                            },
                                            {
                                                Header: "Size",
                                                accessor: "size",
                                                headerClassName: "hd_size",
                                                className: "hd_size hd_size",
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_actions",
                                                className: "hd_actions td_actions",
                                            }
                                        ]}
                                        data={tableData}
                                        pageSizeOptions={[25, 50, 100, 200]}
                                        defaultPageSize={limit}
                                        showPaginationTop
                                        minRows={0}
                                        showPaginationBottom={false}
                                        className={"-striped -highlight "+classes.imagesTable}
                                    />
                            }
                            <div className={classes.loadMore}>
                                {
                                    loadingMore === false && tableData.length > 0 ?
                                        <>
                                            <Button color="primary" disabled={(checked.length === 0)} round onClick={(e) => this.deleteSelected()}>
                                                <Close className={classes.icons} />Delete Selected
                                            </Button>
                                            {
                                                user.level_id === 1 ?
                                                    <Button color="primary" round onClick={(e) => this.deleteAll()}>
                                                        <Close className={classes.icons} />Delete All
                                                    </Button>
                                                :
                                                <></>
                                            }
                                        </>
                                    :
                                    <></>
                                }
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="primary" id="contentViewMore" round onClick={(e) => this.loadImages(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <DeleteFileModal deleteAll={deleteAll} open={deleteFileModal} onClose={() => this.onModalClose(false)} onSuccess={() => this.onModalSuccess()} />
                {
                    uploadFileModal ?
                        <UploadFileModal open={uploadFileModal} store={this.store} onClose={() => this.onUploadModal(false)} onSuccess={() => this.onUploadModalSuccess()} />
                    :
                    <></>
                }
                {
                    imageDetailsModal ?
                        <EditFileDetailsModal open={imageDetailsModal} store={this.store} onClose={() => this.onEditModal(false)} onSuccess={() => this.onEditModalSuccess()} image={image} />
                    :
                    <></>
                }
            </GridContainer>
        );
    }
}

BackgroundImages.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(backgroundImagesPageStyle)(BackgroundImages);
