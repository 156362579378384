import {
    whiteColor,
    cardTitle
} from "./material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "./material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const backgroundImagesPageStyle = theme => ({
    ...customCheckboxRadioSwitchStyle,
    main: {
        background: whiteColor
    },
    loadMore: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    uploadButton: {
        float: "right",
        marginTop: "0px !important"
    },
    imagePreview: {
        width: "50px",
        height: "50px",
        objectFit: "cover"
    },
    imagesTable: {
        "& .rt-thead .rt-th": {
            fontSize: "14px",
            "&.hd_hide": {
                display: 'none'
            }
        },
        "& .rt-tbody .rt-td": {
            fontSize: "12px",
            "&.td_hide": {
                display: "none"
            }
        },
        "& .hd_check": {
            "& .MuiIconButton-root": {
                padding: "0px"
            }
        },
        "& .hd_id": {
            width: "50px !important",
            textAlign: "center !important",
            flex: "50 0 auto !important"
        },
        "& .hd_image": {
            width: "60px !important",
            textAlign: "left !important",
        },
        "& .hd_name": {
            width: "40% !important",
            textAlign: "left !important",
        },
        "& .hd_size": {
            width: "13% !important",
            textAlign: "left !important",
        },
        "& .hd_actions": {
            width: "15% !important",
            textAlign: "left !important",
        }
    },
});

export default backgroundImagesPageStyle;
