import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import Close from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteFileModal from "../components/Home/DeleteFileModal";
import ConfirmationModal from "../components/Home/ConfirmationModal";
import ReactTable from "react-table";
import SignupRequestsIcon from "@material-ui/icons/Notifications";
import AcceptIcon from '@material-ui/icons/CheckCircleOutline';
import RejectIcon from '@material-ui/icons/Block';
import CustomSelect from "../components/CustomInput/CustomSelect";
import moment from 'moment';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import signupRequestsPageStyle from "../../assets/jss/signupRequestsPageStyle";

class SignupRequests extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            allAccountTypes: this.getPlans(),
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            deleteFileModal: false,
            requestModal: false,
            requestAction: '',
            limit: 10000000,
            deleteAll: false,
            requesting: false,
            values: {
                requestType: "all"
            }
        };        

        this.appCategories = Api.getAppCategories();
        this.handleToggle = this.handleToggle.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onModalSuccess = this.onModalSuccess.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.requestTypes = [
            {
                type: "all",
                name: "All"
            },
            {
                type: "1",
                name: "Accepted"
            },
            {
                type: "2",
                name: "Rejected"
            },
            {
                type: "0",
                name: "Pending"
            },
            {
                type: "3",
                name: "Complete"
            }
        ];
    }
    componentDidMount(){
        this.loadSignupRequests();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    getPlans(){
        let plans = Api.getPlans();
        
        let accountTypes = [];
        plans.map(plan => {
            if(plan.id === '-1'){
                return null;
            }
            accountTypes.push(plan);
        
            return null;
        });

        return accountTypes;
    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    loadSignupRequests(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }

        let requestData = Object.assign({}, this.state.values);
        requestData['page'] = page;
        requestData['limit'] = 200;
        
        Api.getSignupRequests(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(request => {
            let requestArray = {
                id: request.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(request.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(request.id)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                created_at: moment(request.created_at).format("MM/DD/YYYY"),
                email: request.email,
                firstName: request.first_name,
                lastName: request.last_name,
                phoneNumber: request.phone,
                compnayName: request.compnay_name,
                accountType: this.getAccountType(request),
                businessAccounts: request.business_accounts,
                actions: (
                    <>
                        {
                            request.status === 0 ?
                                <>
                                    <Button justIcon size="sm" color="info" title="Accept" onClick={() => this.onSignupRequestUpdate(true, 'accept', request)}>
                                        <AcceptIcon />
                                    </Button>
                                    <Button justIcon size="sm" color="primary" title="Reject" onClick={() => this.onSignupRequestUpdate(true, 'reject', request)}>
                                        <RejectIcon />
                                    </Button>
                                </>
                            :
                                <></>
                        }
                        <Button justIcon size="sm" color="danger" title="Delete" onClick={() => this.deleteFile(request)}>
                            <Close />
                        </Button>
                    </>
                )
            };
            tableData.push(requestArray);
            return null;
        });
        return tableData;
    }
    deleteSelected(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        this.setState({deleteFileModal: true});
    }
    deleteAll(){
        this.setState({deleteFileModal: true, deleteAll: true});
    }
    deleteFile(file){
        let checked = [];
        checked.push(file.id);
        this.setState({deleteFileModal: true, checked: checked});
    }
    onModalClose(deleteFileModal = true){
        let checked = this.state.checked;
        if(deleteFileModal === false){
            checked = [];
        }
        this.setState({
            deleteFileModal: deleteFileModal,
            checked: checked,
            deleteAll: false
        });
    }
    onModalSuccess(){
        const { checked, response, deleteAll } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            signupRequests: checked,
            clean: (deleteAll ? 1: 0)
        };
        Api.deleteSignupRequests(requestData, source).then(data => {
            //Handle Success;
        }).catch(err => {
            //Handle Error
        });
        if(deleteAll){
            this.setState({
                response: [],
                checked: [],
                page: 1,
                totalPages: 1,
                deleteFileModal: false,
                deleteAll: false
            });
            return;
        }

        let newResponse = [];
        response.map(file => {
            if(!checked.includes(file.id)){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteFileModal: false,
            deleteAll: false
        });
    }
    onSignupRequestUpdate(status = true, requestAction = '', request = null){
        let checked = [];

        if(request){
            checked.push(request.id);
        }

        this.setState({
            requesting: false,
            requestModal: status,
            requestAction: requestAction,
            checked: checked
        })
    }
    onSignupRequestSuccess(){
        const { checked, requestAction, response } = this.state;
        const source = axios.CancelToken.source();

        this.setState({
            requesting: true,
            cancelToken: source,
        });

        console.log(checked);

        const requestData = {
            id: checked[0],
            requestAction: requestAction
        };

        Api.updateSignupRequest(requestData, source).then(data => {
            let newResponse = [];
            response.map(file => {
                if(!checked.includes(file.id)){
                    newResponse.push(file);
                }
                return null;
            });

            this.setState({
                response: newResponse,
                checked: [],
                requestModal: false,
                requestAction: '',
                requesting: false
            });

            this.loadSignupRequests();
        }).catch(err => {
            //Handle Error
        });
    }
    getAccountType(file){
        let type = "";
        this.state.allAccountTypes.map((item) => {
            if(item.id === file.account_type){
                type = item.label;
            }
            return null;
        });

        return type;
    }
    handleChange(event, name) {
        this.setState({
            values: {
                ...this.state.values,
                [name]: event.target.value
            }
        });
    }
    handleSubmit(e){
        e.preventDefault();
        this.loadSignupRequests();
    }
    render() {
        const { classes } = this.props;
        const { loading, page, loadingMore, totalPages, deleteFileModal, checked, limit, deleteAll, requestModal, requesting, requestAction, values } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }

        const tableData = this.getTableData();
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <SignupRequestsIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Sign Up Requests
                            </h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loading ?
                                    <LoaderComponent align="center" />
                                :
                                    <>
                                        <div>
                                            <form onSubmit={this.handleSubmit} className={classes.form} noValidate autoComplete="off">
                                                <CustomSelect 
                                                    labelText="Status"
                                                    selectProps={{
                                                        onChange: (e) => this.handleChange(e, "requestType"),
                                                        value: values.requestType
                                                    }}
                                                    inputProps={{
                                                        name: "requestType",
                                                        id: "input-requestType",
                                                    }}
                                                    items={this.requestTypes}
                                                    itemLabel="name"
                                                    itemValue="type"
                                                />
                                                <Button round color="primary" type="submit">Search</Button>
                                            </form>
                                        </div>
                                        <ReactTable
                                            columns={[
                                                {
                                                    Header: "ID",
                                                    accessor: "id",
                                                    headerClassName: "hd_check",
                                                    className: "hd_check td_check",
                                                    width: 50
                                                },
                                                {
                                                    Header: "",
                                                    accessor: "check",
                                                    sortable: false,
                                                    filterable: false,
                                                    headerClassName: "hd_check",
                                                    className: "hd_check td_check",
                                                    width: 50
                                                },
                                                {
                                                    Header: "Date",
                                                    accessor: "created_at",
                                                    sortMethod: (a, b) => {
                                                        var a1 = new Date(a).getTime();
                                                        var b1 = new Date(b).getTime();
                                                      if(a1<b1)
                                                        return 1;
                                                      else if(a1>b1)
                                                        return -1;
                                                      else
                                                        return 0;
                                                    }
                                                },
                                                {
                                                    Header: "First Name",
                                                    accessor: "firstName",
                                                    headerClassName: "hd_firstName",
                                                    className: "hd_firstName td_firstName",
                                                },
                                                {
                                                    Header: "Last Name",
                                                    accessor: "lastName",
                                                    headerClassName: "hd_lastName",
                                                    className: "hd_lastName td_lastName",
                                                },
                                                {
                                                    Header: "Email",
                                                    accessor: "email",
                                                    headerClassName: "hd_email",
                                                    className: "hd_email td_email",
                                                    width: 200
                                                },
                                                {
                                                    Header: "Phone Number",
                                                    accessor: "phoneNumber",
                                                    headerClassName: "hd_phone",
                                                    className: "hd_phone td_phone",
                                                },
                                                {
                                                    Header: "Company Name",
                                                    accessor: "companyName",
                                                    headerClassName: "hd_companyName",
                                                    className: "hd_companyName td_companyName",
                                                },
                                                {
                                                    Header: "Type",
                                                    accessor: "accountType",
                                                    headerClassName: "hd_type",
                                                    className: "hd_type td_type",
                                                },
                                                {
                                                    Header: "Business Accounts",
                                                    accessor: "businessAccounts",
                                                    headerClassName: "hd_business_accounts",
                                                    className: "hd_business_accounts td_business_accounts",
                                                },
                                                {
                                                    Header: "Actions",
                                                    accessor: "actions",
                                                    sortable: false,
                                                    filterable: false,
                                                    headerClassName: "hd_actions",
                                                    className: "hd_actions td_actions",
                                                }
                                            ]}
                                            data={tableData}
                                            pageSizeOptions={[limit]}
                                            defaultPageSize={limit}
                                            minRows={0}
                                            showPaginationTop={true}
                                            showPaginationBottom={false}
                                            showPagination={false}
                                            className={"-striped -highlight "+classes.signupRequestsTable}
                                        />
                                    </>
                            }
                            <div className={classes.loadMore}>
                                {
                                    loadingMore === false && tableData.length > 0 ?
                                        <>
                                            <Button color="primary" disabled={(checked.length === 0)} round onClick={(e) => this.deleteSelected()}>
                                                <Close className={classes.icons} />Delete Selected
                                            </Button>
                                            {
                                                user.level_id === 1 ?
                                                    <Button color="primary" round onClick={(e) => this.deleteAll()}>
                                                        <Close className={classes.icons} />Delete All
                                                    </Button>
                                                :
                                                <></>
                                            }
                                        </>
                                    :
                                    <></>
                                }
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="primary" id="contentViewMore" round onClick={(e) => this.loadSignupRequests(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <DeleteFileModal deleteAll={deleteAll} open={deleteFileModal} onClose={() => this.onModalClose(false)} onSuccess={() => this.onModalSuccess()} />
                {
                    requestModal ?
                        <ConfirmationModal 
                            open={requestModal} 
                            saving={requesting} 
                            onClose={() => this.onSignupRequestUpdate(false)} 
                            onSuccess={() => this.onSignupRequestSuccess()}
                            confirmationMessage={"Are you sure you want to "+(requestAction === 'accept' ? "Accept" : "Reject" )+" Request?"}
                        />
                    :
                        <></>
                }
            </GridContainer>
        );
    }
}

SignupRequests.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(signupRequestsPageStyle)(SignupRequests);
