import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import Close from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteLoginVideoModal from "../components/Home/DeleteLoginVideoModal";
import ReactTable from "react-table";
import VideoIcon from "@material-ui/icons/OndemandVideo";
import EditIcon from "@material-ui/icons/Edit";
import LoginVideoModal from "../components/Home/LoginVideoModal";
import { helper } from '../../assets/js/utils/Element';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import loginVideosPageStyle from "../../assets/jss/loginVideosPageStyle";

class LoginVideos extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        this.loginVideoDetails = {
            type: "",
            title: "",
            video_url: "",
            additionalVideos: [
                {
                    title: "",
                    video_url: ""
                }
            ]
        };

        this.state = {
            allAccountTypes: this.getAccountTypes(true),
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            deleteVideoModal: false,
            limit: 10000000,
            deleteAll: false,
            loginVideoDetailsModal: false,
            loginVideoDetails: this.loginVideoDetails,
            editMode: false,
            snackbarOpen: false,
        };        

        this.appCategories = Api.getAppCategories();
        this.handleToggle = this.handleToggle.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onModalSuccess = this.onModalSuccess.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
        this.copyDownloadLink = this.copyDownloadLink.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);
    }
    componentDidMount(){
        this.loadVideos();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    getAccountTypes(all = false){
        let types = this.getTypes();
        
        let accountTypes = [];
        types.map(type => {
            if(type.id === '-1'){
                return null;
            }
            if(all === true){
                accountTypes.push(type);
            }else{
                if(!this.state.typesArray.includes(type.id)){
                    accountTypes.push(type);
                }
            }
            return null;
        });

        if(all === true){
            return accountTypes;
        }
        
        let stateObj = {};
        stateObj['accountTypes'] = accountTypes;

        this.setState(stateObj)
    }
    getTypes(){
        return [
            {
                id: 5,
                label: "Limited Access Users ",
            },
            {
                id: 2,
                label: "Standard / Free",
            },
            {
                id: 3,
                label: "Pro",
            },
            {
                id: 4,
                label: "Business",
            },
            {
                id: 6,
                label: "Pro Lite"
            },
            {
                id: 0,
                label: "Announcement"
            }
        ]
    }
    prepareTypesArray(){
        let types = [];
        this.state.response.map(data => {
            types.push(data.type);

            return null
        });

        this.setState({
            typesArray: types
        }, () => {
            this.getAccountTypes();
        });
    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    loadVideos(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 200,
        };
        Api.getLoginVideos(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });

            this.prepareTypesArray();
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            editMode: false
        });
    }
    copyDownloadLink(link){
        helper.copyTextToClipboard(link);
        this.setState({snackbarOpen: true});
    }
    closeSnackbar(){
        this.setState({snackbarOpen: false});
    }
    getType(type){
        let typeName = "";
        this.state.allAccountTypes.map((item) => {
            if(item.id === type){
                typeName = item.label;
            }
            return null;
        });

        return typeName;
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(video => {
            let videoArray = {
                id: video.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(video.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(video.id)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                type: this.getType(video.type),
                title: video.title,
                video_url: video.video_url,
                created_at: video.created_at,
                actions: (
                    <>
                        <Button justIcon size="sm" color="info" onClick={() => this.onEditModal(true, video)}>
                            <EditIcon />
                        </Button>
                        <Button justIcon size="sm" color="danger" onClick={() => this.deleteFile(video)}>
                            <Close />
                        </Button>
                    </>
                )
            };
            tableData.push(videoArray);
            return null;
        });
        return tableData;
    }
    deleteSelected(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        this.setState({deleteVideoModal: true});
    }
    deleteAll(){
        this.setState({deleteVideoModal: true, deleteAll: true});
    }
    deleteFile(file){
        let checked = [];
        checked.push(file.id);
        this.setState({deleteVideoModal: true, checked: checked});
    }
    onModalClose(status = true){
        let checked = this.state.checked;
        if(status === false){
            checked = [];
        }
        this.setState({
            deleteVideoModal: status,
            checked: checked,
            deleteAll: false
        });
    }
    onModalSuccess(){
        const { checked, response, deleteAll } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            loginVideos: checked,
            clean: (deleteAll ? 1: 0)
        };
        Api.deleteLoginVideo(requestData, source).then(data => {
            //Handle Success;
        }).catch(err => {
            //Handle Error
        });
        if(deleteAll){
            this.setState({
                response: [],
                checked: [],
                page: 1,
                totalPages: 1,
                deleteVideoModal: false,
                deleteAll: false
            });
            return;
        }

        let newResponse = [];
        response.map(file => {
            if(!checked.includes(file.id)){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteVideoModal: false,
            deleteAll: false
        }, () => {
            this.prepareTypesArray();
        });
    }
    onLoginVideoDetailsModal(status = true){
        this.setState({
            loginVideoDetailsModal: status,
            loginVideoDetails: this.loginVideoDetails,
        }, () => {
            if(!status){
                this.loadVideos();
            }
        });
    }
    onAppDetailsModalSuccess(){
        this.loadVideos();
    }
    getCategory(file){
        let category = "";
        this.appCategories.map((item) => {
            if(item.key === file.category){
                category = item.value;
            }
            return null;
        });
        return category;
    }
    onEditModal(status = true, video){
        if(!status){
            this.loadVideos(false);
            return;
        }
        this.setState({
            loginVideoDetailsModal: status,
            loginVideoDetails: {
                id: video.id,
                type: video.type,
                title: video.title,
                video_url: video.video_url,
                additionalVideos: video.hasOwnProperty("additional_videos") ? video.additional_videos : []
            },
            editMode: status
        });
    }
    render() {
        const { classes } = this.props;
        const { loading, page, loadingMore, totalPages, deleteVideoModal, checked, limit, deleteAll, loginVideoDetailsModal, 
            loginVideoDetails, editMode, allAccountTypes, accountTypes } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        const tableData = this.getTableData();

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <VideoIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Login Videos
                                {
                                    loading ?
                                        <></>
                                    :
                                        accountTypes && accountTypes.length > 0 ?
                                            <Button round color="primary" type="button" className={classes.addButton} onClick={() => this.onLoginVideoDetailsModal(true)}>Add Video</Button>
                                        :
                                            <></>
                                }
                            </h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loading ?
                                    <LoaderComponent align="center" />
                                :
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "",
                                                accessor: "check",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_check",
                                                className: "hd_check td_check",
                                            },
                                            {
                                                Header: "Type",
                                                accessor: "type",
                                                headerClassName: "hd_type",
                                                className: "hd_type td_type",
                                            },
                                            {
                                                Header: "Title",
                                                accessor: "title",
                                                headerClassName: "hd_title",
                                                className: "hd_title td_title",
                                            },
                                            {
                                                Header: "Video Url",
                                                accessor: "video_url",
                                                headerClassName: "hd_url",
                                                className: "hd_url td_url",
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_actions",
                                                className: "hd_actions td_actions",
                                            }
                                        ]}
                                        data={tableData}
                                        pageSizeOptions={[limit]}
                                        defaultPageSize={limit}
                                        minRows={0}
                                        showPaginationTop={true}
                                        showPaginationBottom={false}
                                        showPagination={false}
                                        className={"-striped -highlight "+classes.appsTable}
                                    />
                            }
                            <div className={classes.loadMore}>
                                {
                                    loadingMore === false && tableData.length > 0 ?
                                        <>
                                            <Button color="primary" disabled={(checked.length === 0)} round onClick={(e) => this.deleteSelected()}>
                                                <Close className={classes.icons} />Delete Selected
                                            </Button>
                                            {
                                                user.level_id === 1 ?
                                                    <Button color="primary" round onClick={(e) => this.deleteAll()}>
                                                        <Close className={classes.icons} />Delete All
                                                    </Button>
                                                :
                                                <></>
                                            }
                                        </>
                                    :
                                    <></>
                                }
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="primary" id="contentViewMore" round onClick={(e) => this.loadVideos(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <DeleteLoginVideoModal deleteAll={deleteAll} open={deleteVideoModal} onClose={() => this.onModalClose(false)} onSuccess={() => this.onModalSuccess()} />
                {
                    loginVideoDetailsModal ?
                        <LoginVideoModal allAccountTypes={allAccountTypes} accountTypes={accountTypes} open={loginVideoDetailsModal} editMode={editMode} loginVideoDetails={loginVideoDetails} onClose={() => this.onLoginVideoDetailsModal(false)} onSuccess={() => this.onAppDetailsModalSuccess()} />
                    :
                    <></>
                }
            </GridContainer>
        );
    }
}

LoginVideos.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(loginVideosPageStyle)(LoginVideos);
