import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import { helper } from "../../assets/js/utils/Element";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import Close from "@material-ui/icons/Close";
import AccountIcon from "@material-ui/icons/AccountBox";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteUserModal from "../components/Home/DeleteUserModal";
import ReactTable from "react-table";
import CustomInput from "../components/CustomInput/CustomInput";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import accountsPageStyle from "../../assets/jss/accountsPageStyle";

class DeletedAccounts extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            deleteUserModal: false,
            limit: 20,
            deleteAll: false,
            values: {
                email: "",
                firstName: "",
                lastName: "",
                quota: "",
            },
            editMode: false,
            editPackageDetail: {},
            editPackageModal: false,
            businessInfoUserId: null,
            paymentModal: false,
            paymentModalLoading: false,
            user: null,
            errorMessage: null,
            restoreModal: false
        };
        this.handleToggle = this.handleToggle.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onModalSuccess = this.onModalSuccess.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        this.loadUsers();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    loadUsers(viewMore = false){
        const source = axios.CancelToken.source();
        const type = this.props.type;
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        let requestData = Object.assign({}, this.state.values);
        requestData['page'] = page;
        requestData['limit'] = 20;
        requestData['type'] = type;
        Api.getDeletedUsers(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
                limit: response.length
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked } = this.state;
        
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(user => {
            let userArray = {
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(user.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(user.id)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                company: "",
                created_at: user.created_at,
                orignalQuota: user.quota,
                quota: helper.getFormatedSize(user.quota),
                actions: (
                    <>
                        <Button justIcon size="sm" color="danger" onClick={() => this.deleteUser(user)}>
                            <Close />
                        </Button>
                    </>
                )
            };
            tableData.push(userArray);
            return null;
        });
        return tableData;
    }
    deleteSelected(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        this.setState({deleteUserModal: true});
    }
    deleteAll(){
        this.setState({deleteUserModal: true, deleteAll: true});
    }
    deleteUser(user){
        let checked = [];
        checked.push(user.id);
        this.setState({deleteUserModal: true, checked: checked});
    }
    onModalClose(deleteUserModal = true){
        let checked = this.state.checked;
        if(deleteUserModal === false){
            checked = [];
        }
        this.setState({
            deleteUserModal: deleteUserModal,
            checked: checked,
            deleteAll: false
        });
    }
    onModalSuccess(){
        const { checked, response, deleteAll } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            users: checked,
            clean: (deleteAll ? 1: 0)
        };
        Api.deleteDeletedUsers(requestData, source).then(data => {
            if(deleteAll){
                this.loadUsers();
            }
        }).catch(err => {
            //Handle Error
        });
        if(deleteAll){
            this.setState({
                loading: true, 
                showError: false, 
                checked: [],
                page: 1,
                totalPages: 1,
                deleteUserModal: false,
                deleteAll: false
            });
            return;
        }

        let newResponse = [];
        response.map(file => {
            if(!checked.includes(file.id)){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteUserModal: false,
            deleteAll: false
        });
    }
    handleChange(event, name) {
        this.setState({
            values: {
                ...this.state.values,
                [name]: event.target.value
            }
        });
    }
    handleSubmit(e){
        e.preventDefault();
        this.loadUsers();
    }
    render() {
        const { classes } = this.props;
        const { loading, page, loadingMore, totalPages, deleteUserModal, checked, limit, deleteAll, values } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        const tableData = this.getTableData();
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <AccountIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Deleted Accounts
                            </h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loading ?
                                    <LoaderComponent align="center" />
                                :
                                    <>
                                        <div>
                                            <form onSubmit={this.handleSubmit} className={classes.form} noValidate autoComplete="off">
                                                <CustomInput
                                                    labelText="Email"
                                                    inputProps={{
                                                        value: values.email,
                                                        onChange: (e) => this.handleChange(e,"email"),
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText="First Name"
                                                    inputProps={{
                                                        value: values.firstName,
                                                        onChange: (e) => this.handleChange(e,"firstName"),
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText="Last Name"
                                                    inputProps={{
                                                        value: values.lastName,
                                                        onChange: (e) => this.handleChange(e,"lastName"),
                                                    }}
                                                />
                                                <Button round color="primary" type="submit">Search</Button>
                                            </form>
                                        </div>
                                        <ReactTable
                                            columns={[
                                                {
                                                    Header: "",
                                                    accessor: "check",
                                                    sortable: false,
                                                    filterable: false,
                                                    headerClassName: "hd_check",
                                                    className: "hd_check td_check",
                                                    width: 40,
                                                },
                                                {
                                                    Header: "Email",
                                                    accessor: "email",
                                                    width: 190,
                                                },
                                                {
                                                    Header: "First Name",
                                                    accessor: "first_name"
                                                },
                                                {
                                                    Header: "Last Name",
                                                    accessor: "last_name"
                                                },
                                                {
                                                    Header: "Company*",
                                                    accessor: "company"
                                                },
                                                {
                                                    Header: "Deleted Date",
                                                    accessor: "created_at",
                                                    sortMethod: (a, b) => {
                                                        var a1 = new Date(a).getTime();
                                                        var b1 = new Date(b).getTime();
                                                      if(a1<b1)
                                                        return 1;
                                                      else if(a1>b1)
                                                        return -1;
                                                      else
                                                        return 0;
                                                    }
                                                },
                                                {
                                                    Header: "Quota",
                                                    accessor: "orignalQuota",
                                                    headerClassName: "hd_quota",
                                                    className: "hd_quota td_quota td_hide",
                                                    width: 100
                                                },
                                                {
                                                    Header: "Quota",
                                                    accessor: "quota",
                                                    headerClassName: "hd_quota hd_hide",
                                                    className: "hd_quota td_quota",
                                                    width: 100
                                                },
                                                {
                                                    Header: "Actions",
                                                    accessor: "actions",
                                                    sortable: false,
                                                    filterable: false,
                                                    width: 60,
                                                }
                                            ]}
                                            data={tableData}
                                            // pageSizeOptions={[10, 20, 30, 40, 50, 100, 200]}
                                            // defaultPageSize={limit}
                                            pageSizeOptions={[limit]}
                                            defaultPageSize={100000}
                                            minRows={0}
                                            showPaginationTop={true}
                                            showPaginationBottom={false}
                                            showPagination={false}
                                            className={"-striped -highlight "+(classes.reactTable)}
                                        />
                                    </>
                            }
                            <div className={classes.loadMore}>
                                {
                                    loadingMore === false && tableData.length > 0 ?
                                        <>
                                            {
                                                user.level_id === 1 ?
                                                    <>
                                                        <Button color="primary" disabled={(checked.length === 0)} round onClick={(e) => this.deleteSelected()}>
                                                            <Close className={classes.icons} />Delete Selected
                                                        </Button>
                                                        <Button color="primary" round onClick={(e) => this.deleteAll()}>
                                                            <Close className={classes.icons} />Delete All
                                                        </Button>
                                                    </>
                                                :
                                                <></>
                                            }
                                        </>
                                    :
                                    <></>
                                }
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="primary" id="contentViewMore" round onClick={(e) => this.loadUsers(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <DeleteUserModal deleteAll={deleteAll} open={deleteUserModal} onClose={() => this.onModalClose(false)} onSuccess={() => this.onModalSuccess()} />
            </GridContainer>
        );
    }
}

DeletedAccounts.defaultProps = {
    type: "signup"
}
DeletedAccounts.propTypes = {
    classes: PropTypes.object.isRequired,
    type: PropTypes.string
};

export default withStyles(accountsPageStyle)(DeletedAccounts);
