import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";

import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import { helper } from '../../../assets/js/utils/Element';
import CustomFileUpload from "../CustomFileUpload/CustomFileUpload.jsx";
import CustomInput from "../CustomInput/CustomInput.jsx";
import CustomSelect from "../CustomInput/CustomSelect.jsx";
import Danger from "../../components/Typography/Danger";
import GridItem from "../../components/Grid/GridItem";

import deleteicon from "../../../assets/img/deleteicon.png";
import fileicon from "../../../assets/img/fileicon.png";
import foldericon from "../../../assets/img/foldericon.png";
import successGif from '../../../assets/img/success.gif';
import MaterialIcon from "@mdi/react";
import { mdiArrowExpandUp } from '@mdi/js';



import uploadFileModalStyle from "../../../assets/jss/home/uploadFileModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const UploadFileModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.editor = React.createRef();
        this.state = {
            emailTo: "",
            recipients: [],
            yourEmail: "",
            message: "",
            transferType: "link",
            category: "1",
            credit: "",
            showTransfer: false,
            files: [],
            scrollTo: 0,
            validation: {
                emailTo: "",
                yourEmail: ""                
            },
            uploading: false,
            response: null,
            success: false,
            cancelToken: null,
            totalSize: 0,
            totalSent: 0,
            singleSent: 0,
            snackbarOpen: false,
            totalFiles: 0,
            showError: false,
            errorMessage: "",
            chunkSize: (1048576 * 15), //15 MB by default
            path: this.props.path,
            zipUpload: true,
            zipName: "SL_"+this.getDateString()+".zip",
        };

        this.backgroundCategories = Api.getCategories();

        this.partNumber = 0;
        this.chunksSent = 0;
        this.onFilesSelect = this.onFilesSelect.bind(this);
        this.sendFiles = this.sendFiles.bind(this);
        this.cancelUpload = this.cancelUpload.bind(this);
        this.sendAnother = this.sendAnother.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
        this.onFileDelete = this.onFileDelete.bind(this);
    }
    getDateString(){
        const date = new Date();
        const dateString = (date.getMonth() + 1)+"_"+date.getDate()+"_"+date.getFullYear()+"_"+date.getHours()+"_"+date.getMinutes()+"_"+date.getSeconds();
        return dateString;
    }
    onFilesSelect(files){
        this.setState({files: files});
    }
    onScroll(e){
        if(e.target && e.target.scrollTop <= 0){
            this.setState({
                showTransfer: false,
                scrollTo: 0
            });
        }
    }
    sendFiles(){
        const requestData = {
            category: this.state.category,
            credit: this.state.credit
        };

        const source = axios.CancelToken.source();
        this.setState({
            uploading: true,
            recipients: [],
            emailTo: "",
            totalFiles: this.getFilesCount(),
            cancelToken: source,
            showError: false,
            totalSize: this.getTotalSize(),
        });

        const that = this;
        Api.uploadImages(requestData, this.state.files, source, true).then(data => {
            that.setState({
                response: data,
            });
            that.uploadFiles(data.images);
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    cancelUpload(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
        
        this.setState({
            uploading: false,
            cancelToken: null,
            totalSent: 0,
            singleSent: 0,
            showError: false,
            errorMessage: ""
        });
    }
    sendAnother(){
        this.setState({
            success: false,
            files: []
        });
    }
    getTotalSize(){
        let totalSize = 0;
        this.state.files.map(item => {
            if(item.type === "folder"){
                item.files.map(file => {
                    totalSize += file.size;
                    return null;
                });
            }else{
                totalSize += item.file.size;
                return false;
            }
            return null;
        });
        return totalSize;
    }
    getFilesCount(){
        let totalFiles = 0;
        this.state.files.map(item => {
            if(item.type === "folder"){
                totalFiles += item.files.length;
            }else{
                totalFiles++;
            }
            return null;
        });
        return totalFiles;
    }
    getRemainingSize(){
        let totalSize = 0;
        const maxSize = 2147483648;
        this.state.files.map(item => {
            if(item.type === "folder"){
                item.files.map(file => {
                    totalSize += file.size;
                    return null;
                });
            }else{
                totalSize += item.file.size;
                return false;
            }
            return null;
        });
        return maxSize - totalSize;
    }
    uploadFiles(files, key = 0){
        const fileData = this.getNextFile(files, key);
        const that = this;
        if(fileData === null){
            this.setState({
                uploading: false,
                success: true
            });
            return;
        }

        const source = axios.CancelToken.source();
        that.setState({
            cancelToken: source,
            showError: false,
            errorMessage: ""
        });
        Api.uploadMedia(fileData.localFile, fileData.uploadedFile, source, this.onUploadProgress).then(data => {
            const totalSent = that.state.totalSent + fileData.localFile.size;
            that.setState({
                totalSent: totalSent,
                singleSent: 0,
            });
            that.uploadFiles(files, ++key);
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    getNextFile(files, key){
        if(!files.hasOwnProperty(key)){
            return null;
        }

        const uploadedFile = files[key];
        let localFile = null;

        this.state.files.map(item => {
            if(item.type === "folder"){
                item.files.map(file => {
                    if(file.webkitRelativePath === uploadedFile.path+"/"+uploadedFile.name){
                        localFile = file;
                        return false;
                    }
                    return null;
                });
            }else if(item.file.name === uploadedFile.name){
                localFile = item.file;
                return false;
            }
            return null;
        });
        const fileObject = {
            localFile: localFile,
            uploadedFile: uploadedFile
        }
        return fileObject;
    }
    onFileDelete(key){
        let filesArray = this.state.files;
        filesArray.splice(key, 1);
        this.onFilesSelect(filesArray);
        this.setState({
            files: filesArray
        });
    }
    onUploadProgress(e){
        this.setState({
            singleSent: e.loaded
        });
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    renderFile(item, key){
        let  folderSize = 0;
        if(item.type === "folder"){
            item.files.map(file => {
                return folderSize += file.size;
            });
        }
        if(item.type === "file"){
            return <li key={key}>
                <div className="file">
                    <div className="file-header">
                        <div className="file-title">
                            <span className="file-icon icon"><img src={fileicon} alt={"file"} /></span>
                            <span className="file-name">{item.file.name}</span>
                            <span className="file-detail">{helper.getFormatedSize(item.file.size)}</span>
                        </div>
                        <div className="file-actions">
                            <div className="filelist-action filelist-action-delete">
                                <img src={deleteicon} alt={"delete"} onClick={() => this.onFileDelete(key)} />
                            </div>
                        </div>
                    </div>
                </div>
            </li>;
        }
        return <li key={key}>
            <div className="file">
                <div className="file-header">
                    <div className="file-title">
                        <span className="folder-icon icon"><img src={foldericon} alt={"folder"} /></span>
                        <span className="file-name">{item.name} ({item.files.length} items)</span>
                        <span className="file-detail">{helper.getFormatedSize(folderSize)}</span>
                    </div>
                    <div className="fileactions">
                        <div className="filelist-action filelist-action-delete">
                            <img src={deleteicon} alt={"delete"} onClick={() => this.onFileDelete(key)} />
                        </div>
                    </div>
                </div>
            </div>
        </li>;
    }
    renderForm(){
        const { files } = this.state;
        const { classes } = this.props;
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.formFiles+" uploader-files"}>
                        <CustomFileUpload
                            id="outlined-fileUpload"
                            multiple={true}
                            onChange={(files) => this.onFilesSelect(files)}
                            files={files}
                            allowFolder={false}
                            accept="image/*"
                        />
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customSelectFormControl
                            }}
                            labelText="Background Category"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"category"),
                                value: this.state.category
                            }}
                            inputProps={{
                                name: "category",
                                id: "input-category",
                                className: classes.alignLeft
                            }}
                            color="info"
                            items={this.backgroundCategories}
                            itemLabel="value"
                            itemValue="key"
                        />
                        <CustomInput
                            id="input-credit"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'credit'),
                                value: this.state.credit,
                                name: "credit",
                                type: "text",
                                placeholder:"Credit Artist"
                            }}                                    
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customSelectFormControl
                            }}
                        />
                    </div>
                    <div className={classes.transferFooter+" "+classes.fileSendingFormFooter+" "+(files.length <= 0 ? classes.disableFooter : '')}>
                        <Button onClick={this.sendFiles} type="button" color="info" disabled={(files.length === 0)}>
                            <MaterialIcon path={mdiArrowExpandUp} className="MuiSvgIcon-root" />
                            Upload
                        </Button>
                    </div>
                    {
                        files.length > 0 ?
                            <div className={classes.uploaderList+" uploader-list"}>
                                <ul className={classes.filesList}>
                                    {
                                        files.map((item, key) => (
                                            this.renderFile(item, key)
                                        ))
                                    }
                                </ul>
                            </div>
                        :
                            <></>
                    }
                </div>
            </div>
        )
    }
    renderUploading(){
        const { transferType, recipients, totalSize, totalSent, singleSent, totalFiles, showError, errorMessage } = this.state;
        const { classes } = this.props;
        const totalUploaded = totalSent + singleSent;
        let progress = 0;
        let progressOffset = 502.655;
        if(totalSize > 0){
            progress = (totalUploaded/totalSize)*100;
            if(progress > 0){
                progressOffset = ((100 - progress)/100)*progressOffset;
            }
        }
        const roundedValue = Math.round(progress);
        return (
            <div className={classes.uploadContainer}>
                <div className={classes.uploadingProgress}>
                    <div className={classes.uploadingContent}>
                        <div className="transfer_loader">
                            <svg height="170" width="170" shapeRendering="geometricPrecision" viewBox="0 0 170 170">
                                <circle className="loader__background" r="80" cx="85" cy="85" fill="transparent"style={{
                                    strokeDasharray: 502.655,
                                    strokeDashoffset: 0,
                                    strokeWidth: 10,
                                    stroke: "rgb(232, 235, 237)"
                                }}></circle>
                                <circle className="loader_foreground" r="80" cx="85" cy="85" fill="transparent" style={{
                                    strokeDasharray: 502.655,
                                    strokeDashoffset: progressOffset,
                                    strokeWidth: 10,
                                    stroke: "rgb(64, 159, 255)"
                                }} ></circle>
                            </svg>
                            <span>{`${roundedValue}%`}</span>
                        </div>
                        {
                            showError ?
                                <GridItem>
                                    <Danger>{errorMessage}</Danger>
                                </GridItem>
                            :
                            <>
                            <h2>Transferring</h2>
                            {
                                transferType === "email" ?
                                    <p className="orangeColor">Sending {totalFiles} files to {recipients.length} recipient(s)</p>
                                :
                                    <p className="orangeColor">Uploading {totalFiles} files and preparing link</p>
                            }
                            
                            <p>{helper.getFormatedSize(totalUploaded)} of {helper.getFormatedSize(totalSize)} uploaded</p>
                            </>
                        }
                        
                    </div>
                    <div className={classes.transferFooter+" "+classes.uploadingProgressFooter}>
                        <Button className="transfer-button" type="button" round onClick={this.cancelUpload} >Cancel</Button>
                    </div>
                </div>
            </div>
        );
    }
    renderSuccess(){
        const { classes } = this.props;
        return (
            <div className={classes.uploadContainer}>
                <div className={classes.uploaderComplete}>
                    <div className={classes.uploaderCompleteContent}>
                        <div className="success-image">
                            <img src={successGif} alt="success"></img>
                        </div>
                        <h2>Upload Complete!</h2>
                    </div>
                    <div className={classes.transferFooter+" "+classes.uploadingProgressFooter}>
                        <Button className="transfer-button" type="button" round onClick={this.sendAnother} >Upload another?</Button>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        const { uploading, success} = this.state;
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                root: classes.modalRoot,
                paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="uploadFile-slide-title"
                aria-describedby="uploadFile-slide-description"
            >
                <DialogTitle
                    id="uploadFile-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h5 className={classes.modalTitle}>Upload Files</h5>
                </DialogTitle>
                <DialogContent
                    id="uploadFile-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            uploading ?
                                this.renderUploading()
                            :
                            success ?
                                this.renderSuccess()
                            :
                            this.renderForm()
                        }
                </DialogContent>      
            </Dialog>
        );
    }
};

UploadFileModal.defaultProps = {
    open: false,
    path: ""
};
UploadFileModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    path: PropTypes.string
};
export default withStyles(uploadFileModalStyle)(UploadFileModal);
