import {
    whiteColor,
    cardTitle
} from "./material-dashboard-pro-react.jsx";

const spamPageStyle = theme => ({
    main: {
        background: whiteColor
    },
    loadMore: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    editor: {
        textAlign: "left",
        minHeight: "350px",
        "& .jodit_toolbar_btn-fullsize, & .jodit_toolbar_btn-image": {
            display: "none !important"
        }
    },
    saveButton: {
        marginTop: theme.spacing(2)
    },
    placeholders: {
        "& p:first-child": {
            marginBottom: "5px"
        }
    },
    formControl: {
        marginBottom: "5px"
    }
});

export default spamPageStyle;
