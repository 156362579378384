import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import Close from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteFileModal from "../components/Home/DeleteFileModal";
import ReactTable from "react-table";
import EditIcon from "@material-ui/icons/Edit";
import DownloadDetailsModal from "../components/Home/DownloadDetailsModal";
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import downloadsPageStyle from "../../assets/jss/downloadsPageStyle";

class Downloads extends React.Component {
    constructor(props){
        super(props);
        
        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            deleteDownloadModal: false,
            limit: 50,
            deleteAll: false,
            downloadDetailsModal: false,
            downloadDetails: this.defaultDownloadDetails(),
            editMode: false
        };        

        this.appCategories = Api.getAppCategories();
        this.handleToggle = this.handleToggle.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onModalSuccess = this.onModalSuccess.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
    }
    componentDidMount(){
        this.loadDownloads();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    defaultDownloadDetails(){
        let defaultDownloadDetails = {
            category: "",
            order: "",
            photo: null,
            title: "",
            description: "",
            windows: false,
            windowsUrl: "",
            mac: false,
            macUrl: "",
        };

        return defaultDownloadDetails;
    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    loadDownloads(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 200,
        };
        Api.getDownloads(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            downloadDetailsModal: false,
            editMode: false
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(download => {
            let downloadArray = {
                id: download.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(download.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(download.id)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                photo: (
                    <a href={download.thumbnail_url} target="_blank" rel="noopener noreferrer">
                        <img className={classes.imagePreview} src={download.thumbnail_url} alt={"app-thumb"} />
                    </a>
                ),
                category: this.getCategory(download),
                order: download.order,
                title: download.title,
                description: download.description.substr(0,110).trim()+"...",
                windowsUrl: download.windows_url,
                macUrl: download.mac_url,
                actions: (
                    <>
                        <Button justIcon size="sm" color="info" onClick={() => this.onEditModal(true, download)}>
                            <EditIcon />
                        </Button>
                        <Button justIcon size="sm" color="danger" onClick={() => this.deleteDownload(download)}>
                            <Close />
                        </Button>
                    </>
                )
            };
            tableData.push(downloadArray);
            return null;
        });
        return tableData;
    }
    deleteSelected(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        this.setState({deleteDownloadModal: true});
    }
    deleteAll(){
        this.setState({deleteDownloadModal: true, deleteAll: true});
    }
    deleteDownload(download){
        let checked = [];
        checked.push(download.id);
        this.setState({deleteDownloadModal: true, checked: checked});
    }
    onModalClose(deleteDownloadModal = true){
        let checked = this.state.checked;
        if(deleteDownloadModal === false){
            checked = [];
        }
        this.setState({
            deleteDownloadModal: deleteDownloadModal,
            checked: checked,
            deleteAll: false
        });
    }
    onModalSuccess(){
        const { checked, response, deleteAll } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            downloads: checked,
            clean: (deleteAll ? 1: 0)
        };
        Api.deleteDownloads(requestData, source).then(data => {
            //Handle Success;
        }).catch(err => {
            //Handle Error
        });
        if(deleteAll){
            this.setState({
                response: [],
                checked: [],
                page: 1,
                totalPages: 1,
                deleteDownloadModal: false,
                deleteAll: false
            });
            return;
        }

        let newResponse = [];
        response.map(download => {
            if(!checked.includes(download.id)){
                newResponse.push(download);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteDownloadModal: false,
            deleteAll: false
        });
    }
    onDownloadDetailsModalClose(status = true){
        if(status === false){
            this.loadDownloads();
            return;
        }
        this.setState({
            downloadDetailsModal: status,
            downloadDetails: this.defaultDownloadDetails()
        });
    }
    onDownloadDetailsModalSuccess(){
        this.loadDownloads();
    }
    getCategory(download){
        let category = "";
        this.appCategories.map((item) => {
            if(item.key === download.category){
                category = item.value;
            }
            return null;
        });
        return category;
    }
    onEditModal(status = true, download){
        if(!status){
            this.loadDownloads(false);
            return;
        }
        this.setState({
            downloadDetailsModal: status,
            downloadDetails: {
                id: download.id,
                category: download.category,
                order: download.order,
                title: download.title,
                description: download.description,
                photo: null,
                windows: download.windows,
                windowsUrl: download.windows_url,
                mac: download.mac,
                macUrl: download.mac_url
            },
            editMode: status
        });
    }
    render() {
        const { classes } = this.props;
        const { loading, page, loadingMore, totalPages, deleteDownloadModal, checked, limit, deleteAll, downloadDetailsModal, downloadDetails, editMode } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        const tableData = this.getTableData();
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <DownloadIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Downloads
                                <Button round color="primary" type="button" className={classes.addButton} onClick={() => this.onDownloadDetailsModalClose(true)}>Add Downloads</Button>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loading ?
                                    <LoaderComponent align="center" />
                                :
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                headerClassName: "hd_id",
                                                className: "hd_id td_id",
                                            },
                                            {
                                                Header: "",
                                                accessor: "check",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_check",
                                                className: "hd_check td_check",
                                            },
                                            {
                                                Header: "",
                                                accessor: "photo",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_photo",
                                                className: "hd_photo td_photo",
                                            },
                                            {
                                                Header: "App Build",
                                                accessor: "category",
                                                headerClassName: "hd_category",
                                                className: "hd_category td_category",
                                            },
                                            {
                                                Header: "Order",
                                                accessor: "order",
                                                headerClassName: "hd_order",
                                                className: "hd_order td_order",
                                            },
                                            {
                                                Header: "Title",
                                                accessor: "title",
                                                headerClassName: "hd_title",
                                                className: "hd_title td_title",
                                            },
                                            {
                                                Header: "Description",
                                                accessor: "description",
                                                headerClassName: "hd_description",
                                                className: "hd_description td_description",
                                            },
                                            {
                                                Header: "Windows App Url",
                                                accessor: "windowsUrl",
                                                headerClassName: "hd_windowsUrl",
                                                className: "hd_windowsUrl td_windowsUrl",
                                            },
                                            {
                                                Header: "Mac App Url",
                                                accessor: "macUrl",
                                                headerClassName: "hd_macUrl",
                                                className: "hd_macUrl td_macUrl",
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_actions",
                                                className: "hd_actions td_actions",
                                            }
                                        ]}
                                        data={tableData}
                                        pageSizeOptions={[50, 100, 200]}
                                        defaultPageSize={limit}
                                        showPaginationTop
                                        minRows={0}
                                        showPaginationBottom={false}
                                        className={"-striped -highlight "+classes.downloadsTable}
                                    />
                            }
                            <div className={classes.loadMore}>
                                {
                                    loadingMore === false && tableData.length > 0 ?
                                        <>
                                            <Button color="primary" disabled={(checked.length === 0)} round onClick={(e) => this.deleteSelected()}>
                                                <Close className={classes.icons} />Delete Selected
                                            </Button>
                                            {
                                                user.level_id === 1 ?
                                                    <Button color="primary" round onClick={(e) => this.deleteAll()}>
                                                        <Close className={classes.icons} />Delete All
                                                    </Button>
                                                :
                                                <></>
                                            }
                                        </>
                                    :
                                    <></>
                                }
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="primary" id="contentViewMore" round onClick={(e) => this.loadDownloads(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <DeleteFileModal deleteAll={deleteAll} open={deleteDownloadModal} onClose={() => this.onModalClose(false)} onSuccess={() => this.onModalSuccess()} />
                {
                    downloadDetailsModal ?
                        <DownloadDetailsModal open={downloadDetailsModal} editMode={editMode} downloadDetails={downloadDetails} onClose={() => this.onDownloadDetailsModalClose(false)} onSuccess={() => this.onDownloadDetailsModalSuccess()} />
                    :
                    <></>
                }
            </GridContainer>
        );
    }
}

Downloads.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(downloadsPageStyle)(Downloads);
