import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader';

import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import CustomInput from "../CustomInput/CustomInput.jsx";
import CustomSelect from "../CustomInput/CustomSelect.jsx";
import DatePicker from "../CustomInput/DatePicker";
import CustomFileInput from "../CustomFileInput/CustomFileInput";
import AttachFile from "@material-ui/icons/AttachFile";

import appDetailsModalStyle from "../../../assets/jss/home/appDetailsModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const AppDetailsModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { appDetails } = this.props;
        this.state = {
            category: appDetails.category,
            appDetails: appDetails,
            buildDate: appDetails.buildDate,
            buildVersion: appDetails.buildVersion,
            file: appDetails.file,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                category: "",
                buildDate: "",
                buildVersion: "",
                file: "",
                isValid: false
            },
            uploaded: 0
        };

        this.appCategories = Api.getAppCategories();
        this.updateAppDetails = this.updateAppDetails.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
    }
    updateAppDetails(){
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }

        const { category, buildDate, buildVersion, file, appDetails } = this.state;
        const requestData = {
            category: category,
            buildDate: buildDate,
            buildVersion: buildVersion
        };
        if(file !== null && typeof(file) === "object"){
            requestData['file'] = {
                item_type: 'file',
                size: file.size,
                name: file.name,
                type: file.type
            };
        }
        if(appDetails.hasOwnProperty("id")){
            requestData['id'] = appDetails.id;
        }

        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.updateAppDetails(requestData, file, source, this.onUploadProgress).then(data => {
            that.setState({
                success: true,
                saving: false
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    saving: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    handleDateChange(date, name){
        let state = {};
        state[name] = date.toISOString();
        this.setState(state);
    }
    handleFileChange(e, name){
        let state = {};
        state[name] = e.target.files[0];
        this.setState(state);
    }
    validateForm(){
        let validation = {
            category: "success",
            buildDate: "success",
            buildVersion: "success",
            file: "success",
            isValid: true
        };
        const { category, buildDate, buildVersion, file } = this.state;
        if(category.length <= 0){
            validation.category = "error";
            validation.isValid = false;
        }
        if(buildDate.length <= 2){
            validation.buildDate = "error";
            validation.isValid = false;
        }
        if(buildVersion.length <= 0){
            validation.buildVersion = "error";
            validation.isValid = false;
        }

        if(file === null || (typeof(file) === "object" && file.size <= 0)){
            validation.file = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    onUploadProgress(e){
        this.setState({
            uploaded: e.loaded
        });
    }
    renderForm(){
        const { saving, buildDate, buildVersion, file, validation } = this.state;
        const { classes } = this.props;
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.formFiles+" uploader-files"}>
                        <CustomSelect
                            success={validation.category === "success"}
                            error={validation.category === "error"}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customSelectFormControl
                            }}
                            labelText="Category"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"category"),
                                value: this.state.category
                            }}
                            inputProps={{
                                name: "category",
                                id: "input-category",
                                className: classes.alignLeft
                            }}
                            color="info"
                            items={this.appCategories}
                            itemLabel="value"
                            itemValue="key"
                        />
                        <DatePicker
                            success={validation.buildDate === "success"}
                            error={validation.buildDate === "error"}
                            labelText="Build Date"
                            id="input-buildDate"
                            value={buildDate}
                            color="info"
                            onChange={(date) => this.handleDateChange(date, 'buildDate')}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.datePicker
                            }}
                        />
                        <CustomInput
                            success={validation.buildVersion === "success"}
                            error={validation.buildVersion === "error"}
                            id="input-buildVersion"
                            labelText="Build Version"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'buildVersion'),
                                value: buildVersion,
                                name: "buildVersion",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <CustomFileInput
                            success={validation.file === "success"}
                            error={validation.file === "error"}
                            id="input-file"                            
                            onChange={(e) => this.handleFileChange(e,'file')}
                            endButton={{
                                buttonProps: {
                                  round: true,
                                  color: "info",
                                  justIcon: true,
                                  fileButton: true
                                },
                                icon: <AttachFile />
                            }}
                            inputProps={{
                                value: file,
                                placeholder: "Build File"
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </div>
                    <div className={classes.transferFooter+" "+classes.fileSendingFormFooter}>
                        <Button onClick={this.updateAppDetails} type="button" color="info">
                            {
                                saving ?
                                    <LoaderComponent align="left" saving={true} color="white" />
                                :
                                    "Save Changes"
                            }
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { success } = this.state;
        const { classes, editMode } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="appDetails-slide-title"
                aria-describedby="appDetails-slide-description"
            >
                <DialogTitle
                    id="appDetails-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h5 className={classes.modalTitle}>
                        {
                            editMode ?
                                "Edit Apps Meta Data"
                            :
                            "Add Apps Meta Data"
                        }
                    </h5>
                </DialogTitle>
                <DialogContent
                    id="appDetails-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            success ?
                                <>
                                    <p className={classes.successMessage}>Changes saved successfully.</p>
                                    <Button
                                        color="info"
                                        onClick={() => this.props.onClose()}
                                    >
                                        Close
                                    </Button>
                                </>
                            :
                            this.renderForm()
                        }
                </DialogContent>      
            </Dialog>
        );
    }
};

AppDetailsModal.defaultProps = {
    open: false,
    editMode: false,
};
AppDetailsModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    appDetails: PropTypes.object.isRequired,
    editMode: PropTypes.bool
};
export default withStyles(appDetailsModalStyle)(AppDetailsModal);
