import {
    whiteColor,
    cardTitle
} from "./material-dashboard-pro-react.jsx";

const themeSettingsPageStyle = theme => ({
    main: {
        background: whiteColor
    },
    loadMore: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    editor: {
        textAlign: "left",
        minHeight: "350px",
        "& .jodit_toolbar_btn-fullsize, & .jodit_toolbar_btn-image": {
            display: "none !important"
        }
    },
    saveButton: {
        marginTop: theme.spacing(2)
    },
    placeholders: {
        "& p:first-child": {
            marginBottom: "5px"
        }
    },
    formControl: {
        marginBottom: "5px"
    },
    colorItem: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: "4px",
        marginBottom: "15px",
        "& p": {
            marginBottom: "0px",
            marginRight: "10px"
        },
        "& .colorBox": {
            width: "24px",
            height: "24px",
            borderRadius: "4px",
            cursor: "pointer",
            boxShadow: "1px 1px 2px rgb(0 0 0)"
        }
    }
});

export default themeSettingsPageStyle;
