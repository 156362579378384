/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import footerStyle from "../../../assets/jss/material-dashboard-pro-react/components/footerStyle";

function Footer({ ...props }) {
    const { classes, fluid, white, rtlActive } = props;
    var container = cx({
        [classes.container]: !fluid,
        [classes.containerFluid]: fluid,
        [classes.whiteColor]: white
    });
    var anchor =
        classes.a +
        cx({
        [" " + classes.whiteColor]: white
        });
    var block = cx({
        [classes.block]: true,
        [classes.whiteColor]: white
    });
    return (
        <footer className={classes.footer}>
            <div className={container}>
                <p className={classes.left}>
                &copy; {new Date().getFullYear()}{" "}
                <a
                    href="http://sendlinx.com/"
                    className={anchor}
                    target="_blank"
                >
                    {rtlActive ? "توقيت الإبداعية" : "Sendlinx"}
                </a>
                </p>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
    fluid: PropTypes.bool,
    white: PropTypes.bool,
    rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
