import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import CustomInput from "../CustomInput/CustomInput.jsx";
import DateTimePicker from "../CustomInput/DateTimePicker";

import promoCodeModalStyle from "../../../assets/jss/home/promoCodeModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const PromoCodeModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { promoCodeDetails } = this.props;
        this.state = {
            promoCodeDetails: promoCodeDetails,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                category: "",
                buildDate: "",
                buildVersion: "",
                file: "",
                isValid: false
            },
            uploaded: 0
        };

        this.appCategories = Api.getAppCategories();
        this.updatePromoCode = this.updatePromoCode.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
    }
    updatePromoCode(){
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }

        const {  promoCodeDetails } = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.updatePromoCode(promoCodeDetails, source).then(data => {
            that.setState({
                success: true,
                saving: false
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    saving: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    handleChange(e, name){
        this.setState({
            promoCodeDetails: {
                ...this.state.promoCodeDetails,
                [name]: e.target.value
            }
        });
    }
    handleDateChange(date, name){
        try{
            if(date === 'Invalid Date'){
                return true;
            }
            
            this.setState({
                promoCodeDetails: {
                    ...this.state.promoCodeDetails,
                    [name]: date.toISOString()
                }
            });
        }catch(e){ console.log(e); } 
    }
    handleCheckbox(e, name){
        this.setState({
            promoCodeDetails: {
                ...this.state.promoCodeDetails,
                [name]: e.target.checked
            }
        });
    }
    validateForm(){
        let validation = {
            title: "success",
            code: "success",
            storage: "success",
            isValid: true
        };
        const { promoCodeDetails } = this.state;
        if(promoCodeDetails.title.length <= 0){
            validation.title = "error";
            validation.isValid = false;
        }
        if(promoCodeDetails.code.length <= 2){
            validation.code = "error";
            validation.isValid = false;
        }
        if(promoCodeDetails.storage.length <= 0){
            validation.storage = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    onUploadProgress(e){
        this.setState({
            uploaded: e.loaded
        });
    }
    renderForm(){
        const { saving, promoCodeDetails, validation } = this.state;
        const { classes } = this.props;
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.formFiles+" uploader-files"}>
                        <CustomInput
                            success={validation.title === "success"}
                            error={validation.title === "error"}
                            id="input-title"
                            labelText="Title"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'title'),
                                value: promoCodeDetails.title,
                                name: "title",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <CustomInput
                            success={validation.code === "success"}
                            error={validation.code === "error"}
                            id="input-code"
                            labelText="Promo Code"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'code'),
                                value: promoCodeDetails.code,
                                name: "code",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <DateTimePicker
                            labelText="Start Date"
                            id="input-start_date"
                            value={promoCodeDetails.start_date}
                            color="info"
                            onChange={(date) => this.handleDateChange(date, 'start_date')}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.datePicker
                            }}
                        />
                        <DateTimePicker
                            labelText="End Date"
                            id="input-end_date"
                            value={promoCodeDetails.end_date}
                            color="info"
                            onChange={(date) => this.handleDateChange(date, 'end_date')}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.datePicker
                            }}
                        />
                        <CustomInput
                            success={validation.storage === "success"}
                            error={validation.storage === "error"}
                            id="input-storage"
                            labelText="Storage Quota (MB)"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'storage'),
                                value: String(promoCodeDetails.storage),
                                name: "storage",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <CustomInput
                            id="input-max_signups"
                            labelText="Maximum Allowed Signups"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'max_signups'),
                                value: String(promoCodeDetails.max_signups),
                                name: "max_signups",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <CustomInput
                            id="input-directory_name"
                            labelText="My Docs Directory"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'directory_name'),
                                value: promoCodeDetails.directory_name ? promoCodeDetails.directory_name : '',
                                name: "directory_name",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <div className={classes.textLeft}>
                            <FormControlLabel
                                classes={{label: classes.label}}
                                control={
                                    <Checkbox
                                        tabIndex={-1}
                                        onClick={(e) => this.handleCheckbox(e, "status")}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                        checked={promoCodeDetails.status === 1 ? true : false}
                                    />
                                }
                                label={
                                    <span>Enabled</span>
                                }
                            />
                        </div>
                    </div>
                    <div className={classes.transferFooter+" "+classes.fileSendingFormFooter}>
                        <Button onClick={this.updatePromoCode} type="button" color="info">
                            {
                                saving ?
                                    <LoaderComponent align="left" saving={true} color="white" />
                                :
                                    "Save Changes"
                            }
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { success } = this.state;
        const { classes, editMode } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="promoCodeDetails-slide-title"
                aria-describedby="promoCodeDetails-slide-description"
            >
                <DialogTitle
                    id="promoCodeDetails-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h5 className={classes.modalTitle}>
                        {
                            editMode ?
                                "Edit Promo Code"
                            :
                            "Add Promo Code"
                        }
                    </h5>
                </DialogTitle>
                <DialogContent
                    id="promoCodeDetails-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            success ?
                                <>
                                    <p className={classes.successMessage}>Changes saved successfully.</p>
                                    <Button
                                        color="info"
                                        onClick={() => this.props.onClose()}
                                    >
                                        Close
                                    </Button>
                                </>
                            :
                            this.renderForm()
                        }
                </DialogContent>      
            </Dialog>
        );
    }
};

PromoCodeModal.defaultProps = {
    open: false,
    editMode: false,
};
PromoCodeModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    promoCodeDetails: PropTypes.object.isRequired,
    editMode: PropTypes.bool
};
export default withStyles(promoCodeModalStyle)(PromoCodeModal);
