import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import UserTypeModalStyle from "../../../assets/jss/home/UserTypeModalStyle";
import { DialogTitle } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const UserTypeModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            userAccountType: "personal"
        };

        this.handleFinish = this.handleFinish.bind(this);
    }
    handleUserAccountType(name, value){
        this.setState({
            [name]: value
        });
    }
    handleFinish(){
        this.props.onClose(this.state.userAccountType)
    }
    render() {
        const { userAccountType } = this.state;
        const { classes } = this.props;
        
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="addAccount-slide-title"
                aria-describedby="addAccount-slide-description"
            >
                <DialogContent
                    id="addAccount-slide-description"
                    className={classes.modalBody}
                >
                    <DialogTitle
                        id="downloadDetails-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <h2 className={classes.modalTitle}>Account Type</h2>
                    </DialogTitle>
                    <div className={classes.selectAccountType}>
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Radio
                                    checked={(userAccountType === "personal")}
                                    onChange={(e) => this.handleUserAccountType("userAccountType", "personal")}
                                    value={"personal"}
                                    name="userAccountType"
                                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                />
                            }
                            label={(
                                <>
                                    Personal Account
                                    <p>Send and receive file easily and securely with your friends and family.</p>
                                </>
                            )}
                        />
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Radio
                                    checked={(userAccountType === "business")}
                                    onChange={(e) => this.handleUserAccountType("userAccountType", "business")}
                                    value={"business"}
                                    name="userAccountType"
                                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                />
                            }
                            label={(
                                <>
                                    Business Account
                                    <p>Share files with your team mates and customers easily and securely.</p>
                                </>
                            )}
                        />
                        <div className={classes.textCenter+" "+classes.alignButtons}>
                            <Button round color="primary" onClick={() => this.handleFinish()}>Next</Button>
                        </div>
                    </div>
                </DialogContent>   
            </Dialog>
        );
    }
};

UserTypeModal.defaultProps = {
    open: false,
};
UserTypeModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
};
export default withStyles(UserTypeModalStyle)(UserTypeModal);
