import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditIcon from "@material-ui/icons/Edit";
import CustomSelect from "../../CustomInput/CustomSelect.jsx";
import LoaderComponent from '../../Loader';
import CalenderIcon from "@material-ui/icons/DateRange";
import DatePicker from "../../../components/CustomInput/DatePicker";
import Email from "@material-ui/icons/Email";
import axios from "axios";
import Api from "../../../../assets/js/utils/Api";
import CustomInput from "../../CustomInput/CustomInput.jsx";

import addAccountModalStyle from "../../../../assets/jss/home/addAccountModalStyle";


const Step2 = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { businessInfoDetails } = this.props;

        this.state = {
            businessInfoDetails: businessInfoDetails,
            dob: businessInfoDetails.dob,
            occupation: businessInfoDetails.occupation,
            address1: businessInfoDetails.address1,
            address2: businessInfoDetails.address2,
            address3: businessInfoDetails.address3,
            countryId: businessInfoDetails.countryId,
            stateId: businessInfoDetails.stateId,
            cityId: businessInfoDetails.cityId,
            firstName: businessInfoDetails.firstName,
            lastName: businessInfoDetails.lastName,
            email: businessInfoDetails.email,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                occupation: '',
                countryId: '',
                firstName: '',
                lastName: '',
                email: '',
                isValid: false,
            },
            countries: [],
            states: [],
            cities: [],
            loadingCountries: false,
            loadingStates: false,
            loadingCities: false,
        };

        this.occupationOptions = Api.getOccupationOptions();
    }
    componentDidMount(){
        this.loadCountries();
    }
    
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        if(name === "email"){
            state[name] = e.target.value.toLowerCase();
        }
        this.setState(state);
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    isValidated() {
        const isValid = this.validateForm();
        if(!isValid){
            return false;
        }

        return true;
    }
    validateForm(){
        let validation = {
            occupation: 'success',
            countryId: 'success',
            firstName: 'success',
            lastName: 'success',
            email: 'success',
            isValid: true
        };
        
        if(this.state.occupation.length <= 0){
            validation.occupation = "error";
            validation.isValid = false;
        }
        if(this.state.countryId.length <= 0){
            validation.countryId = "error";
            validation.isValid = false;
        }
        if(this.state.firstName.length <= 0){
            validation.firstName = "error";
            validation.isValid = false;
        }
        if(this.state.lastName.length <= 0){
            validation.lastName = "error";
            validation.isValid = false;
        }
        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    sendState() {
        return this.state;
    }
    loadCountries(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingCountries: true,
            countries: []
        });
        Api.getCountries(source).then(data => {
            that.setState({
                countries: data.response,
                loadingCountries: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onCountryChange(e){
        const country = e.target.value;
        this.loadStates(country);
    }
    loadStates(country){
        const { countryId } = this.state;
        if(countryId === country){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingStates: true,
            countryId: country,
            states: [],
            stateId: ""
        });
        Api.getStates(country, source).then(data => {
            that.setState({
                states: data.response,
                loadingStates: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onStateChange(e){
        const state = e.target.value;
        this.loadCities(state);
    }
    loadCities(state){
        const { stateId } = this.state;
        if(stateId === state){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        const { countryId } = this.state;
        that.setState({
            cancelToken: source,
            loadingCities: true,
            stateId: state,
            cities: [],
            cityId: ""
        });
        Api.getCities(countryId, state, source).then(data => {
            that.setState({
                cities: data.response,
                loadingCities: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    renderForm(){
        const { validation, countries, states, cities, countryId, stateId, cityId, firstName, lastName, email,
            loadingCountries, loadingCities, loadingStates } = this.state;
        const { classes } = this.props;
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.addAccount}>
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            success={validation.firstName === "success"}
                            error={validation.firstName === "error"}
                            id="input-firstName"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'firstName'),
                                name: "firstName",
                                value: firstName,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Business Contact First Name"
                            }}
                        />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            success={validation.lastName === "success"}
                            error={validation.lastName === "error"}
                            id="input-lastName"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'lastName'),
                                name: "lastName",
                                value: lastName,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Business Contact Last Name"
                            }}
                        />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            success={validation.email === "success"}
                            error={validation.email === "error"}
                            id="input-email"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'email'),
                                name: "email",
                                value: email,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <Email className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Business Contact Email",
                                type: "email"
                            }}
                        />
                        <DatePicker
                            labelText={null}
                            id="input-dob"
                            value={this.state.dob}
                            disableFuture={true}
                            onChange={(date) => this.handleDateChange(date, 'dob')}
                            inputProps={{
                                placeholder:"Date of Birth",
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <CalenderIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                            }}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                        />
                        <CustomSelect 
                            success={validation.occupation === "success"}
                            error={validation.occupation === "error"}
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="What is Your Occupation?"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"occupation"),
                                value: this.state.occupation
                            }}
                            inputProps={{
                                name: "occupation",
                                id: "input-occupation",
                                className: classes.alignLeft
                            }}
                            items={this.occupationOptions}
                        />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            id="input-address1"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'address1'),
                                name: "address1",
                                value: this.state.address1,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Address1"
                            }}
                        />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            id="input-address2"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'address2'),
                                name: "address2",
                                value: this.state.address2,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Address2"
                            }}
                        />
                        {
                            countries.length > 0 ?
                                <CustomSelect 
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    labelText="Country"
                                    success={validation.countryId === "success"}
                                    error={validation.countryId === "error"}
                                    selectProps={{
                                        onChange: (e) => this.onCountryChange(e),
                                        value: countryId
                                    }}
                                    inputProps={{
                                        name: "countryId",
                                        id: "input-countryId",
                                        className: classes.alignLeft
                                    }}
                                    items={countries}
                                    itemLabel="name"
                                    itemValue="id"
                                />
                            :
                                loadingCountries ?
                                    <LoaderComponent align="left" />
                                :
                                <></>
                        }
                        {
                            states.length > 0 ?
                                <CustomSelect 
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    labelText="State / Province"
                                    success={validation.stateId === "success"}
                                    error={validation.stateId === "error"}
                                    selectProps={{
                                        onChange: (e) => this.onStateChange(e),
                                        value: stateId
                                    }}
                                    inputProps={{
                                        name: "stateId",
                                        id: "input-stateId",
                                        className: classes.alignLeft
                                    }}
                                    items={states}
                                    itemLabel="name"
                                    itemValue="id"
                                />
                            :
                                loadingStates ?
                                    <LoaderComponent align="left" />
                                :
                                <></>
                        }
                        {
                            cities.length > 0 ?
                                <CustomSelect 
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    labelText="City"
                                    selectProps={{
                                        onChange: (e) => this.handleChange(e, "cityId"),
                                        value: cityId
                                    }}
                                    inputProps={{
                                        name: "cityId",
                                        id: "input-cityId",
                                        className: classes.alignLeft
                                    }}
                                    items={cities}
                                    itemLabel="name"
                                    itemValue="id"
                                />
                            :
                                loadingCities ?
                                    <LoaderComponent align="left" />
                                :
                                <></>
                        }
                    </div>
                </div>
            </div>
        )
    }
    render() {
        return (
            <>
                {
                    this.renderForm()
                }
            </>
        );
    }
};

Step2.defaultProps = {
    onMount: function(){ }
};
Step2.propTypes = {
    classes: PropTypes.object,
    onMount: PropTypes.func
};
export default withStyles(addAccountModalStyle)(Step2);
