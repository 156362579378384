import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import CustomInput from "../CustomInput/CustomInput.jsx";
import CustomSelect from "../CustomInput/CustomSelect.jsx";
import CustomFileInput from "../CustomFileInput/CustomFileInput";
import AttachFile from "@material-ui/icons/AttachFile";

import downloadDetailsModalStyle from "../../../assets/jss/home/downloadDetailsModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DownloadDetailsModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { downloadDetails } = this.props;

        let windowsFlag = false;
        let macFlag = false;
        if(downloadDetails.windows === 1){
            windowsFlag = true;
        }
        if(downloadDetails.mac === 1){
            macFlag = true;
        }
        this.state = {
            downloadDetails: downloadDetails,
            category: downloadDetails.category,
            order: String(downloadDetails.order),
            title: downloadDetails.title,
            description: downloadDetails.description,
            photo: downloadDetails.photo,
            windows: windowsFlag,
            mac: macFlag,
            windowsUrl: downloadDetails.windowsUrl,
            macUrl: downloadDetails.macUrl,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                category: "",
                order: "",
                title: "",
                description: "",
                photo: "",
                isValid: false,
            },
            uploaded: 0
        };

        this.appCategories = Api.getAppCategories();
        this.updateDownloadDetails = this.updateDownloadDetails.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
    }
    updateDownloadDetails(){
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }

        const { category, order, title, description, photo, windows, mac, windowsUrl, macUrl, downloadDetails } = this.state;

        let windowsFlag = 0;
        let macFlag = 0;
        if(windows === true){
            windowsFlag = 1;
        }
        if(mac === true){
            macFlag = 1;
        }
        var requestData = new FormData();
        requestData.append('category', category);
        requestData.append('order', order);
        if(photo !== null){
            requestData.append('photo', photo);
        }
        requestData.append('title', title)
        requestData.append('description', description);
        requestData.append('windows', windowsFlag);
        requestData.append('windowsUrl', windowsUrl);
        requestData.append('mac', macFlag);
        requestData.append('macUrl', macUrl);

        let id = null;
        if(downloadDetails.hasOwnProperty("id")){
            requestData.append('id',downloadDetails.id);
            id = downloadDetails.id;
        }

        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.updateDownloadDetails(requestData, id, source, this.onUploadProgress).then(data => {
            that.setState({
                success: true,
                saving: false
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    saving: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    handleFileChange(e, name){
        let state = {};
        state[name] = e.target.files[0];
        this.setState(state);
    }
    validateForm(){
        let validation = {
            category: "success",
            order: "success",
            title: "success",
            description: "success",
            photo: "success",
            isValid: true
        };
        const { category, title, description, photo, order } = this.state;
        const { editMode } = this.props;
        if(category.length <= 0){
            validation.category = "error";
            validation.isValid = false;
        }
        if(order.length <= 0){
            validation.order = "error";
            validation.isValid = false;
        }
        if(title.length <= 0){
            validation.title = "error";
            validation.isValid = false;
        }
        if(description.length <= 0){
            validation.description = "error";
            validation.isValid = false;
        }

        if(!editMode && (photo === null || (typeof(photo) === "object" && photo.size <= 0))){
            validation.photo = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    onUploadProgress(e){
        this.setState({
            uploaded: e.loaded
        });
    }
    renderForm(){
        const { saving, title, description, photo, validation, windows, mac, windowsUrl, macUrl, order } = this.state;
        const { classes } = this.props;
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.formFiles+" uploader-files"}>
                        <CustomSelect
                            success={validation.category === "success"}
                            error={validation.category === "error"}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customSelectFormControl
                            }}
                            labelText="App Build"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"category"),
                                value: this.state.category
                            }}
                            inputProps={{
                                name: "category",
                                id: "input-category",
                                className: classes.alignLeft
                            }}
                            color="info"
                            items={this.appCategories}
                            itemLabel="value"
                            itemValue="key"
                        />
                        <CustomInput
                            success={validation.order === "success"}
                            error={validation.order === "error"}
                            id="input-order"
                            labelText="Order #"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'order'),
                                value: order,
                                name: "order",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <CustomFileInput
                            success={validation.photo === "success"}
                            error={validation.photo === "error"}
                            id="input-photo"                            
                            onChange={(e) => this.handleFileChange(e,'photo')}
                            endButton={{
                                buttonProps: {
                                  round: true,
                                  color: "info",
                                  justIcon: true,
                                  fileButton: true
                                },
                                icon: <AttachFile />
                            }}
                            inputProps={{
                                value: photo,
                                placeholder: "Photo"
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <CustomInput
                            success={validation.title === "success"}
                            error={validation.title === "error"}
                            id="input-title"
                            labelText="Title"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'title'),
                                value: title,
                                name: "title",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <CustomInput
                            success={validation.description === "success"}
                            error={validation.description === "error"}
                            id="input-description"
                            labelText="Description"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'description'),
                                value: description,
                                name: "description",
                                type: "text",
                                multiline: true,
                                rows: 3
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <FormControlLabel
                                classes={{label: classes.label}}
                                control={
                                    <Checkbox                                                
                                        tabIndex={-1}
                                        onClick={(e) => this.handleCheckbox(e, "windows")}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                        checked={windows}
                                    />
                                }
                                label={
                                    <span>Windows app</span>
                                }
                        />
                        <CustomInput
                            success={validation.windowsUrl === "success"}
                            error={validation.windowsUrl === "error"}
                            id="input-windowsUrl"
                            labelText="Windows app url"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'windowsUrl'),
                                value: windowsUrl,
                                name: "windowsUrl",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox                                                
                                    tabIndex={-1}
                                    onClick={(e) => this.handleCheckbox(e, "mac")}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                    checked={mac}
                                />
                            }
                            label={
                                <span>MAC app</span>
                            }
                        />
                        <CustomInput
                            success={validation.macUrl === "success"}
                            error={validation.macUrl === "error"}
                            id="input-macUrl"
                            labelText="MAC app url"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'macUrl'),
                                value: macUrl,
                                name: "macUrl",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </div>
                    <div className={classes.transferFooter+" "+classes.fileSendingFormFooter}>
                        <Button onClick={this.updateDownloadDetails} type="button" color="info">
                            {
                                saving ?
                                    <LoaderComponent align="left" saving={true} color="white" />
                                :
                                    "Save Changes"
                            }
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { success } = this.state;
        const { classes, editMode } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="downloadDetails-slide-title"
                aria-describedby="downloadDetails-slide-description"
            >
                <DialogTitle
                    id="downloadDetails-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h5 className={classes.modalTitle}>
                        {
                            editMode ?
                                "Edit Download"
                            :
                            "Add Download"
                        }
                    </h5>
                </DialogTitle>
                <DialogContent
                    id="downloadDetails-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            success ?
                                <>
                                    <p className={classes.successMessage}>Changes saved successfully.</p>
                                    <Button
                                        color="info"
                                        onClick={() => this.props.onClose()}
                                    >
                                        Close
                                    </Button>
                                </>
                            :
                            this.renderForm()
                        }
                </DialogContent>      
            </Dialog>
        );
    }
};

DownloadDetailsModal.defaultProps = {
    open: false,
    editMode: false,
};
DownloadDetailsModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    downloadDetails: PropTypes.object.isRequired,
    editMode: PropTypes.bool
};
export default withStyles(downloadDetailsModalStyle)(DownloadDetailsModal);
