import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader';

import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import CustomInput from "../CustomInput/CustomInput.jsx";
import CustomSelect from "../CustomInput/CustomSelect.jsx";

import uploadFileModalStyle from "../../../assets/jss/home/uploadFileModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const EditFileDetailsModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { image } = this.props;
        this.state = {
            image: image,
            category: image.category,
            credit: image.credit,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
        };

        this.backgroundCategories = Api.getCategories();
        this.updateImage = this.updateImage.bind(this);
    }
    updateImage(){
        const { image, category, credit } = this.state;
        const requestData = {
            category: category,
            credit: credit
        };

        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.updateImage(requestData, image, source).then(data => {
            that.setState({
                success: true,
                saving: false
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    saving: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    renderForm(){
        const { saving } = this.state;
        const { classes } = this.props;
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.formFiles+" uploader-files"}>
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customSelectFormControl
                            }}
                            labelText="Background Category"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"category"),
                                value: this.state.category
                            }}
                            inputProps={{
                                name: "category",
                                id: "input-category",
                                className: classes.alignLeft
                            }}
                            color="info"
                            items={this.backgroundCategories}
                            itemLabel="value"
                            itemValue="key"
                        />
                        <CustomInput
                            id="input-credit"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'credit'),
                                value: this.state.credit,
                                name: "credit",
                                type: "text",
                                placeholder:"Credit Artist"
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customSelectFormControl
                            }}
                        />
                    </div>
                    <div className={classes.transferFooter+" "+classes.fileSendingFormFooter}>
                        <Button onClick={this.updateImage} type="button" color="info">
                            {
                                saving ?
                                    <LoaderComponent align="left" saving={true} color="white" />
                                :
                                    "Save Changes"
                            }
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { success } = this.state;
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                root: classes.modalRoot,
                paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="updateImage-slide-title"
                aria-describedby="updateImage-slide-description"
            >
                <DialogTitle
                    id="updateImage-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h5 className={classes.modalTitle}>Edit Background Image Details</h5>
                </DialogTitle>
                <DialogContent
                    id="updateImage-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            success ?
                                <>
                                    <p className={classes.successMessage}>Changes saved successfully.</p>
                                    <Button
                                        color="info"
                                        onClick={() => this.props.onClose()}
                                    >
                                        Close
                                    </Button>
                                </>
                            :
                            this.renderForm()
                        }
                </DialogContent>      
            </Dialog>
        );
    }
};

EditFileDetailsModal.defaultProps = {
    open: false,
    path: "",
};
EditFileDetailsModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    path: PropTypes.string,
    image: PropTypes.object.isRequired
};
export default withStyles(uploadFileModalStyle)(EditFileDetailsModal);
