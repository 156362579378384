import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditIcon from "@material-ui/icons/Edit";
import CalenderIcon from "@material-ui/icons/DateRange";
import DatePicker from "../../../components/CustomInput/DatePicker";

import Api from "../../../../assets/js/utils/Api";
import CustomInput from "../../CustomInput/CustomInput.jsx";
import CustomSelect from "../../CustomInput/CustomSelect.jsx";

import addAccountModalStyle from "../../../../assets/jss/home/addAccountModalStyle";

const Step1 = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { businessInfoDetails } = this.props;

        this.state = {
            businessInfoDetails: businessInfoDetails,
            businessType: businessInfoDetails.businessType,
            keywords: businessInfoDetails.keywords,
            employeesCount: businessInfoDetails.employeesCount,
            establishedDate: businessInfoDetails.establishedDate,
            website: businessInfoDetails.website,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                businessType: '',
                keywords: '',
                employeesCount: '',
                isValid: false,
            },
        };

        this.businessTypes = Api.getBusinessTypes();
        this.employeesNo = ['1-5', '6-10', '11-20', '21-50', '51-100', '100+'];
    }
    isValidated() {
        const isValid = this.validateForm();
        if(!isValid){
            return false;
        }

        return true;
    }
    validateForm(){
        let validation = {
            businessType: "success",
            keywords: "success",
            employeesCount: "success",
            isValid: true
        };

        if(this.state.businessType.length <= 0){
            validation.businessType = "error";
            validation.isValid = false;
        }
        if(this.state.keywords.length <= 0){
            validation.keywords = "error";
            validation.isValid = false;
        }
        if(this.state.employeesCount.length <= 0){
            validation.employeesCount = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    sendState() {
        return this.state;
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        if(name === "email"){
            state[name] = e.target.value.toLowerCase();
        }
        this.setState(state);
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    renderForm(){
        const { validation } = this.state;
        const { classes } = this.props;
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.addAccount}>
                        <CustomSelect 
                            success={validation.businessType === "success"}
                            error={validation.businessType === "error"}
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="Business Type"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"businessType"),
                                value: this.state.businessType
                            }}
                            inputProps={{
                                name: "businessType",
                                id: "input-businessType",
                                className: classes.alignLeft
                            }}
                            items={this.businessTypes}
                            itemLabel="label"
                            itemValue="id"
                        />
                        <CustomInput
                            success={validation.keywords === "success"}
                            error={validation.keywords === "error"}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            id="input-keywords"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'keywords'),
                                name: "keywords",
                                value: this.state.keywords,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Product or Service Keywords"
                            }}
                        />
                        <CustomSelect 
                            success={validation.employeesCount === "success"}
                            error={validation.employeesCount === "error"}
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="Number of Employees"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"employeesCount"),
                                value: this.state.employeesCount
                            }}
                            inputProps={{
                                name: "employeesCount",
                                id: "input-employeesCount",
                                className: classes.alignLeft
                            }}
                            items={this.employeesNo}
                        />
                        <DatePicker
                            labelText={null}
                            id="input-establishedDate"
                            value={this.state.establishedDate}
                            disableFuture={true}
                            onChange={(date) => this.handleDateChange(date, 'establishedDate')}
                            inputProps={{
                                placeholder:"Date Business Established",
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <CalenderIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                            }}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                        />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            id="input-website"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'website'),
                                name: "website",
                                value: this.state.website,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Website"
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
    render() {
        return (
            <>
                {
                    this.renderForm()
                }
            </>
        );
    }
};

Step1.defaultProps = {
    onMount: function(){ }
};
Step1.propTypes = {
    classes: PropTypes.object,
    onMount: PropTypes.func
};
export default withStyles(addAccountModalStyle)(Step1);
