import {
    whiteColor,
    cardTitle
} from "./material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "./material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const appsPageStyle = {
    ...customCheckboxRadioSwitchStyle,
    main: {
        background: whiteColor
    },
    loadMore: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    addButton: {
        float: "right",
        marginTop: "0px !important"
    },
    appsTable: {
        "& .rt-thead .rt-th": {
            fontSize: "14px",
            "&.hd_hide": {
                display: 'none'
            }
        },
        "& .rt-tbody .rt-td": {
            fontSize: "12px",
            padding: "0px 5px",
            "&.td_hide": {
                display: "none"
            }
        },
        "& .hd_check": {
            width: "5% !important",
            textAlign: "center !important",
            "& .MuiIconButton-root": {
                padding: "0px"
            }
        },
        "& .hd_category": {
            width: "20% !important",
            textAlign: "left !important",
        },
        "& .hd_build_date": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_build_version": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_name": {
            width: "20% !important",
            textAlign: "left !important",
        },
        "& .hd_size": {
            width: "10% !important",
            textAlign: "left !important",
        },
        "& .hd_actions": {
            width: "10% !important",
            textAlign: "left !important",
        },
    }
};

export default appsPageStyle;
