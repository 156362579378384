import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import CustomInput from "../components/CustomInput/CustomInput";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import BlockIcon from "@material-ui/icons/Block";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import spamPageStyle from "../../assets/jss/spamPageStyle";

class Spam extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.editor = React.createRef();
        this.history = this.props.history;
        this.state = {
            cancelToken: null,
            loading: false,
            email: "",
            ip: "",
            showError: false,
            errorMessage: "",
            loadingSpam: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        this.loadSpam();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    loadSpam(){
        const source = axios.CancelToken.source();
        const requestData = {};
        Api.getSpam(requestData, source).then(data => {
            this.setState({
                ip: data.ip,
                email: data.email,
                loadingSpam: false,
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loadingSpam: true, 
            cancelToken: source,
        });
    }
    handleSubmit(e){
        e.preventDefault();
        const source = axios.CancelToken.source();
        const { email, ip } = this.state;
        const requestData = {
            email: email,
            ip: ip,
        }
        Api.saveSpam(requestData, source).then(data => {
            this.setState({
                loading: false, 
                showError: false, 
                cancelToken: null,
                errorMessage: ""
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    render() {
        const { classes } = this.props;
        const { loading, loadingSpam, ip, email } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <BlockIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Spam Control</h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loadingSpam ?
                                    <LoaderComponent align="left" />
                                :
                                    <form onSubmit={this.handleSubmit} noValidate>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <CustomInput 
                                                labelText="Email Address Ban"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,"email"),
                                                    multiline: true,
                                                    rows: 5,
                                                    value: email
                                                }}
                                            />
                                            <p>To limit transfers by email address, enter the addresses into the field below. Addresses should be separated by commas, like email@domain.com, email2@domain.com. You may use an asterisk (*) as a wildcard character.</p>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <CustomInput 
                                                labelText="IP Address Ban"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,"ip"),
                                                    multiline: true,
                                                    rows: 5,
                                                    value: ip
                                                }}
                                            />
                                            <p>To ban users by their IP address, enter their address into the field below. Addresses should be separated by commas, like 123.45.67.8, 23.45.67.*, 192.168.1.1-192.168.1.55.</p>
                                        </GridItem>
                                        <GridItem lg={6} md={6} sm={12}>
                                            {
                                                loading ?
                                                    <LoaderComponent align="left" />
                                                :
                                                <Button type="submit" color="info">
                                                    Save Changes
                                                </Button>
                                            }
                                        </GridItem>
                                    </form>
                            }
                            
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

Spam.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(spamPageStyle)(Spam);
