import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api";
import Config from "../../../Config";
import { helper } from "../../assets/js/utils/Element";
import Cookie from "../../assets/js/utils/Cookie";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import LoaderComponent from "../components/Loader";
import Button from "../components/CustomButtons/Button.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import Close from "@material-ui/icons/Close";
import AccountIcon from "@material-ui/icons/AccountBox";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import StorageIcon from "@material-ui/icons/Storage";
import DeleteUserModal from "../components/Home/DeleteUserModal";
import ReactTable from "react-table";
import CustomSelect from "../components/CustomInput/CustomSelect";
import CustomInput from "../components/CustomInput/CustomInput";
import DatePicker from "../components/CustomInput/DatePicker";
import AddAccountModal from "../components/Home/AddAccountModal";
import AddBusinessAccountModal from "../components/Home/AddBusinessAccountModal";
import AddBusinessInfoModal from "../components/Home/AddBusinessInfoModal";
import EditPackageModal from "../components/Home/EditPackageModal";
import UserTypeModal from "../components/Home/UserTypeModal";
import PaymentIcon from '@material-ui/icons/Payment';
import ProcessingModal from "../components/Home/ProcessingModal";
import RestoreUserModal from "../components/Home/RestoreUserModal";
import ConfirmationModal from "../components/Home/ConfirmationModal";
import LockIcon from "@material-ui/icons/LockOpen";
import SdStorageIcon from "@material-ui/icons/SdStorage";
import ChangeStorageModal from "../components/Home/ChangeStorageModal";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import accountsPageStyle from "../../assets/jss/accountsPageStyle";

class Accounts extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.defaultAccountDetails = {
            firstName: '',
            lastName: '',
            email: "",
            password: "",
            passwordConfirm: "",
            accountName: "",
            phone: "",
            cell: "",
            address1: "",
            address2: "",
            address3: "",
            planId: "1",
            countryId: "",
            stateId: "",
            cityId: "",
            dob: "",
            timezone: "",
            terms: true,
            promotions: true,
            businessName: ''
        };

        this.defaultBusinessInfoDetails = {
            userId: '',
            businessType: '',
            keywords: '',
            employeesCount: '',
            establishedDate: '',
            website: '',
            dob: '',
            occupation: "",
            address1: "",
            address2: "",
            countryId: "",
            stateId: "",
            cityId: "",
            firstName: "",
            lastName: "",
            email: ""
        };

        this.state = {
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            deleteUserModal: false,
            limit: 20,
            deleteAll: false,
            values: {
                userId: "",
                email: "",
                firstName: "",
                lastName: "",
                countryId: "",
                stateId: "",
                cityId: "",
                loginIp: "",
                dateType: "",
                filterCondition: "",
                filterDate: "",
                startDate: "",
                endDate: "",
                usage: "",
                quota: "",
                remainingQuota: "",
                company: "",
                storagePolicy: "",
                userPolicy: ""
            },
            countries: [],
            states: [],
            cities: [],
            loadingCountries: false,
            loadingStates: false,
            loadingCities: false,
            addAccountModal: false,
            addBusinessAccountModal: false,
            addBusinessInfoModal: false,
            userTypeModal: false,
            accountDetails: this.defaultAccountDetails,
            businessInfoDetails: this.defaultBusinessInfoDetails,
            editMode: false,
            editPackageDetail: {},
            editPackageModal: false,
            changeStorageModal: false,
            changeStorageDetail: {},
            businessInfoUserId: null,
            paymentModal: false,
            paymentModalLoading: false,
            user: null,
            errorMessage: null,
            restoreModal: false,
            loginModal: false,
            loggingIn: false
        };
        this.dateTypes = [
            {
                type: "created_at",
                name: "Creation Date"
            },
            {
                type: "lastlogin_date",
                name: "Last Login Date"
            },
            {
                type: "dob",
                name: "Date of Birth"
            }
        ];
        this.filterConditions = [
            {
                type: "between",
                name: "Between"
            },
            {
                type: "equalTo",
                name: "Equal To"
            },
            {
                type: "greaterThan",
                name: "Greater Than"
            },
            {
                type: "lessThan",
                name: "Less Than"
            },
        ];
        this.handleToggle = this.handleToggle.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onModalSuccess = this.onModalSuccess.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAddAccount = this.onAddAccount.bind(this);
    }
    componentDidMount(){
        this.loadUsers();
        this.loadCountries();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
      }
    loadUsers(viewMore = false){
        const source = axios.CancelToken.source();
        const type = this.props.type;
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        let requestData = Object.assign({}, this.state.values);
        requestData['page'] = page;
        requestData['limit'] = 20;
        requestData['type'] = type;
        Api.getUsers(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
                limit: response.length
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked } = this.state;
        const { user } = this.store.getState();
        let authorizedUser = user;
        
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(user => {
            let userArray = {
                id: user.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(user.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(user.id)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                email: user.email,
                account_name: user.account_name,
                first_name: user.first_name,
                last_name: user.last_name,
                company: "",
                created_at: user.created_at,
                lastlogin_date: user.lastlogin_date,
                lastlogin_ip: user.lastlogin_ip,
                address_book: "n/a",
                secondary_email: "n/a",
                plan: user.plan,
                storage_policy: "n/a",
                file_policy: "n/a",
                created_by_name: "",
                created_by_email: "",
                created_by_id: "",
                creation_ip: "",
                orignalUsage: user.usage,
                usage: helper.getFormatedSize(user.usage),
                orignalQuota: user.quota,
                quota: helper.getFormatedSize(user.quota),
                orignalRemaining: user.orignalRemaining,
                remaining: user.remaining,
                actions: (
                    <>
                        {
                            authorizedUser.level_id <= 2 ?
                                <Button title={"Edit Account"}  justIcon size="sm" color="info" onClick={() => this.onEditAccount(true, user)}>
                                    <EditIcon />
                                </Button>
                            :
                                <></> 
                        }
                        <Button title={"Change Storage"} justIcon size="sm" color="rose" onClick={() => this.onChangeStorageModal(true, user)} >
                            <SdStorageIcon />
                        </Button>
                        <Button title={"Assign Package"} justIcon size="sm" color="rose" onClick={() => this.onEditPackageModal(true, user)}>
                            <StorageIcon />
                        </Button>
                        {
                            user.level_id !== 1 && user.account_type > 1 ?
                                <Button title={"Pay Now"} justIcon size="sm" color="success" onClick={() => this.onPaymentModal(true, user)}>
                                    <PaymentIcon />
                                </Button>
                            :
                            <></>
                        }
                        {
                            authorizedUser.level_id === 1 ?
                                <Button justIcon size="sm" color="danger" onClick={() => this.deleteUser(user)}>
                                    <Close />
                                </Button>
                            :
                                <></>
                        }
                        {
                            user.scheduled_deletion || user.deleted ?
                                <Button title={"Restore User"} justIcon size="sm" color="info" onClick={() => this.restoreUser(user)}>
                                    <RefreshIcon />
                                </Button>
                            :
                            <></>
                        }
                        {
                            user.level_id !== 1 ?
                                <Button title={"Login"} justIcon size="sm" color="success" onClick={() => this.onLoginModal(true, user)}>
                                    <LockIcon />
                                </Button>
                            :
                            <></>
                        }
                    </>
                )
            };
            tableData.push(userArray);
            return null;
        });
        return tableData;
    }
    onEditAccount(status = true, user){
        this.setState({
            addAccountModal: status,
            accountDetails: {
                userId: user.id,
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                password: "",
                passwordConfirm: "",
                accountName: user.account_name,
                phone: user.phone,
                cell: user.cell,
                address1: user.address1,
                address2: user.address2,
                address3: user.address3,
                planId: user.account_type,
                countryId: user.country_id,
                stateId: user.state_id,
                cityId: user.city_id,
                dob: user.dob,
                timezone: user.timezone,
                terms: user.terms === 1 ? true : false,
                promotions: user.promotions === 1 ? true : false,
                businessName: user.business_name
            },
            editMode: status
        });
    }
    onUserTypeModal(status = true, type = null){
        this.setState({
            userTypeModal: status,
            editMode: false
        })
        if(status === false && type === 'business'){
            this.onAddBusinessAccountModal(true);
        }else if(status === false){
            this.onAddAccountModal(true);
        }
    }
    onAddAccountModal(status = true){
        this.setState({
            addAccountModal: status,
            accountDetails: this.defaultAccountDetails,
            editMode: false
        });
    }
    onAddBusinessAccountModal(status = true){
        this.setState({
            addBusinessAccountModal: status,
        });
    }
    onAddBusinessInfoModal(status = true){
        let userId = '';
        if(status === true){
            userId = this.state.businessInfoUserId
        }
        this.setState({
            addBusinessInfoModal: status,
            businessInfoDetails: {
                ...this.state.businessInfoDetails,
                userId: userId
            }
        });
        if(status === false){
            this.loadUsers();
        }
    }
    onAddAccount(user, userCreated = false, payNow = false){
        if(userCreated && user.user_type === "business"){
            this.setState({
                addAccountModal: false,
                businessInfoUserId: user.id,
                addBusinessAccountModal: false,
                addBusinessInfoModal: true,
                businessInfoDetails: {
                    ...this.state.businessInfoDetails,
                    userId: user.id
                }
            });
            if(payNow){
                this.onPaymentModal(true, user);
            }
            return;
        }
        if(payNow){
            this.onPaymentModal(true, user);
        }else{
            this.onAddAccountModal(false);
            this.loadUsers();
        }
        
    }
    onAddBusinessAccount(userId = null){
        this.setState({
            businessInfoUserId: userId,
        })
        this.onAddBusinessAccountModal(false);
        this.onAddBusinessInfoModal(true);
    }
    onAddBusinessInfo(){
        this.onAddBusinessInfoModal(false);
        this.loadUsers();
    }
    onChangeStorageModal(status = true, user){
        if(status === false){
            this.setState({
                changeStorageModal: status,
            })
            return;
        }
        this.setState({
            changeStorageModal: status,
            changeStorageDetail: {
                userId: user.id,
                quota: user.quota,
                usage: user.usage
            }
        })
    }
    onChangeStorage(user = null){
        this.onChangeStorageModal(false);
        this.loadUsers();
    }
    onEditPackageModal(status = true, user){
        if(status === false){
            this.setState({
                editPackageModal: status,
            })
            return;
        }
        this.setState({
            editPackageModal: status,
            editPackageDetail: {
                userId: user.id,
                accountType: user.account_type
            }
        })
    }
    onEditPackage(user = null, payNow = false){
        if(payNow){
            this.onPaymentModal(true, user);
        }
        this.onEditPackageModal(false);
        this.loadUsers();
    }
    deleteSelected(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        this.setState({deleteUserModal: true});
    }
    deleteAll(){
        this.setState({deleteUserModal: true, deleteAll: true});
    }
    deleteUser(user){
        let checked = [];
        checked.push(user.id);
        this.setState({deleteUserModal: true, checked: checked});
    }
    onModalClose(deleteUserModal = true){
        let checked = this.state.checked;
        if(deleteUserModal === false){
            checked = [];
        }
        this.setState({
            deleteUserModal: deleteUserModal,
            checked: checked,
            deleteAll: false
        });
    }
    onModalSuccess(){
        const { checked, response, deleteAll } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            users: checked,
            clean: (deleteAll ? 1: 0)
        };
        
        if(this.props.type === 'deactivated'){
            Api.deleteScheduledUsers(requestData, source).then(data => {
                if(deleteAll){
                    this.loadUsers();
                }
            }).catch(err => {
                //Handle Error
            });
        }else{
            requestData['type'] = this.props.type;
            Api.deleteUsers(requestData, source).then(data => {
                if(deleteAll){
                    this.loadUsers();
                }
            }).catch(err => {

            });
        }
        if(deleteAll){
            this.setState({
                loading: true, 
                showError: false, 
                checked: [],
                page: 1,
                totalPages: 1,
                deleteUserModal: false,
                deleteAll: false
            });
            return;
        }

        let newResponse = [];
        response.map(file => {
            if(!checked.includes(file.id)){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteUserModal: false,
            deleteAll: false
        });
    }
    handleChange(event, name) {
        this.setState({
            values: {
                ...this.state.values,
                [name]: event.target.value
            }
        });
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                values: {
                   ...this.state.values,
                   [name]: parsedDate.toISOString()
               }
           });
        }catch(e){ console.log(e); }        
    }
    loadCountries(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingCountries: true,
            countries: []
        });
        Api.getCountries(source).then(data => {
            const countries = [{id: "", "name": "Select Country"}].concat(data.response);
            that.setState({
                countries: countries,
                loadingCountries: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onCountryChange(e){
        const country = e.target.value;
        if(country.length <= 0){
            this.setState({
                values: {
                    ...this.state.values,
                    countryId: country,
                    stateId: "",
                    cityId: "",
                },
                cities: [],
                states: [],
            });
            return;
        }
        this.loadStates(country);
    }
    loadStates(country){
        const { countryId } = this.state.values;
        if(countryId === country){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingStates: true,
            values: {
                ...this.state.values,
                countryId: country,
                stateId: "",
                cityId: ""
            },
            states: [],
            cities: [],
        });
        Api.getStates(country, source).then(data => {
            let states = data.response;
            if(states.length > 0){
                states = [{id: "", "name": "Select State"}].concat(data.response);
            }
            that.setState({
                states: states,
                loadingStates: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onStateChange(e){
        const state = e.target.value;
        if(state.length <= 0){
            this.setState({
                values: {
                    ...this.state.values,
                    stateId: state,
                    cityId: ""
                },
                states: [],
                cities: [],
            });
            return;
        }
        this.loadCities(state);
    }
    loadCities(state){
        const { stateId } = this.state.values;
        if(stateId === state){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        const { countryId } = this.state.values;
        that.setState({
            cancelToken: source,
            loadingCities: true,
            values: {
                ...this.state.values,
                stateId: state,
                cityId: ""
            },
            cities: [],
        });
        Api.getCities(countryId, state, source).then(data => {
            let cities = data.response;
            if(cities.length > 0){
                cities = [{id: "", "name": "Select City"}].concat(data.response);
            }
            that.setState({
                cities: cities,
                loadingCities: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    handleSubmit(e){
        e.preventDefault();
        this.loadUsers();
    }
    onPaymentModal(status = true, user = null){
        if(user !== null && parseInt(user.account_type) === 1){
            status = false;
        }
        this.setState({
            paymentModal: status,
            paymentModalLoading: status,
            addAccountModal: false,
            accountDetails: this.defaultAccountDetails,
            user: user,
            editMode: false
        });
        if(status){
            const source = axios.CancelToken.source();
            const requestData = {
                id: user.id
            }
            Api.paymentLink(requestData, source).then(data => {
                //Handle Success;
                Cookie.write("oauth_token", data.oauth_token, ".sendlinx.com");
                Cookie.write("oauth_secret", data.oauth_secret, ".sendlinx.com");
                window.open(data.checkout_url, "_blank");
                this.setState({
                    paymentModal: false,
                    paymentModalLoading: false,
                    user: null,
                });
                this.loadUsers();
            }).catch(err => {
                //Handle Error
                this.setState({
                    paymentModalLoading: false,
                    user: null,
                    errorMessage: err.message
                });
            });
        }
    }
    onPaymentModalSuccess(){
        this.setState({
            paymentModal: false,
            paymentModalLoading: false
        });
    }
    restoreUser(user){
        let checked = [];
        checked.push(user.id);
        this.setState({restoreModal: true, checked: checked});
    }
    onRestoreModalClose(restoreModal = true){
        let checked = this.state.checked;
        if(restoreModal === false){
            checked = [];
        }
        this.setState({
            restoreModal: restoreModal,
            checked: checked,
        });
    }
    onRestoreModalSuccess(){
        const { checked, response } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            users: checked,
        };
        Api.restoreUsers(requestData, source).then(data => {
            //this.loadUsers();
        }).catch(err => {
            //Handle Error
        });

        let newResponse = [];
        response.map(file => {
            if(!checked.includes(file.id)){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            restoreModal: false,
        });
    }
    onLoginModal(status = true, user = null){
        this.setState({
            loginModal: status,
            loggingIn: false,
            user: user
        });
    }
    onLoginSuccess(){
        let { user } = this.state;
        if(user === null){
            return this.onLoginModal(false);
        }
        const requestData = {
            id: user.id
        };
        const source = axios.CancelToken.source();
        this.setState({
            loggingIn: true,
            cancelToken: source,
        });

        Api.loginUser(requestData, source).then(data => {
            //Handle Success;
            Cookie.write("oauth_token", data.oauth_token, ".sendlinx.com");
            Cookie.write("oauth_secret", data.oauth_secret, ".sendlinx.com");
            window.open(data.home_url, "_blank");
            this.setState({
                loginModal: false,
                loggingIn: false,
                user: null,
                cancelToken: null,
            });
        }).catch(err => {
            this.setState({
                loginModal: false,
                loggingIn: false,
                user: null,
                cancelToken: null,
            });
        });
    }
    render() {
        const { classes } = this.props;
        const { loading, page, loadingMore, totalPages, deleteUserModal, checked, limit, deleteAll, values } = this.state;
        const { countries, states, cities, loadingCountries, loadingStates, loadingCities, addAccountModal, addBusinessAccountModal,
             accountDetails, editMode, editPackageModal, editPackageDetail, userTypeModal, addBusinessInfoModal, businessInfoDetails,
             paymentModal, paymentModalLoading, errorMessage, restoreModal, loginModal, loggingIn, changeStorageModal,changeStorageDetail } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        const apiUrl = Config.getUsersExportUrl(this.props.type);
        const tableData = this.getTableData();
        const currentUser = this.state.user;
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <AccountIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Account Management
                                {
                                    user.level_id === 1 ?
                                        <Button round color="primary" type="button" className={classes.addButton} onClick={() => this.onUserTypeModal(true)}>Add Account</Button>
                                    :
                                        <></>
                                }
                                <a target="_blank" rel="noopener noreferrer" href={apiUrl} className={classes.exportButton}>
                                    <Button round color="primary" type="button">Export</Button>
                                </a>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loading ?
                                    <LoaderComponent align="center" />
                                :
                                    <>
                                        <div>
                                            <form onSubmit={this.handleSubmit} className={classes.form} noValidate autoComplete="off">
                                                <CustomInput
                                                    labelText="Account ID"
                                                    inputProps={{
                                                        value: values.userId,
                                                        onChange: (e) => this.handleChange(e,"userId"),
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText="Email"
                                                    inputProps={{
                                                        value: values.email,
                                                        onChange: (e) => this.handleChange(e,"email"),
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText="First Name"
                                                    inputProps={{
                                                        value: values.firstName,
                                                        onChange: (e) => this.handleChange(e,"firstName"),
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText="Last Name"
                                                    inputProps={{
                                                        value: values.lastName,
                                                        onChange: (e) => this.handleChange(e,"lastName"),
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText="Company"
                                                    inputProps={{
                                                        value: values.company,
                                                        onChange: (e) => this.handleChange(e,"company"),
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText="User Access Policy"
                                                    inputProps={{
                                                        value: values.userPolicy,
                                                        onChange: (e) => this.handleChange(e,"userPolicy"),
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText="Storage Location Policy"
                                                    inputProps={{
                                                        value: values.storagePolicy,
                                                        onChange: (e) => this.handleChange(e,"storagePolicy"),
                                                    }}
                                                />
                                                {
                                                    countries.length > 0 ?
                                                        <CustomSelect 
                                                            labelText="Country"
                                                            selectProps={{
                                                                onChange: (e) => this.onCountryChange(e),
                                                                value: values.countryId
                                                            }}
                                                            inputProps={{
                                                                name: "countryId",
                                                                id: "input-countryId",
                                                            }}
                                                            items={countries}
                                                            itemLabel="name"
                                                            itemValue="id"
                                                        />
                                                    :
                                                        loadingCountries ?
                                                            <LoaderComponent align="left" />
                                                        :
                                                        <></>
                                                }
                                                {
                                                    states.length > 0 ?
                                                        <CustomSelect 
                                                            labelText="State"
                                                            selectProps={{
                                                                onChange: (e) => this.onStateChange(e),
                                                                value: values.stateId
                                                            }}
                                                            inputProps={{
                                                                name: "stateId",
                                                                id: "input-stateId",
                                                            }}
                                                            items={states}
                                                            itemLabel="name"
                                                            itemValue="id"
                                                        />
                                                    :
                                                        loadingStates ?
                                                            <LoaderComponent align="left" />
                                                        :
                                                        <></>
                                                }
                                                {
                                                    cities.length > 0 ?
                                                        <CustomSelect 
                                                            labelText="City"
                                                            selectProps={{
                                                                onChange: (e) => this.handleChange(e, "cityId"),
                                                                value: values.cityId
                                                            }}
                                                            inputProps={{
                                                                name: "cityId",
                                                                id: "input-cityId",
                                                            }}
                                                            items={cities}
                                                            itemLabel="name"
                                                            itemValue="id"
                                                        />
                                                    :
                                                        loadingCities ?
                                                            <LoaderComponent align="left" />
                                                        :
                                                        <></>
                                                }
                                                <CustomInput
                                                    labelText="Last Login IP"
                                                    inputProps={{
                                                        value: values.loginIp,
                                                        onChange: (e) => this.handleChange(e,"loginIp"),
                                                    }}
                                                />
                                                <CustomSelect 
                                                    labelText="Date Filter"
                                                    selectProps={{
                                                        onChange: (e) => this.handleChange(e, "dateType"),
                                                        value: values.dateType
                                                    }}
                                                    inputProps={{
                                                        name: "dateType",
                                                        id: "input-dateType",
                                                    }}
                                                    items={this.dateTypes}
                                                    itemLabel="name"
                                                    itemValue="type"
                                                />
                                                {
                                                    values.dateType.length > 0 ?
                                                        <CustomSelect 
                                                            labelText="Condition"
                                                            selectProps={{
                                                                onChange: (e) => this.handleChange(e, "filterCondition"),
                                                                value: values.filterCondition
                                                            }}
                                                            inputProps={{
                                                                name: "filterCondition",
                                                                id: "input-filterCondition",
                                                            }}
                                                            items={this.filterConditions}
                                                            itemLabel="name"
                                                            itemValue="type"
                                                        />
                                                    :
                                                    <></>
                                                }
                                                {
                                                    values.filterCondition.length > 0 ?
                                                        <>
                                                            {
                                                                values.filterCondition === "equalTo" ?
                                                                    <DatePicker
                                                                        labelText="Date"
                                                                        id="input-date"
                                                                        value={values.filterDate}
                                                                        disableFuture={true}
                                                                        onChange={(date) => this.handleDateChange(date, 'filterDate')}
                                                                    />
                                                                :
                                                                <></>
                                                            }
                                                            {
                                                                values.filterCondition === "between" || values.filterCondition === "greaterThan" ?
                                                                    <DatePicker
                                                                        labelText="Start Date"
                                                                        id="input-startDate"
                                                                        value={values.startDate}
                                                                        disableFuture={true}
                                                                        onChange={(date) => this.handleDateChange(date, 'startDate')}
                                                                    />
                                                                :
                                                                <></>
                                                            }
                                                            {
                                                                values.filterCondition === "between" || values.filterCondition === "lessThan" ?
                                                                    <DatePicker
                                                                        labelText="End Date"
                                                                        id="input-endDate"
                                                                        value={values.endDate}
                                                                        disableFuture={true}
                                                                        onChange={(date) => this.handleDateChange(date, 'endDate')}
                                                                    />
                                                                :
                                                                <></>
                                                            }
                                                            
                                                        </>
                                                    :
                                                    <></>
                                                }
                                                
                                                <CustomInput
                                                    labelText="Usage"
                                                    inputProps={{
                                                        value: values.usage,
                                                        onChange: (e) => this.handleChange(e,"usage"),
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText="Quota"
                                                    inputProps={{
                                                        value: values.quota,
                                                        onChange: (e) => this.handleChange(e,"quota"),
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText="Storage Remaining"
                                                    inputProps={{
                                                        value: values.remainingQuota,
                                                        onChange: (e) => this.handleChange(e,"remainingQuota"),
                                                    }}
                                                />
                                                <Button round color="primary" type="submit">Search</Button>
                                            </form>
                                        </div>
                                        <ReactTable
                                            columns={[
                                                {
                                                    Header: "ID",
                                                    accessor: "id",
                                                    headerClassName: "hd_id",
                                                    className: "hd_id td_id",
                                                    width: 50
                                                },
                                                {
                                                    Header: "",
                                                    accessor: "check",
                                                    sortable: false,
                                                    filterable: false,
                                                    headerClassName: "hd_check",
                                                    className: "hd_check td_check",
                                                    width: 50,
                                                },
                                                {
                                                    Header: "Email",
                                                    accessor: "email",
                                                    width: 200,
                                                },
                                                {
                                                    Header: "Account Name",
                                                    accessor: "account_name",
                                                    width: 150,
                                                },
                                                {
                                                    Header: "First Name",
                                                    accessor: "first_name"
                                                },
                                                {
                                                    Header: "Last Name",
                                                    accessor: "last_name"
                                                },
                                                {
                                                    Header: "Company*",
                                                    accessor: "company"
                                                },
                                                {
                                                    Header: "Creation Date",
                                                    accessor: "created_at",
                                                    sortMethod: (a, b) => {
                                                        var a1 = new Date(a).getTime();
                                                        var b1 = new Date(b).getTime();
                                                      if(a1<b1)
                                                        return 1;
                                                      else if(a1>b1)
                                                        return -1;
                                                      else
                                                        return 0;
                                                    }
                                                },
                                                {
                                                    Header: "Last Login Date",
                                                    accessor: "lastlogin_date",
                                                    sortMethod: (a, b) => {
                                                        var a1 = new Date(a).getTime();
                                                        var b1 = new Date(b).getTime();
                                                      if(a1<b1)
                                                        return 1;
                                                      else if(a1>b1)
                                                        return -1;
                                                      else
                                                        return 0;
                                                    }
                                                },
                                                {
                                                    Header: "Last Login IP",
                                                    accessor: "lastlogin_ip"
                                                },
                                                {
                                                    Header: "Shared Address Book",
                                                    accessor: "address_book",
                                                    width: 150,
                                                },
                                                {
                                                    Header: "Secondary Email",
                                                    accessor: "secondary_email"
                                                },
                                                {
                                                    Header: "User Access Policy*",
                                                    accessor: "plan",
                                                    width: 150,
                                                },
                                                {
                                                    Header: "Storage Location Policy",
                                                    accessor: "storage_policy",
                                                    width: 150,
                                                },
                                                {
                                                    Header: "File And Folder Management Policy",
                                                    accessor: "file_policy",
                                                    width: 200,
                                                },
                                                {
                                                    Header: "Created By Name",
                                                    accessor: "created_by_name"
                                                },
                                                {
                                                    Header: "Created By Email",
                                                    accessor: "created_by_email"
                                                },
                                                {
                                                    Header: "Created By UserID",
                                                    accessor: "created_by_id"
                                                },
                                                {
                                                    Header: "Created By IP",
                                                    accessor: "creation_ip"
                                                },
                                                {
                                                    Header: "Usage",
                                                    accessor: "orignalUsage",
                                                    headerClassName: "hd_usage",
                                                    className: "hd_usage td_usage td_hide",
                                                },
                                                {
                                                    Header: "Usage",
                                                    accessor: "usage",
                                                    headerClassName: "hd_usage hd_hide",
                                                    className: "hd_usage td_usage",
                                                },
                                                {
                                                    Header: "Quota",
                                                    accessor: "orignalQuota",
                                                    headerClassName: "hd_quota",
                                                    className: "hd_quota td_quota td_hide",
                                                },
                                                {
                                                    Header: "Quota",
                                                    accessor: "quota",
                                                    headerClassName: "hd_quota hd_hide",
                                                    className: "hd_quota td_quota",
                                                },
                                                {
                                                    Header: "Remaining %",
                                                    accessor: "orignalRemaining",
                                                    headerClassName: "hd_remaining",
                                                    className: "hd_remaining td_remaining td_hide",
                                                },
                                                {
                                                    Header: "Remaining %",
                                                    accessor: "remaining",
                                                    headerClassName: "hd_remaining hd_hide",
                                                    className: "hd_remaining td_remaining",
                                                },
                                                {
                                                    Header: "Actions",
                                                    accessor: "actions",
                                                    sortable: false,
                                                    filterable: false,
                                                    width: 210,
                                                }
                                            ]}
                                            data={tableData}
                                            // pageSizeOptions={[10, 20, 30, 40, 50, 100, 200]}
                                            // defaultPageSize={limit}
                                            pageSizeOptions={[limit]}
                                            defaultPageSize={10000000}
                                            minRows={0}
                                            showPaginationTop={true}
                                            showPaginationBottom={false}
                                            showPagination={false}
                                            className={"-striped -highlight "+(classes.reactTable)}
                                        />
                                    </>
                            }
                            <div className={classes.loadMore}>
                                {
                                    loadingMore === false && tableData.length > 0 ?
                                        <>
                                            {
                                                user.level_id === 1 ?
                                                    <>
                                                        <Button color="primary" disabled={(checked.length === 0)} round onClick={(e) => this.deleteSelected()}>
                                                            <Close className={classes.icons} />Delete Selected
                                                        </Button>
                                                        <Button color="primary" round onClick={(e) => this.deleteAll()}>
                                                            <Close className={classes.icons} />Delete All
                                                        </Button>
                                                    </>
                                                :
                                                <></>
                                            }
                                        </>
                                    :
                                    <></>
                                }
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="primary" id="contentViewMore" round onClick={(e) => this.loadUsers(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                {
                    addAccountModal ?
                        <AddAccountModal open={addAccountModal} editMode={editMode} accountDetails={accountDetails} onClose={() => this.onAddAccountModal(false)} onSuccess={(user, userCreated, payNow) => this.onAddAccount(user, userCreated, payNow)} />
                    :
                        <></>
                }
                {
                    addBusinessAccountModal ?
                        <AddBusinessAccountModal open={addBusinessAccountModal} editMode={editMode} accountDetails={accountDetails} onClose={() => this.onAddBusinessAccountModal(false)} onSuccess={(userId) => this.onAddBusinessAccount(userId)} />
                    :
                        <></>
                }
                {
                    addBusinessInfoModal ?
                        <AddBusinessInfoModal open={addBusinessInfoModal} businessInfoDetails={businessInfoDetails} onClose={() => this.onAddBusinessInfoModal(false)} onSuccess={() => this.onAddBusinessInfo()} />
                    :
                        <></>
                }
                {
                    userTypeModal ?
                        <UserTypeModal open={userTypeModal} onClose={(type) => this.onUserTypeModal(false, type)} />
                    :
                        <></>
                }
                {
                    editPackageModal ? 
                        <EditPackageModal open={editPackageModal} editPackageDetail={editPackageDetail} onClose={() => this.onEditPackageModal(false)} onSuccess={(user, payNow) => this.onEditPackage(user, payNow)}  />
                    :
                        <></>
                }
                {
                    changeStorageModal ?
                        <ChangeStorageModal open={changeStorageModal} changeStorageDetail={changeStorageDetail} onClose={() => this.onChangeStorageModal(false)} onSuccess={(user) => this.onChangeStorage(user)} />
                    :
                        <></>
                }
                {
                    paymentModal ?
                        <ProcessingModal 
                            open={paymentModal} 
                            saving={paymentModalLoading} 
                            onClose={() => this.onPaymentModal(false)} 
                            onSuccess={() => this.onPaymentModalSuccess()}
                            loadingMessage="Please wait while we prepare your payment link."
                            successMessage="Your payment link is ready."
                            errorMessage={errorMessage}
                        />
                    :
                    <></>
                }
                {
                    loginModal ?
                        <ConfirmationModal 
                            open={loginModal} 
                            saving={loggingIn} 
                            onClose={() => this.onLoginModal(false)} 
                            onSuccess={() => this.onLoginSuccess()}
                            confirmationMessage={"Are you sure you want to login with "+currentUser.first_name+" "+currentUser.last_name+"?"}
                        />
                    :
                    <></>
                }
                <DeleteUserModal deleteAll={deleteAll} open={deleteUserModal} onClose={() => this.onModalClose(false)} onSuccess={() => this.onModalSuccess()} />
                <RestoreUserModal open={restoreModal} onClose={() => this.onRestoreModalClose(false)} onSuccess={() => this.onRestoreModalSuccess()} />
            </GridContainer>
        );
    }
}

Accounts.defaultProps = {
    type: "signup"
}
Accounts.propTypes = {
    classes: PropTypes.object.isRequired,
    type: PropTypes.string
};

export default withStyles(accountsPageStyle)(Accounts);
