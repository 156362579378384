import React from 'react';
import axios from "axios";
import Cookie from '../../../assets/js/utils/Cookie';
import { Redirect } from 'react-router-dom';
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import Api from "../../../assets/js/utils/Api";
import LoaderComponent from '../../components/Loader'
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Warning from "../../components/Typography/Warning";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

import loginPageStyle from "../../../assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";

const Login = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);

        this.state = {
            email: "",
            password: "",
            passwordConfirm: "",
            code: "",
            loading: false,
            validation: {
                email: '',
                password: '',
                passwordConfirm: '',
                code: '',
                isValid: false
            },
            showError: false,
            errorMessage: "",
            forgotPassword: false,
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            requestCode: false,
        }
    }
    handleChange(e, name){
        let state = {};
        let value = e.target.value;
        if(name === "email"){
            value = value.toLowerCase();
        }
        state[name] = value;
        this.setState(state);
    }
    componentDidUpdate(prevProps, prevState){
        const { password, passwordConfirm } = this.state;
        let validatePassword = false;
        let validatePasswordConfirm = false;
        if(prevState.password !== password){
            validatePassword = true;            
        }
        if(prevState.passwordConfirm !== passwordConfirm){
            validatePasswordConfirm = true;            
        }
        if(this.state.requestCode && (validatePassword || validatePasswordConfirm)){
            this.validateResetForm(validatePassword, validatePasswordConfirm);
        }
    }
    handleSubmit(e){
        e.preventDefault();
        if(this.state.forgotPassword === true && this.state.requestCode === true){
            this.handleResetPassword();
            return;
        }
        if(this.state.forgotPassword === true){
            this.handleForgotSubmit();
            return;
        }
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }
        const source = axios.CancelToken.source();
        const { email, password } = this.state;
        const requestData = {
            email: email,
            password: password
        }
        Api.login(requestData, source).then(data => {
            if(data.user.level_id > 3){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: "You need to have admin level to login."
                });
                return;
            }
            Cookie.write("oauth_token", data.oauth_token);
            Cookie.write("oauth_secret", data.oauth_secret);
            this.history.push("/dashboard");
            this.store.dispatch({
                type: "LOGIN",
                state: {
                    authorized: true,
                    user: data.user
                }
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    handleForgotSubmit(){
        const isValid = this.validateForgotForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        const { email } = this.state;
        const requestData = {
            email: email,
        };
        this.setState({loading: true, cancelToken: source, showError: false});
        Api.forgot(requestData, source).then(data => {
            this.setState({requestCode: true, validated: false, loading: false});
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
    }
    handleResetPassword(){
        const isValid = this.validateResetForm(true,true);
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        const { email, code, password } = this.state;
        const requestData = {
            email: email,
            code: code,
            password: password
        };
        this.setState({loading: true, cancelToken: source, showError: false});
        Api.reset(requestData, source).then(data => {
            Cookie.write("oauth_token", data.oauth_token);
            Cookie.write("oauth_secret", data.oauth_secret);
            this.history.push("/dashboard");
            this.store.dispatch({
                type: "LOGIN",
                state: {
                    authorized: true,
                    user: data.user
                }
            }); 
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
    }
    validateForm(){
        let validation = {
            email: 'success',
            password: 'success',
            isValid: true
        };
        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        if(this.state.password.length <= 0){
            validation.password = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    validateForgotForm(){
        let validation = {
            email: 'success',
            password: '',
            passwordConfirm: '',
            code: '',
            isValid: true
        };
        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    validateResetForm(validatePassword = false, validatePasswordConfirm = false){
        let validation = {
            email: 'success',
            password: (validatePassword ? 'success': this.state.validation.password),
            passwordConfirm: (validatePasswordConfirm ? 'success': this.state.validation.passwordConfirm),
            code: '',
            isValid: true
        };
        if(this.state.code.length <= 3){
            validation.code = "error";
            validation.isValid = false;
        }

        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        if(this.state.password.length <= 0 || check === false){
            if(validatePassword){
                validation.password = "error";
            }
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            if(validatePasswordConfirm){
                validation.passwordConfirm = "error";
            }
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    handleForgotPassword(){
        this.setState({forgotPassword: true});
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    render() {
        const { classes } = this.props;
        const { validation, loading, showError, forgotPassword, requestCode, email, password, passwordConfirm } = this.state;
        const { requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength } = this.state;
        const { authorized } = this.store.getState();
        const showPasswordErrors = (validation.password === "success" || validation.password === "error");
        if(authorized){
            return <Redirect to='/dashboard' />
        }
        return (
            <div>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={5}>
                            <Card className={classes.loginCard}>
                                <form className={classes.form} onSubmit={this.handleSubmit} noValidate>
                                    <h5 className={classes.cardTitle + " " + classes.modalTitle}>Log in</h5>
                                    <CardBody signup>
                                        {
                                            showError ?
                                                this.renderErrorMessages()
                                            :
                                            <></>
                                        }
                                        {
                                            requestCode === false ?
                                                <CustomInput
                                                    id="email"
                                                    success={validation.email === "success"}
                                                    error={validation.email === "error"}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Email",
                                                        type: "email",
                                                        onChange: (e) => this.handleChange(e, 'email'),
                                                        name: "email",
                                                        value: email,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                            <Email className={classes.inputIconsColor} />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            :
                                            <>
                                                <Warning>
                                                    <p>We have sent a code by email. Enter it below to confirm your account.</p>
                                                </Warning>
                                                <CustomInput
                                                    success={validation.code === "success"}
                                                    error={validation.code === "error"}
                                                    id="input-code"
                                                    labelText="Verification Code"
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'code'),
                                                        name: "code",
                                                        type: "text"
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                                <CustomInput
                                                    success={validation.password === "success"}
                                                    error={validation.password === "error"}
                                                    id="input-password"
                                                    labelText="New Password"
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'password'),
                                                        name: "password",
                                                        type: "password",
                                                        value: password
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                                {
                                                    showPasswordErrors ?
                                                        <GridItem>
                                                            <div>
                                                                <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                                                </div>
                                                                <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                                                </div>
                                                                <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-symbols">Password must contain a number</span>
                                                                </div>
                                                                <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                                                </div>
                                                                <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                                                </div>
                                                            </div>
                                                        </GridItem>
                                                    :
                                                    <></>
                                                }
                                                <CustomInput
                                                    success={validation.passwordConfirm === "success"}
                                                    error={validation.passwordConfirm === "error"}
                                                    id="input-passwordConfirm"
                                                    labelText="New Password Again"
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'passwordConfirm'),
                                                        name: "passwordConfirm",
                                                        type: "password",
                                                        value: passwordConfirm
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            </>
                                        }
                                        
                                        {
                                            forgotPassword === false ?
                                                <>
                                                <CustomInput
                                                    id="password"
                                                    success={validation.password === "success"}
                                                    error={validation.password === "error"}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.passwordControl
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Password",
                                                        type: "password",
                                                        onChange: (e) => this.handleChange(e, 'password'),
                                                        name: "password",
                                                        value: password,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                            <Icon className={classes.inputIconsColor}>
                                                                lock_utline
                                                            </Icon>
                                                            </InputAdornment>
                                                        ),
                                                        autoComplete: "off"
                                                    }}
                                                />
                                                <p className={classes.anchor} onClick={this.handleForgotPassword}>Forgot?</p>
                                                </>
                                            :
                                            <></>
                                        }
                                    </CardBody>
                                    <div className={classes.textCenter}>
                                        {
                                            loading ?
                                                <LoaderComponent />
                                            :
                                            forgotPassword === false ?
                                                <Button simple color="primary" size="lg" type="submit">Login</Button>
                                            :
                                                requestCode === true ?
                                                    <Button simple color="primary" size="lg" type="submit">Reset Password</Button>
                                                :
                                                    <Button simple color="primary" size="lg" type="submit">Help me!</Button>
                                            
                                        }                                        
                                    </div>
                                </form>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        )
    }
}

export default withStyles(loginPageStyle)(Login);